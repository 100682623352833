// @libs
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
// @Layout
import AuthLayout from './layout'
// @pages
const SignIn = lazy(() => import('./pages/sign-in'))
const ForgotPassword = lazy(() => import('./pages/forgot-password'))
const ResetPassword = lazy(() => import('./pages/reset-password'))

const authRoutes = [
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      { path: '*', element: <Navigate to="/auth/sign-in" /> },
      { path: '/auth/sign-in', element: <SignIn /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/reset-password', element: <ResetPassword /> }
    ]
  }
]

export default authRoutes
