import { Grid, NumberInput } from '@mantine/core'
import PropTypes from 'prop-types'

function AttributesNumericRangeView({ formik }) {
  return (
    <>
      <Grid.Col xs={6}>
        <NumberInput
          placeholder="Inicio"
          max={formik.values.attribute_data.max}
          value={formik.values.attribute_data.min}
          onChange={(val) =>
            formik.setFieldValue('attribute_data', {
              ...formik.values.attribute_data,
              min: val
            })
          }
        />
      </Grid.Col>
      <Grid.Col xs={6}>
        <NumberInput
          placeholder="Final"
          min={formik.values.attribute_data.min}
          value={formik.values.attribute_data.max}
          onChange={(val) =>
            formik.setFieldValue('attribute_data', {
              ...formik.values.attribute_data,
              max: val
            })
          }
        />
      </Grid.Col>
    </>
  )
}

AttributesNumericRangeView.propTypes = {
  formik: PropTypes.object
}

AttributesNumericRangeView.defaultProps = {
  formik: null
}

export default AttributesNumericRangeView
