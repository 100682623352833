// @libs
import { useRoutes, Navigate } from 'react-router-dom'
// @routes
import authRoutes from '../features/auth/routes'
import clientRoutes from '../features/client/routes'
import privateRouter from './private'

const Router = ({ user }) =>
  useRoutes(
    user
      ? privateRouter
      : [
          {
            path: '/',
            element: <Navigate to="/auth/sign-in" />
          },
          ...authRoutes,
          ...clientRoutes,
          {
            path: '*',
            element: <Navigate to="/auth/sign-in" />
          }
        ]
  )
export default Router
