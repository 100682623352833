export const TABLE_HEADERS = [
  { label: 'Compañia', key: 'insurance_company.business_name' },
  {
    label: 'Tipo de Cliente',
    key: 'client_type_has_insurance_type.clientType.name'
  },
  {
    label: 'Tipo de seguro',
    key: 'client_type_has_insurance_type.insuranceType.name'
  },
  { label: 'Plan', key: 'name' },
  { label: 'Tag de Respuesta', key: 'tag' },
  { label: 'Activo', key: 'is_active' }
]
export const PAGE_TITLE = ''
