// @libs
import { useState } from 'react'
import PropTypes from 'prop-types'
// @mantine-ui
import { Group, Grid, Switch, Text } from '@mantine/core'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useClientTypeMutation from '../hooks/useInsuranceTypeMutation'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
import { openDeleteModal } from '../../../utils/alerts'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import InsuranceTypeForm from './InsuranceTypeForm'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import Can from '../../../shared/components/Can'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'

function InsuranceTypeList({ searchText }) {
  const [showEditForm, setShowEditForm] = useState(false)
  const [insuranceTypeToEdit, setInsuranceTypeToEdit] = useState(null)
  const [filters, setFilters] = useState({
    sort_by: ['created_at:desc'],
    page: 1,
    limit: '50'
  })

  const { data, isLoading, isError } = fetchAll({
    key: 'insurance-types',
    filters: { ...filters, searchText }
  })
  const { removeInsuranceType } = useClientTypeMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert />
  if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (insuranceType) =>
    openDeleteModal({
      title: 'Eliminar Tipo de Seguro',
      content: `¿Estás seguro de eliminar el Tipo de Seguro ${insuranceType.name}?.`,
      onConfirm: async () => removeInsuranceType.mutateAsync(insuranceType.id)
    })

  return (
    <Grid>
      <Grid.Col span={4}>
        <Text
          align="justify"
          sx={{
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            color: '#1E1C3E',
            opacity: 0.8
          }}
        >
          Nombre
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text
          align="center"
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Activo
        </Text>
      </Grid.Col>
      <Grid.Col span={4} />
      <Grid.Col span={12}>
        {data?.data?.map((insuranceType) => (
          <Grid
            key={insuranceType.id}
            sx={{
              backgroundColor: 'white',
              borderRadius: 16,
              marginBottom: 16,
              display: 'flex',
              alignItems: 'center'
            }}
            px={8}
          >
            <Grid.Col span={4}>
              <Text
                align="start"
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
                truncate
              >
                {insuranceType.name}
              </Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Group position="center">
                <Switch
                  size="sm"
                  color="green"
                  checked={insuranceType.is_active}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={4}>
              <Group position="center">
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
                  action="Tipos de Seguro:EDIT"
                  yes={() => (
                    <EditIconButton
                      onClick={() => {
                        setInsuranceTypeToEdit(insuranceType)
                        setShowEditForm((prev) => !prev)
                      }}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
                  action="Tipos de Seguro:EDIT"
                  yes={() => (
                    <DeleteIconButton
                      onClick={() => handleDelete(insuranceType)}
                    />
                  )}
                />
              </Group>
            </Grid.Col>
          </Grid>
        ))}
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <RowsCount
          value={filters.limit}
          onChange={(value) => setFilters({ ...filters, limit: value })}
        />
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <PaginationBase
          total={data?.meta?.lastPage}
          value={data?.meta?.currentPage}
          onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
        />
      </Grid.Col>
      <InsuranceTypeForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        insuranceTypeToEdit={insuranceTypeToEdit}
      />
    </Grid>
  )
}

InsuranceTypeList.propTypes = {
  searchText: PropTypes.string
}

InsuranceTypeList.defaultProps = {
  searchText: ''
}

export default InsuranceTypeList
