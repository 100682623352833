import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dataToEdit: null,
  modalShow: false
}

export const attributes = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setAttributeDataToUpdate: (state, action) => {
      state.dataToEdit = action.payload
    },
    setModalShow: (state, action) => {
      state.modalShow = action.payload
    }
  }
})

export const { setAttributeDataToUpdate, setModalShow } = attributes.actions

export default attributes.reducer
