// @libs
import { useState } from 'react'
import { useDebouncedState } from '@mantine/hooks'
// @mantine-ui
import { Box, Button, Grid } from '@mantine/core'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import SearchInput from '../../../shared/components/Inputs/SearchInput'
import ProductsForm from '../components/ProductsForm'
import ProductsList from '../components/ProductsList'
import Can from '../../../shared/components/Can'

function Products() {
  const [showForm, setShowForm] = useState(false)
  const [value, setValue] = useDebouncedState('', 500)
  return (
    <Page title="Configurador de Productos">
      <Grid>
        <Grid.Col
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <PageTitle title="Configurador de Productos" />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <SearchInput
              defaultValue={value}
              onChange={(event) => setValue(event.currentTarget.value)}
            />
            <Can
              moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
              action="Productos:CREATE"
              yes={() => (
                <Button
                  sx={{ marginLeft: 16 }}
                  onClick={() => setShowForm((prev) => !prev)}
                >
                  Agregar nuevo producto
                </Button>
              )}
            />
            <ProductsForm
              show={showForm}
              handleShow={() => setShowForm((prev) => !prev)}
            />
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} mt={16}>
          <ProductsList q={value} />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default Products
