// @mantine-ui
import { Text } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'

export const openBasicModal = ({ title, content, ...props }) =>
  openConfirmModal({
    title: (
      <Text
        sx={{
          fontFamily: 'Assistant',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 24,
          lineHeight: '24px',
          color: '#1E1C3E'
        }}
      >
        {title}
      </Text>
    ),
    centered: true,
    children: (
      <Text
        sx={{
          fontFamily: 'Assistant',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '20px',
          letterSpacing: '-0.015em',
          color: '#1E1C3E',
          textAlign: 'center',
          marginBottom: 16
        }}
      >
        {content}
      </Text>
    ),
    labels: { confirm: 'Aceptar', cancel: 'Cancelar' },
    styles: {
      close: { display: 'none' }
    },
    padding: 40,
    ...props
  })
export const openDeleteModal = ({
  title,
  content,
  confirmExtraProps,
  ...props
}) =>
  openBasicModal({
    title,
    content,
    labels: { confirm: 'Sí, eliminar', cancel: 'Cancelar' },
    confirmProps: {
      style: {
        backgroundColor: '#E24515',
        borderRadius: 6
      },
      ...confirmExtraProps
    },
    cancelProps: {
      style: {
        fontWeight: 400,
        color: '#1E1C3E',
        borderRadius: 6
      }
    },
    ...props
  })
export const openMessageModal = ({ title, content, ...props }) =>
  openBasicModal({
    title,
    content,
    labels: { confirm: 'Aceptar' },
    cancelProps: {
      style: { display: 'none' }
    },
    confirmProps: {
      style: {
        backgroundColor: '#E24515',
        borderRadius: 6
      }
    },
    closeOnClickOutside: false,
    withCloseButton: false,
    ...props
  })
