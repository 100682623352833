// @libs
import { useState } from 'react'
import PropTypes from 'prop-types'
import { FiEye } from 'react-icons/fi'
// @mantine-ui
import { Grid, ActionIcon, Box } from '@mantine/core'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'

function PreviewHtmlModal({ htmlText }) {
  const [show, setShow] = useState(false)

  return (
    <>
      <ActionIcon
        mr={16}
        sx={{ color: 'orange' }}
        onClick={() => setShow((prev) => !prev)}
      >
        <FiEye />
      </ActionIcon>

      <ModalBase
        title="Ver HTML cargado"
        show={show}
        size="auto"
        handleShow={() => setShow((prev) => !prev)}
      >
        <Grid>
          <Grid.Col xs={12}>
            <Box
              sx={(theme) => ({
                textAlign: 'center',
                padding: theme.spacing.xl,
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.colors.gray[4]}`
              })}
            >
              <div dangerouslySetInnerHTML={{ __html: htmlText }} />
            </Box>
          </Grid.Col>
        </Grid>
      </ModalBase>
    </>
  )
}

PreviewHtmlModal.propTypes = {
  htmlText: PropTypes.string
}

PreviewHtmlModal.defaultProps = {
  htmlText: ''
}

export default PreviewHtmlModal
