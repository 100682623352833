// @libs
import { useEffect, useState } from 'react'
import { useDebouncedState } from '@mantine/hooks'
import { useParams } from 'react-router-dom'
// @mantine-ui
import { Box, Button, Grid, Text } from '@mantine/core'
import { openDeleteModal } from '../../../utils/alerts'
// @hooks
import useFetchOne from '../../../shared/hooks/useFetchOne'
import useCodeMutation from '../hooks/useCodeMutations'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import SearchInput from '../../../shared/components/Inputs/SearchInput'
import CodeCampaignDetails from '../components/CodeCampaignDetails'
import ErrorPage from '../../../shared/components/ErrorPage'
import BasicLoader from '../../../shared/components/BasicLoader'

function Details() {
  const [value, setValue] = useDebouncedState('', 300)
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [codes, setCodes] = useState([])
  const [filters, setFilters] = useState({
    page: 1,
    limit: 5,
    q: ''
  })
  const { codeCampaignId } = useParams()

  const {
    data: codeCampaign,
    isLoading,
    isError
  } = useFetchOne(['code-campaigns', codeCampaignId])
  const codesData = fetchAll({
    key: 'codes',
    filters: {
      ...filters,
      code_campaign_id: codeCampaign?.id
    },
    customConfig: {
      enabled: !!codeCampaign?.id,
      onSuccess: (data) => {
        if (data?.data?.length) {
          setCodes(data?.data)
        }
      }
    }
  })
  const { blockCodes } = useCodeMutation()

  const handleDelete = () =>
    openDeleteModal({
      title: 'Eliminar Códigos',
      content: `¿Estás seguro de eliminar los códigos seleccionados?.`,
      onConfirm: async () =>
        blockCodes.mutateAsync(
          codes.filter((code) => code.isSelected).map((code) => code.id)
        )
    })

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      q: value
    }))
  }, [value])
  useEffect(() => {
    const updatedItems = codes?.map((code) => ({
      ...code,
      isSelected: isAllSelected
    }))
    setCodes(updatedItems)
  }, [isAllSelected])

  if (isError) return <ErrorPage code={500} />
  if (isLoading) return <BasicLoader />

  return (
    <Page title="Códigos">
      <Grid>
        <Grid.Col
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <PageTitle title="Códigos" />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <SearchInput
              defaultValue={value}
              onChange={(event) => setValue(event.currentTarget.value)}
            />
            <Button
              sx={{ marginLeft: 16 }}
              disabled={!codes?.some((code) => code.isSelected)}
              loading={blockCodes.isLoading}
              onClick={handleDelete}
            >
              Bloquear seleccionados
            </Button>
          </Box>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Text>
            Descripción:{' '}
            <span
              style={{
                fontFamily: 'Assistant',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 15,
                lineHeight: '14px',
                letterSpacing: '-0.015em',
                color: '#F28D01'
              }}
            >
              {codeCampaign?.description}
            </span>
          </Text>
        </Grid.Col>
        <Grid.Col xs={12} mt={8}>
          <CodeCampaignDetails
            onChangeFilters={setFilters}
            codes={codes}
            codesData={codesData}
            isAllSelected={isAllSelected}
            setSelectedCodes={setCodes}
            setIsAllSelected={setIsAllSelected}
          />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default Details
