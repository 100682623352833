import { utils } from 'xlsx'

export default () => {
  // workBook
  const book = utils.book_new()
  // resume sheet
  const resumeData = [
    ['Column 1', 'Column2'],
    ['Row A1', 'Row A2'],
    ['Row B1', 'Row B2']
  ]
  const resumeSheet = utils.aoa_to_sheet(resumeData)
  utils.book_append_sheet(book, resumeSheet, 'Example')
  return book
}
