// @libs
import { useParams } from 'react-router-dom'
// @mantine-ui
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Radio,
  Select,
  Switch,
  Text,
  Collapse,
  Stack,
  Divider,
  Group,
  ActionIcon,
  Textarea,
  Center,
  FileButton
} from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { useFormik } from 'formik'
import { Fragment, useState } from 'react'
import { FiX, FiCheck, FiUpload } from 'react-icons/fi'
import { BsLink45Deg } from 'react-icons/bs'
import { showNotification } from '@mantine/notifications'
// @components
import useInsuranceSettingsMutation from '../hooks/useInsuranceSettingsMutation'
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import BasicLoader from '../../../shared/components/BasicLoader'
import InsuranceSettingsOutputOrderModal from '../components/InsuranceSettingsOutputOrderModal'
import InsuranceSettingsFilterOrderModal from '../components/InsuranceSettingsFilterOrderModal'
import FormsOrderModal from '../../forms/components/FormsOrderModal'
import DistributionList from '../components/DistributionList'
import fetchAll from '../../../shared/hooks/useFetchAll'
import fetchOne from '../../../shared/hooks/useFetchOne'
import PreviewHtmlModal from '../components/PreviewHtmlModal'

function InsuranceSettingsView() {
  const [isLoading, setIsLoading] = useState(false)
  const [active, setActive] = useState(null)
  const { uniqueTupleId } = useParams()
  const { updateInsuranceSettings } = useInsuranceSettingsMutation()
  const clipboard = useClipboard({ timeout: 1000 })

  const formik = useFormik({
    initialValues: {
      isOnlineCotization: '',
      attribute_output_count: 3,
      email_template: '',
      attributes: []
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await updateInsuranceSettings.mutateAsync([
          uniqueTupleId,
          {
            ...values,
            is_online_cotization: values.isOnlineCotization === 'ONLINE'
          }
        ])
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
      }
    }
  })

  const { data: uniqueTupleData, status } = fetchOne(
    ['client-types-has-insurance-types', uniqueTupleId],
    {
      refetchOnMount: true,
      onSuccess: (response) => {
        formik.setFieldValue('attributes', response.insuranceTypesHasAttributes)
        formik.setFieldValue(
          'isOnlineCotization',
          response.is_online_cotization ? 'ONLINE' : 'MAILING'
        )
        formik.setFieldValue('email_template', response.email_template)
      }
    }
  )

  const { data: insuranceCompanies } = fetchAll({
    key: 'insurance-companies',
    filters: {
      limit: 'all'
    }
  })

  const handleSelectAttributes = (attributeValue, index) => {
    if (attributeValue.id === active?.id) {
      setActive(null)
    } else {
      setActive({
        id: attributeValue.id,
        index,
        InsuranceCompaniesHasAttributesConfig:
          attributeValue.InsuranceCompaniesHasAttributesConfig
      })
    }
  }

  const readSingleFile = (file) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      const htmlContent = e.target.result
      formik.setFieldValue('email_template', htmlContent)
    }
    fileReader.readAsText(file)
  }

  const copyContent = async () => {
    try {
      const { origin } = window.location
      const link = `${origin}/cotiza/${uniqueTupleId}`
      clipboard.copy(link)
      showNotification({
        title: 'Realizado',
        color: 'green',
        message: `${link} copiado al portapapeles`
      })
    } catch (err) {
      showNotification({
        title: 'Error',
        color: 'red',
        message: 'Lo sentimos, no fue posible copiar el valor solicitado'
      })
    }
  }

  if (status === 'loading') return <BasicLoader />

  return (
    <Page title="Configuración de Seguros">
      <Grid>
        <Grid.Col span={12}>
          <Group>
            <PageTitle title="Tipos de Seguros" />
            <FormsOrderModal uniqueTupleId={+uniqueTupleId} />
            {formik.values.isOnlineCotization === 'ONLINE' && (
              <>
                <InsuranceSettingsFilterOrderModal />
                <InsuranceSettingsOutputOrderModal />
              </>
            )}
            <Button
              variant={clipboard.copied ? 'outline' : 'subtle'}
              leftIcon={<BsLink45Deg size={18} />}
              onClick={copyContent}
              color={clipboard.copied ? 'teal' : 'orange'}
            >
              {clipboard.copied ? 'Copiado' : 'Generar link'}
            </Button>
          </Group>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text
            sx={{
              fontFamily: 'Assistant',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 16,
              lineHeight: '24px',
              letterSpacing: '0.04em',
              color: '#1E1C3E'
            }}
          >
            Configuración del seguro
          </Text>
        </Grid.Col>
        <Grid.Col xs={6} md={4} sx={{ margin: 'auto 0' }}>
          <Text
            align="start"
            sx={{
              fontWeight: 700,
              fontSize: 16,
              lineHeight: '21px',
              letterSpacing: '0.04em',
              opacity: 0.8
            }}
          >
            {uniqueTupleData?.clientType?.name} -{' '}
            {uniqueTupleData?.insuranceType?.name}
          </Text>
        </Grid.Col>
        <Grid.Col xs={6} md={4}>
          <Radio.Group
            value={formik.values.isOnlineCotization}
            onChange={(newValue) =>
              formik.setFieldValue('isOnlineCotization', newValue)
            }
          >
            <Radio value="ONLINE" label="Cotización online" />
            <Radio value="MAILING" label="Cotización por mail" />
          </Radio.Group>
        </Grid.Col>
        {formik.values.isOnlineCotization === 'MAILING' && (
          <Grid.Col xs={6} md={4}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end'
              }}
            >
              <Group>
                <FileButton onChange={readSingleFile} accept=".html">
                  {(props) => (
                    <Button
                      {...props}
                      leftIcon={<FiUpload />}
                      variant="subtle"
                      sx={{ color: 'orange' }}
                    >
                      HTML mail cotización
                    </Button>
                  )}
                </FileButton>
                {formik.values.email_template && (
                  <PreviewHtmlModal htmlText={formik.values.email_template} />
                )}
              </Group>

              <DistributionList />
            </Box>
          </Grid.Col>
        )}
        <Grid.Col xs={12}>
          <Text
            sx={{
              fontFamily: 'Assistant',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 16,
              lineHeight: '24px',
              letterSpacing: '0.04em',
              color: '#1E1C3E'
            }}
          >
            Atributos
          </Text>
        </Grid.Col>

        <Grid.Col xs={2} sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {formik.values.attributes.map((attr, attrIndex) => (
            <Box
              key={attr.id}
              sx={(theme) => ({
                backgroundColor: attr.id === active?.id && '#D9D9D9',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.md,
                marginBottom: theme.spacing.xs,
                cursor: 'pointer',
                border: !attr.is_selected && '1px solid #ffb8b8',
                '&:hover': {
                  backgroundColor: '#D9D9D9'
                }
              })}
            >
              <Group>
                <Text
                  sx={{
                    fontFamily: 'Assistant',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.04em',
                    color: '#1E1C3E',
                    width: '70%'
                  }}
                  onClick={() => handleSelectAttributes(attr, attrIndex)}
                >
                  {attr.attributes?.name}
                </Text>
                <ActionIcon
                  size="xs"
                  title="Sacar del listado"
                  onClick={() =>
                    formik.setFieldValue(
                      `attributes.${attrIndex}.is_selected`,
                      !formik.values.attributes[attrIndex].is_selected
                    )
                  }
                >
                  {attr.is_selected ? <FiCheck /> : <FiX />}
                </ActionIcon>
              </Group>
              <Collapse in={attr.id === active?.id}>
                <Divider my="sm" color="dark" />
                <Checkbox
                  checked={attr.is_required}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `attributes.${attrIndex}.is_required`,
                      e.target.checked
                    )
                  }}
                  mb={4}
                  label="Mínimo para cotizar"
                />

                {formik.values.isOnlineCotization === 'ONLINE' && (
                  <>
                    <Checkbox
                      checked={attr.is_filter}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `attributes.${attrIndex}.is_filter`,
                          e.target.checked
                        )
                      }}
                      mb={4}
                      label="Filtro"
                    />
                    <Checkbox
                      checked={attr.is_order}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `attributes.${attrIndex}.is_order`,
                          e.target.checked
                        )
                      }}
                      mb={4}
                      label="Orden"
                    />
                    <Box
                      sx={(theme) => ({
                        backgroundColor: '#b7b7b7',
                        padding: theme.spacing.xs,
                        borderRadius: theme.radius.md,
                        marginTop: theme.spacing.xs
                      })}
                    >
                      <Checkbox
                        checked={attr.used_to_compare}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `attributes.${attrIndex}.used_to_compare`,
                            e.target.checked
                          )
                        }}
                        label="Comparación"
                      />
                      {attr.used_to_compare && (
                        <Radio.Group
                          value={attr.comparison_type}
                          onChange={(newValue) => {
                            formik.setFieldValue(
                              `attributes.${attrIndex}.comparison_type`,
                              newValue
                            )
                          }}
                        >
                          <Stack>
                            <Radio value="HEADER" label="Header" />
                            <Radio value="BODY" label="Body" />
                          </Stack>
                        </Radio.Group>
                      )}
                    </Box>
                  </>
                )}
              </Collapse>
            </Box>
          ))}
        </Grid.Col>
        {active ? (
          <Grid.Col xs={10}>
            <Box
              sx={(theme) => ({
                backgroundColor: '#D9D9D9',
                padding: theme.spacing.xl,
                borderRadius: theme.radius.md
              })}
            >
              <Grid>
                <Grid.Col xs={3}>Compañias</Grid.Col>
                <Grid.Col xs={2}>Servicio de origen</Grid.Col>
                <Grid.Col xs={2}>Tag de origen</Grid.Col>
                <Grid.Col xs={2}>Servicio destino</Grid.Col>
                <Grid.Col xs={2}>Tag destino</Grid.Col>
                <Grid.Col xs={1}>Obligatorio</Grid.Col>
                {active.InsuranceCompaniesHasAttributesConfig.map(
                  (company, companyIndex) => (
                    <Fragment key={company.id}>
                      <Grid.Col
                        xs={3}
                        sx={{
                          borderRadius: '8px 0px 0px 8px',
                          backgroundColor: 'white',
                          marginBottom: 16,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        px={8}
                      >
                        <Text
                          sx={{
                            fontWeight: 700,
                            fontSize: 16,
                            lineHeight: '21px',
                            letterSpacing: '0.04em',
                            opacity: 0.8
                          }}
                        >
                          {company.insuranceTypeHasInsuranceCompany
                            .insurance_company?.name ||
                            company.insuranceTypeHasInsuranceCompany
                              .insurance_company?.business_name}
                        </Text>
                      </Grid.Col>
                      <Grid.Col
                        xs={2}
                        sx={{
                          backgroundColor: 'white',
                          marginBottom: 16,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        px={8}
                      >
                        <Select
                          value={
                            formik.values.attributes[active.index]
                              .InsuranceCompaniesHasAttributesConfig[
                              companyIndex
                            ].origin_source
                          }
                          onChange={(originSourcevalue) => {
                            formik.setFieldValue(
                              `attributes.${active.index}.InsuranceCompaniesHasAttributesConfig.${companyIndex}.origin_source`,
                              originSourcevalue
                            )
                          }}
                          clearable
                          placeholder="Servicio de origen"
                          data={
                            insuranceCompanies?.data
                              ? insuranceCompanies?.data?.map((tc) => ({
                                  ...tc,
                                  label: tc.name || tc.business_name,
                                  value: tc.id
                                }))
                              : []
                          }
                          disabled={
                            formik.values.isOnlineCotization === 'MAILING'
                          }
                        />
                      </Grid.Col>
                      <Grid.Col
                        xs={2}
                        sx={{
                          backgroundColor: 'white',
                          marginBottom: 16,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        px={8}
                      >
                        <Textarea
                          name="origin_tag"
                          autosize
                          value={
                            formik.values.attributes[active.index]
                              .InsuranceCompaniesHasAttributesConfig[
                              companyIndex
                            ].origin_tag
                          }
                          onChange={(e) => {
                            const {
                              target: { value: originTagValue }
                            } = e
                            formik.setFieldValue(
                              `attributes.${active.index}.InsuranceCompaniesHasAttributesConfig.${companyIndex}.origin_tag`,
                              originTagValue
                            )
                          }}
                          placeholder="Tag origen"
                          disabled={
                            formik.values.isOnlineCotization === 'MAILING'
                          }
                        />
                      </Grid.Col>
                      <Grid.Col
                        xs={2}
                        sx={{
                          backgroundColor: 'white',
                          marginBottom: 16,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        px={8}
                      >
                        <Select
                          value={
                            formik.values.attributes[active.index]
                              .InsuranceCompaniesHasAttributesConfig[
                              companyIndex
                            ].target_source
                          }
                          onChange={(originSourcevalue) => {
                            formik.setFieldValue(
                              `attributes.${active.index}.InsuranceCompaniesHasAttributesConfig.${companyIndex}.target_source`,
                              originSourcevalue
                            )
                          }}
                          clearable
                          placeholder="Servicio destino"
                          data={
                            insuranceCompanies?.data
                              ? insuranceCompanies?.data?.map((tc) => ({
                                  ...tc,
                                  label: tc.name || tc.business_name,
                                  value: tc.id
                                }))
                              : []
                          }
                          disabled={
                            formik.values.isOnlineCotization === 'MAILING'
                          }
                        />
                      </Grid.Col>
                      <Grid.Col
                        xs={2}
                        sx={{
                          backgroundColor: 'white',
                          marginBottom: 16,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        px={8}
                      >
                        <Textarea
                          name="target_tag"
                          autosize
                          value={
                            formik.values.attributes[active.index]
                              .InsuranceCompaniesHasAttributesConfig[
                              companyIndex
                            ].target_tag
                          }
                          onChange={(e) => {
                            const {
                              target: { value: targetTagValue }
                            } = e
                            formik.setFieldValue(
                              `attributes.${active.index}.InsuranceCompaniesHasAttributesConfig.${companyIndex}.target_tag`,
                              targetTagValue
                            )
                          }}
                          placeholder="Tag destino"
                          disabled={
                            formik.values.isOnlineCotization === 'MAILING'
                          }
                        />
                      </Grid.Col>
                      <Grid.Col
                        xs={1}
                        sx={{
                          borderRadius: '0px 8px 8px 0px',
                          backgroundColor: 'white',
                          marginBottom: 16,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        px={8}
                      >
                        <Switch
                          checked={
                            formik.values.attributes[active.index]
                              .InsuranceCompaniesHasAttributesConfig[
                              companyIndex
                            ].is_required
                          }
                          onChange={(event) =>
                            formik.setFieldValue(
                              `attributes.${active.index}.InsuranceCompaniesHasAttributesConfig.${companyIndex}.is_required`,
                              event.currentTarget.checked
                            )
                          }
                          color="green"
                          disabled={
                            formik.values.isOnlineCotization === 'MAILING'
                          }
                        />
                      </Grid.Col>
                    </Fragment>
                  )
                )}
                {active.InsuranceCompaniesHasAttributesConfig.length === 0 && (
                  <Grid.Col
                    xs={12}
                    sx={{
                      borderRadius: 8,
                      backgroundColor: 'white',
                      marginBottom: 16,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    px={8}
                  >
                    <Text
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '21px',
                        letterSpacing: '0.04em',
                        opacity: 0.8
                      }}
                    >
                      Esta tupla no tiene compañías de seguro asociadas.
                    </Text>
                  </Grid.Col>
                )}
              </Grid>
            </Box>
          </Grid.Col>
        ) : (
          <Grid.Col xs={10}>
            <Box
              sx={(theme) => ({
                backgroundColor: '#D9D9D9',
                padding: theme.spacing.xl,
                borderRadius: theme.radius.md
              })}
            >
              <Text
                sx={{
                  fontFamily: 'Assistant',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: '24px',
                  letterSpacing: '0.04em',
                  color: '#1E1C3E'
                }}
              >
                Seleccione un atributo para ver su configuración.
              </Text>
            </Box>
          </Grid.Col>
        )}
        <Grid.Col xs={12}>
          <Center>
            <Group>
              <Button onClick={formik.handleSubmit} loading={isLoading}>
                Guardar configuración
              </Button>
            </Group>
          </Center>
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default InsuranceSettingsView
