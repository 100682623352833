// @libs
import { useState, useContext, createContext } from 'react'
import { useMutation } from '@tanstack/react-query'
import { showNotification } from '@mantine/notifications'
import PropTypes from 'prop-types'
// @helpers
import {
  saveInLocalStorage,
  localStorageKeys,
  getInLocalStorage,
  removeInLocalStorage
} from '../../helpers/localStorageManager'
// @services
import AuthService from '../../services/auth.service'
import { NAV_LINKS } from '../../utils/contants'

const useProvideAuth = () => {
  const [user, setUser] = useState(() => {
    const authenticatedUser = getInLocalStorage(
      localStorageKeys.AUTENTICARED_USER
    )
    if (authenticatedUser) {
      return authenticatedUser
    }
    return null
  })

  const signIn = useMutation(AuthService.signIn, {
    onSuccess: (authenticatedUser) => {
      saveInLocalStorage(localStorageKeys.AUTENTICARED_USER, authenticatedUser)
      const permissions = authenticatedUser?.role?.permissions
      if (
        !permissions ||
        !permissions.some((p) => p.permission.name === 'Ver')
      ) {
        window.location.replace('/')
      } else {
        const {
          permission: { module, action }
        } = permissions.find((p) => p.permission.name === 'Ver')
        const navItem = NAV_LINKS.find((nav) => nav.label === module.name)
        if ('links' in navItem) {
          const option = navItem.links.find(
            (navLink) => navLink.label === action.split(':')[0]
          )
          window.location.replace(option.link || '/')
        } else {
          window.location.replace(navItem.link)
        }
      }
    },
    onError: (err) => {
      const payload = {
        title: 'Error',
        message: 'Las credenciales ingresadas son incorrectas.',
        color: 'red'
      }
      if (err.response?.data?.message === 'USER_NOT_ACTIVE') {
        payload.message = 'Este usuario no se encuentra activo'
      }
      showNotification(payload)
    }
  })
  const signUp = useMutation(AuthService.signUp, {
    onError: (err) => {
      if (err.statusCode === 409) {
        return showNotification({
          title: 'Error',
          message: 'Ya existe un registro con los datos ingresados.',
          color: 'red'
        })
      }
      return showNotification({
        title: 'Error',
        message: 'No fue posible crear el registro.',
        color: 'red'
      })
    }
  })
  const forgotPassword = useMutation(AuthService.forgotPassword, {
    onError: () => {
      showNotification({
        title: 'Error',
        message: 'Las credenciales ingresadas son incorrectas.',
        color: 'red'
      })
    }
  })
  const resetPassword = useMutation(AuthService.resetPassword, {
    onError: (err) => {
      if (err.response.data.message === 'jwt expired') {
        return showNotification({
          title: 'Error',
          message:
            'El enlace ya no es válido, debes reiniciar el proceso de recuperación de contraseña.',
          color: 'red'
        })
      }
      return showNotification({
        title: 'Error',
        message: 'No fue posible realizar el cambio de contraseña.',
        color: 'red'
      })
    }
  })
  const signOut = useMutation(AuthService.signOut, {
    onSuccess: () => {
      removeInLocalStorage(localStorageKeys.AUTENTICARED_USER)
      setUser(null)
    }
  })

  return {
    user,
    forgotPassword,
    signIn,
    signUp,
    signOut,
    resetPassword
  }
}

const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

ProvideAuth.propTypes = {
  children: PropTypes.node
}

ProvideAuth.defaultProps = {
  children: null
}

export const useAuth = () => useContext(authContext)
