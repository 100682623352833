// @libs
import { MdClose } from 'react-icons/md'
// @mantine-ui
import { ActionIcon } from '@mantine/core'

function DeleteIconButton({ ...props }) {
  return (
    <ActionIcon radius="xl" {...props}>
      <MdClose size={18} color="orange" />
    </ActionIcon>
  )
}

export default DeleteIconButton
