// @libs
import { useQuery } from '@tanstack/react-query'
// @services
import ApiService from '../../services/api.service'

const useFetchOne = (id, config = {}, queryKey = '') =>
  useQuery([queryKey, id], () => ApiService.fetchById(id), {
    ...config
  })

export default useFetchOne
