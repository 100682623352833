// @libs
import { useState } from 'react'
// @mantine-ui
import { Grid, Group, Switch, Text } from '@mantine/core'
import PropTypes from 'prop-types'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useRoleMutation from '../hooks/useRoleMutation'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
import { openDeleteModal } from '../../../utils/alerts'
// @constants
import { TABLE_HEADERS } from '../constants'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import RoleForm from './RoleForm'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import FilterIconButton from '../../../shared/components/Buttons/FilterIconButton'
import Can from '../../../shared/components/Can'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'

function RoleList({ searchText }) {
  const [showEditForm, setShowEditForm] = useState(false)
  const [roleToEdit, setRoleToEdit] = useState(null)
  const [filters, setFilters] = useState({
    sort_by: ['created_at:asc'],
    page: 1,
    limit: '50'
  })

  const { data, isLoading, isError } = fetchAll({
    key: 'roles',
    filters: {
      ...filters,
      searchText
    }
  })
  const { removeRole } = useRoleMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert />
  if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (role) =>
    openDeleteModal({
      title: 'Eliminar Perfil',
      content: `¿Estás seguro de eliminar el Perfil ${role.name}?.`,
      onConfirm: async () => removeRole.mutateAsync(role.id)
    })
  const handleSort = (option) => {
    const filter = filters.sort_by.find((item) => item.split(':')[0] === option)
    if (filter) {
      setFilters((currentFilters) => ({
        ...currentFilters,
        sort_by: currentFilters.sort_by.map((currentFilter) => {
          const [key, direction] = currentFilter.split(':')
          if (key === option) {
            if (direction === 'asc') return `${option}:desc`
            return `${option}:asc`
          }
          return currentFilter
        })
      }))
    } else {
      setFilters((currentFilters) => ({
        ...currentFilters,
        sort_by: [`${option}:asc`, ...currentFilters.sort_by]
      }))
    }
  }
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS.map((header, index) => (
            <Grid.Col xs={4} key={header.label}>
              <Group position={index === 0 ? 'left' : 'center'}>
                <Text
                  align={index === 0 ? 'start' : 'center'}
                  sx={{
                    fontSize: 16,
                    lineHeight: '24px',
                    letterSpacing: '0.04em',
                    opacity: 0.8
                  }}
                >
                  {header.label}
                </Text>
                <FilterIconButton onClick={() => handleSort(header.key)} />
              </Group>
            </Grid.Col>
          ))}
          <Grid.Col xs={4} />
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {data?.data?.map((role) => (
          <Grid
            key={role.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            {TABLE_HEADERS.map((header, index) => (
              <Grid.Col xs={4} key={header.key}>
                {header.key !== 'is_active' ? (
                  <Text
                    align={index === 0 ? 'start' : 'center'}
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '24px',
                      letterSpacing: '0.04em',
                      opacity: 0.8
                    }}
                    truncate
                  >
                    {role[header.key]}
                  </Text>
                ) : (
                  <Group position="center">
                    <Switch
                      size="sm"
                      color="green"
                      checked={role[header.key]}
                    />
                  </Group>
                )}
              </Grid.Col>
            ))}
            <Grid.Col xs={4}>
              <Group position="center">
                <Can
                  moduleName={MODULES_PERMISSIONS.USERS}
                  action="Perfiles:VIEW"
                  yes={() => (
                    <EditIconButton
                      onClick={() => {
                        setRoleToEdit(role)
                        setShowEditForm((prev) => !prev)
                      }}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.USERS}
                  action="Perfiles:VIEW"
                  yes={() => (
                    <DeleteIconButton onClick={() => handleDelete(role)} />
                  )}
                />
              </Group>
            </Grid.Col>
          </Grid>
        ))}
        <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <RowsCount
            value={filters.limit}
            onChange={(value) => setFilters({ ...filters, limit: value })}
          />
        </Grid.Col>
        <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <PaginationBase
            total={data?.meta?.lastPage}
            value={data?.meta?.currentPage}
            onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
          />
        </Grid.Col>
      </Grid.Col>
      <RoleForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        roleToEdit={roleToEdit}
      />
    </Grid>
  )
}

RoleList.propTypes = {
  searchText: PropTypes.string
}

RoleList.defaultProps = {
  searchText: ''
}

export default RoleList
