// @libs
import * as yup from 'yup'
// @constants
import { REQUIRED_MESSAGE } from '../../utils/contants'

const planSchema = yup.object().shape({
  insurance_company_id: yup
    .number()
    .positive()
    .required(REQUIRED_MESSAGE)
    .nullable(),
  client_type_has_insurance_type_id: yup
    .number()
    .positive()
    .required(REQUIRED_MESSAGE)
    .nullable(),
  compare_value: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  is_active: yup.boolean().default(true),
  tag: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres')
})

export default planSchema
