// @components
import DefaultAlert from './DefaultAlert'

function ErrorAlert() {
  return (
    <DefaultAlert
      title="Error"
      content="No fue posible descargar los datos."
      color="red"
    />
  )
}

export default ErrorAlert
