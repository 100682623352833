// @libs
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useState } from 'react'
// @mantine-ui
import { Button, Grid, TextInput, Box, Select, Textarea } from '@mantine/core'
// @validations
import productSchema from '../validations'
// @hooks
import useProductMutation from '../hooks/useProductMutation'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'
import BasicLoader from '../../../shared/components/BasicLoader'
import useFetchOne from '../../../shared/hooks/useFetchOne'

function ProductsForm({ show, handleShow, planToEdit }) {
  const { addProduct, updateProduct } = useProductMutation()
  const [clientOptions, setClientOptions] = useState([])

  const formik = useFormik({
    initialValues: {
      insurance_company_id: planToEdit?.insurance_company_id || '',
      is_active: planToEdit?.is_active || true,
      name: planToEdit?.name || '',
      client_type_has_insurance_type_id: '',
      tag: planToEdit?.tag || '',
      compare_value: planToEdit?.compare_value || ''
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: productSchema,
    onSubmit: (planData, { resetForm }) => {
      if (planToEdit) {
        return updateProduct
          .mutateAsync([planToEdit.id, { ...planToEdit, ...planData }])
          .then(() => {
            handleShow()
            resetForm()
          })
      }
      return addProduct.mutateAsync(planData).then(() => {
        handleShow()
        resetForm()
      })
    }
  })

  const insuranceCompanies = fetchAll({
    key: 'insurance-companies',
    filters: {
      limit: 'all'
    },
    customConfig: {
      enabled: show && !planToEdit,
      refetchOnMount: false
    }
  })
  const companyDetails = useFetchOne(
    ['insurance-companies', formik.values.insurance_company_id],
    {
      enabled: show && !!formik.values.insurance_company_id,
      onSuccess: (data) => {
        const optionsTuple = data.insurance_types_has_insurance_companies.map(
          (client) => ({
            value: client?.client_type_has_insurance_type?.id,
            label: `${client?.client_type_has_insurance_type?.insuranceType.name} (${client?.client_type_has_insurance_type?.clientType?.name})`,
            group: client?.client_type_has_insurance_type?.clientType?.name
          })
        )
        setClientOptions(optionsTuple)
        formik.setFieldValue(
          'client_type_has_insurance_type_id',
          planToEdit?.client_type_has_insurance_type?.id
        )
      }
    }
  )

  const handleChangeCompany = (companyId) => {
    if (companyId) {
      formik.setFieldValue('insurance_company_id', companyId)
    } else {
      formik.setFieldValue('insurance_company_id', '')
      formik.setFieldValue('client_type_has_insurance_type_id', '')
      setClientOptions([])
    }
  }
  const handleChangeClienType = (clientTypeId) => {
    formik.setFieldValue('client_type_has_insurance_type_id', clientTypeId)
  }

  const handleClose = () => {
    formik.resetForm()
    handleShow()
  }

  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Ingresa la información del nuevo producto aquí"
    >
      {insuranceCompanies.isFetching ? (
        <BasicLoader />
      ) : (
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid.Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Select
                disabled={!!planToEdit}
                name="insurance_company_id"
                value={formik.values.insurance_company_id}
                clearable
                placeholder="Compañia*"
                nothingFound="Sin resultados"
                defaultValue={
                  planToEdit
                    ? {
                        ...companyDetails?.data,
                        value: companyDetails?.data?.id,
                        label:
                          companyDetails?.data?.name ||
                          companyDetails?.data?.business_name
                      }
                    : []
                }
                data={
                  insuranceCompanies?.data?.data?.length > 0
                    ? insuranceCompanies.data.data.map(
                        (insuranceCompany) =>
                          insuranceCompany && {
                            ...insuranceCompany,
                            value: insuranceCompany.id,
                            label:
                              insuranceCompany.name ||
                              insuranceCompany.business_name
                          }
                      )
                    : []
                }
                onChange={(company) => handleChangeCompany(company)}
                error={formik.errors.insurance_company_id}
              />
              <SwitchBase
                label="Activo"
                name="is_active"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                value={formik.values.is_active}
                error={formik.errors.is_active}
              />
            </Grid.Col>
            {formik.values.insurance_company_id && (
              <Grid.Col xs={12}>
                {companyDetails.isLoading || companyDetails.isFetching ? (
                  <Grid>
                    <Grid.Col>
                      <BasicLoader />
                    </Grid.Col>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid.Col xs={12}>
                      <Select
                        name="client_type_has_insurance_type_id"
                        value={formik.values.client_type_has_insurance_type_id}
                        clearable
                        placeholder="Tipo de cliente*"
                        nothingFound="Sin resultados"
                        data={clientOptions}
                        onChange={(clientType) =>
                          handleChangeClienType(clientType)
                        }
                        error={formik.errors.client_type_has_insurance_type_id}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <TextInput
                        placeholder="Nombre comercial*"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.errors.name}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <Textarea
                        placeholder="Tag de respuesta*"
                        name="tag"
                        onChange={formik.handleChange}
                        value={formik.values.tag}
                        error={formik.errors.tag}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <TextInput
                        placeholder="Valor a comparar*"
                        name="compare_value"
                        onChange={formik.handleChange}
                        value={formik.values.compare_value}
                        error={formik.errors.compare_value}
                      />
                    </Grid.Col>
                  </Grid>
                )}
              </Grid.Col>
            )}
            <Grid.Col xs={12} mt={16}>
              <Button
                fullWidth
                loading={addProduct.isLoading || updateProduct.isLoading}
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

ProductsForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  planToEdit: PropTypes.object
}

ProductsForm.defaultProps = {
  show: false,
  handleShow: () => {},
  planToEdit: null
}

// ruta del producto
// "OfertasBase.OfertaBase.producto"

// Ruta del plan
// "OfertasBase.OfertaBase.Deducibles.DeducibleBase.nombrePlan"

export default ProductsForm
