import { Grid } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import 'dayjs/locale/es-mx'
import PropTypes from 'prop-types'

function AttributesDateRangeView({ formik }) {
  return (
    <>
      <Grid.Col xs={6}>
        <DatePicker
          locale="es-mx"
          placeholder="Fecha de inicio"
          value={
            formik.values.attribute_data.startDate
              ? new Date(formik.values.attribute_data.startDate)
              : null
          }
          maxDate={formik.values.attribute_data.endDate}
          onChange={(val) =>
            formik.setFieldValue('attribute_data', {
              ...formik.values.attribute_data,
              startDate: val
            })
          }
        />
      </Grid.Col>
      <Grid.Col xs={6}>
        <DatePicker
          locale="es-mx"
          placeholder="Fecha de termino"
          value={
            formik.values.attribute_data.endDate
              ? new Date(formik.values.attribute_data.endDate)
              : null
          }
          minDate={formik.values.attribute_data.startDate}
          onChange={(val) =>
            formik.setFieldValue('attribute_data', {
              ...formik.values.attribute_data,
              endDate: val
            })
          }
        />
      </Grid.Col>
    </>
  )
}

AttributesDateRangeView.propTypes = {
  formik: PropTypes.object
}

AttributesDateRangeView.defaultProps = {
  formik: null
}

export default AttributesDateRangeView
