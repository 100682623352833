// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Box, Checkbox, Grid, Text } from '@mantine/core'
// @hooks
import useCodeMutations from '../hooks/useCodeMutations'
// @utils
import { openDeleteModal } from '../../../utils/alerts'
// @constants
import { TABLE_HEADERS_DETAILS } from '../constants'
// @components
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import FilterIconButton from '../../../shared/components/Buttons/FilterIconButton'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import BasicLoader from '../../../shared/components/BasicLoader'
import ErrorAlert from '../../../shared/components/ErrorAlert'
import PaginationBase from '../../../shared/components/PaginationBase'

function CodeDetailsList({
  isAllSelected,
  codes,
  codesData,
  setSelectedCodes,
  setIsAllSelected,
  onChangeFilters
}) {
  const { removeCode } = useCodeMutations()

  const handleDelete = (code) => {
    openDeleteModal({
      title: 'Eliminar Código',
      content: `¿Estás seguro de eliminar el código identificado como: ${
        code.code
      }, asociado al rut: ${code.associated_rut || ''}?.`,
      onConfirm: async () => removeCode.mutateAsync(code.id)
    })
  }
  if (codesData.isLoading) return <BasicLoader />
  if (codesData.isError) return <ErrorAlert />
  if (!codesData?.data?.data?.length) return <NoDataAlert />

  return (
    <Grid>
      {codes.length > 0 && (
        <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Checkbox
            checked={isAllSelected}
            label="Seleccionar todos"
            onChange={(e) => setIsAllSelected(e.currentTarget.checked)}
          />
        </Grid.Col>
      )}
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS_DETAILS.map((header) => (
            <Grid.Col xs={2} key={header.label}>
              <Text
                sx={{
                  fontSize: 16,
                  lineHeight: '24px',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  display: 'flex'
                }}
              >
                {header.label}
                <FilterIconButton />
              </Text>
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {codes.map((code) => (
          <Grid
            key={code.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            {TABLE_HEADERS_DETAILS.map((header) => (
              <Grid.Col xs={2} key={header.key}>
                <Text
                  sx={{
                    fontWeight: 700,
                    fontSize: 16,
                    lineHeight: '24px',
                    letterSpacing: '0.04em',
                    opacity: 0.8
                  }}
                  truncate
                  title={code[header.key]}
                >
                  {code[header.key]}
                </Text>
              </Grid.Col>
            ))}
            <Grid.Col xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <Checkbox
                  checked={code.isSelected}
                  mr={8}
                  onChange={(e) => {
                    const updatedCodes = codes.map((c) => {
                      if (c.id === code.id) {
                        return {
                          ...c,
                          isSelected: e.currentTarget.checked
                        }
                      }
                      return c
                    })
                    if (updatedCodes.every((c) => !c.isSelected)) {
                      setIsAllSelected(false)
                    }
                    setSelectedCodes(updatedCodes)
                  }}
                />
                <DeleteIconButton onClick={() => handleDelete(code)} />
              </Box>
            </Grid.Col>
          </Grid>
        ))}
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <PaginationBase
          total={codesData?.data?.meta?.lastPage}
          value={codesData?.data?.meta?.currentPage}
          onChange={(value) =>
            onChangeFilters((cf) => ({ ...cf, page: value }))
          }
        />
      </Grid.Col>
    </Grid>
  )
}

CodeDetailsList.propTypes = {
  isAllSelected: PropTypes.bool,
  setSelectedCodes: PropTypes.func,
  setIsAllSelected: PropTypes.func,
  codesData: PropTypes.object,
  onChangeFilters: PropTypes.func,
  codes: PropTypes.array
}

CodeDetailsList.defaultProps = {
  isAllSelected: false,
  setSelectedCodes: () => {},
  setIsAllSelected: () => {},
  codesData: {},
  onChangeFilters: () => {},
  codes: []
}

export default CodeDetailsList
