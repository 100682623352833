// @libs
import { useState } from 'react'
// @mantine-ui
import { Box, Button, Grid } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import ClientTypeList from '../components/InsuranceTypeList'
import InsuranceTypeForm from '../components/InsuranceTypeForm'
import Can from '../../../shared/components/Can'
import SearchInput from '../../../shared/components/Inputs/SearchInput'

function InsuranceTypes() {
  const [showForm, setShowForm] = useState(false)
  const [value, setValue] = useDebouncedState('', 500)

  return (
    <Page title="Tipos de Seguros">
      <Grid>
        <Grid.Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <PageTitle title="Tipos de Seguros" />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <SearchInput
                defaultValue={value}
                onChange={(event) => setValue(event.currentTarget.value)}
              />
              <Can
                moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
                action="Tipos de Seguro:CREATE"
                yes={() => (
                  <Button
                    sx={{ marginLeft: 16 }}
                    onClick={() => setShowForm((prev) => !prev)}
                  >
                    Agregar tipo de seguro
                  </Button>
                )}
              />
            </Box>

            <InsuranceTypeForm
              show={showForm}
              handleShow={() => setShowForm((prev) => !prev)}
            />
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} mt={16}>
          <ClientTypeList searchText={value} />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default InsuranceTypes
