const Button = {
  styles: (theme) => ({
    root: {
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '18px',
      textAlign: 'center',
      letterSpacing: '-0.015em',
      // color: '#FFFFFF',
      borderRadius: theme.radius.xs * 3
    }
  })
}
export default Button
