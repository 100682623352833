import { useState } from 'react'
// @mantine-ui
import { Box, Grid, Text } from '@mantine/core'
// @constants
import { PAGE_TITLE_STATS } from '../constants'
// @hooks
import { useUser } from '../../../shared/hooks/useUser'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import AgentCodeStatsList from '../components/AgentCodeStatsList'
import AgentLinkConditions from '../components/AgentLinkConditions'
import AgentCampaigns from '../components/AgentCampaigns'

function AgentStats() {
  const user = useUser()
  const [campaign, setCampaign] = useState(null)
  return (
    <Page title={PAGE_TITLE_STATS}>
      <Grid>
        <Grid.Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <PageTitle
              title={`${PAGE_TITLE_STATS} ${user?.name} ${user?.last_name}`}
            />
            <Text>
              <span
                style={{
                  fontFamily: 'Assistant',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 15,
                  lineHeight: '14px',
                  letterSpacing: '-0.015em',
                  color: '#F28D01'
                }}
              >
                Código:{' '}
              </span>
              {user?.agent?.code || 'Sin código'}
            </Text>
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <AgentCampaigns
            agent={user?.agent}
            campaign={campaign}
            changeCampaign={setCampaign}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <AgentLinkConditions agent={user?.agent} />
        </Grid.Col>
        <Grid.Col xs={12} mt={16}>
          <AgentCodeStatsList />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default AgentStats
