// @libs
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { RiDeleteBinFill, RiEdit2Fill } from 'react-icons/ri'
import PropTypes from 'prop-types'
// @mantine-ui
import { ActionIcon, Paper, Badge, ScrollArea } from '@mantine/core'

const noOp = () => null

function OptionsList({
  onDragEnd,
  removeSectionOptionItem,
  handleEditOption,
  section,
  sectionItem
}) {
  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, section.id, sectionItem.id)}
    >
      <Droppable
        droppableId={`formSectionsOptionItem_${sectionItem.id}`}
        direction="horizontal"
      >
        {(provided) => (
          <Paper
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={(theme) => ({
              borderRadius: theme.radius.md,
              padding: theme.spacing.sm,
              marginBottom: theme.spacing.sm,
              backgroundColor: '#D9D9D9'
            })}
          >
            <ScrollArea.Autosize>
              {sectionItem.formSectionListItem.map((option, optionIndex) => (
                <Draggable
                  key={option.id}
                  draggableId={String(option.id)}
                  index={optionIndex}
                >
                  {(draggableProvided) => (
                    <Badge
                      {...draggableProvided.draggableProps}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                      sx={{ textTransform: 'none' }}
                      pr={3}
                      mx={3}
                      color="gray"
                      rightSection={
                        <ActionIcon
                          size="xs"
                          radius="xl"
                          variant="transparent"
                          onClick={() =>
                            removeSectionOptionItem(
                              section.id,
                              sectionItem.id,
                              option.id
                            )
                          }
                        >
                          <RiDeleteBinFill />
                        </ActionIcon>
                      }
                      leftSection={
                        <ActionIcon
                          size="xs"
                          radius="xl"
                          variant="transparent"
                          onClick={() =>
                            handleEditOption(section.id, sectionItem.id, option)
                          }
                        >
                          <RiEdit2Fill />
                        </ActionIcon>
                      }
                    >
                      {option.name}{' '}
                      {option.sectionRef !== undefined &&
                        `#id:${option.sectionRef}`}
                    </Badge>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ScrollArea.Autosize>
          </Paper>
        )}
      </Droppable>
    </DragDropContext>
  )
}

OptionsList.propTypes = {
  onDragEnd: PropTypes.func,
  removeSectionOptionItem: PropTypes.func,
  handleEditOption: PropTypes.func,
  section: PropTypes.object,
  sectionItem: PropTypes.object
}

OptionsList.defaultProps = {
  handleEditOption: noOp,
  removeSectionOptionItem: noOp,
  onDragEnd: noOp,
  section: null,
  sectionItem: null
}

export default OptionsList
