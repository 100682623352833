export const APPBAR_HEIGHT = 96
export const REQUIRED_MESSAGE = '*Este es un campo requerido.'
export const NAV_LINKS = [
  { label: 'Clasificaciones de Riesgo', link: '/risk-classifications' },
  {
    label: 'Tipos de Seguro',
    links: [
      { label: 'Tipos de Cliente', link: '/client-types' },
      { label: 'Tipos de Seguro', link: '/insurance-types' }
    ]
  },
  { label: 'Compañías de Seguros', link: '/insurance-companies' },
  {
    label: 'Configuración de Seguros',
    links: [
      { label: 'Atributos', link: '/attributes' },
      {
        label: 'Configuración',
        link: '/insurance-settings'
      },
      { label: 'Formularios', link: '/forms' },
      { label: 'Planes', link: '/plans' },
      { label: 'Productos', link: '/products' }
    ]
  },
  {
    label: 'Agentes',
    links: [
      { label: 'Creación de Agentes', link: '/agents' },
      { label: 'Códigos', link: '/code-campaigns' },
      { label: 'Estadísticas', link: '/agents-stats' }
    ]
  },
  {
    label: 'Usuarios',
    links: [
      { label: 'Perfiles', link: '/roles' },
      { label: 'Usuarios', link: '/users' }
    ]
  }
]
export const MODULES_PERMISSIONS = {
  RISK_CLASSIFICATIONS: 'Clasificaciones de Riesgo',
  INSURANCES_TYPES: 'Tipos de Seguro',
  INSURANCES_COMPANIES: 'Compañías de Seguros',
  INSURANCES_SETTINGS: 'Configuración de Seguros',
  AGENTS: 'Agentes',
  USERS: 'Usuarios'
}
