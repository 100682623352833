// @libs
import { useMemo } from 'react'
import PropTypes from 'prop-types'
// @mantine-ui
import { Grid } from '@mantine/core'
// @components
import Page from './Page'
import PageTitle from './PageTitle'
import DefaultAlert from './Alerts/DefaultAlert'

function ErrorPage({ code, pageTitle, children }) {
  const ERROR_MESSAGE = useMemo(
    () => ({
      401: {
        pageTitle: 'Error 401',
        message: 'no tienes los permisos necesarios para ver esta página',
        type: 'yellow'
      },
      404: {
        pageTitle: 'Error 404',
        message: 'la página que buscas no existe o no se encuentra disponible',
        type: 'yellow'
      },
      500: {
        pageTitle: 'Error 500',
        message: 'no fue posible descargar los datos',
        type: 'red'
      }
    }),
    []
  )

  return (
    <Page title={`Error ${code}`}>
      <Grid>
        <Grid.Col item xs={12}>
          <PageTitle title={pageTitle || ERROR_MESSAGE[code].pageTitle} />
        </Grid.Col>
        <Grid.Col item xs={12}>
          <DefaultAlert
            title={code === 500 ? 'Error' : 'Información'}
            color={ERROR_MESSAGE[code].type}
            content={`Lo sentimos, ${ERROR_MESSAGE[code].message}.`}
          />
        </Grid.Col>
        <Grid.Col item xs={12}>
          {children}
        </Grid.Col>
      </Grid>
    </Page>
  )
}

ErrorPage.propTypes = {
  code: PropTypes.oneOf([404, 401, 500]),
  pageTitle: PropTypes.string,
  children: PropTypes.node
}

ErrorPage.defaultProps = {
  code: 404,
  pageTitle: '',
  children: null
}

export default ErrorPage
