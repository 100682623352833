// @libs
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
// @mantine-ui
import { Button, Grid, TextInput, Box } from '@mantine/core'
// @validations
import riskClassificationSchema from '../validations'
// @hooks
import useRiskClassificationMutation from '../hooks/useRiskClassifcationMutation'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'

function RiskClassificationForm({
  show,
  handleShow,
  riskClassificationToEdit
}) {
  const { addRiskClassification, updateRiskClassification } =
    useRiskClassificationMutation()

  const formik = useFormik({
    initialValues: {
      name: riskClassificationToEdit?.name || '',
      is_active: riskClassificationToEdit?.is_active || true
    },
    enableReinitialize: true,
    validationSchema: riskClassificationSchema,
    onSubmit: (data, { resetForm }) => {
      if (riskClassificationToEdit) {
        return updateRiskClassification
          .mutateAsync([
            riskClassificationToEdit.id,
            { ...riskClassificationToEdit, ...data }
          ])
          .then(() => {
            handleShow()
            resetForm()
          })
      }
      return addRiskClassification.mutateAsync(data).then(() => {
        handleShow()
        resetForm()
      })
    }
  })

  const handleClose = () => {
    formik.resetForm()
    handleShow()
  }

  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Ingresa la información del riesgo aquí"
    >
      <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Grid.Col
            span={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <TextInput
              placeholder="Nombre"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.errors.name}
            />
            <SwitchBase
              name="is_active"
              checked={formik.values.is_active}
              onChange={formik.handleChange}
              error={formik.errors.is_active}
            />
          </Grid.Col>
          <Grid.Col span={12} mt={16}>
            <Button
              fullWidth
              loading={
                addRiskClassification.isLoading ||
                updateRiskClassification.isLoading
              }
              loaderPosition="right"
              type="submit"
            >
              Guardar
            </Button>
          </Grid.Col>
        </Grid>
      </Box>
    </ModalBase>
  )
}

RiskClassificationForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  riskClassificationToEdit: PropTypes.object
}

RiskClassificationForm.defaultProps = {
  show: false,
  handleShow: () => {},
  riskClassificationToEdit: null
}

export default RiskClassificationForm
