// @libs
import { useFormik } from 'formik'
import { useState } from 'react'
import PropTypes from 'prop-types'
// @mantine-ui
import {
  Button,
  Grid,
  TextInput,
  Box,
  Checkbox,
  Accordion,
  Text
} from '@mantine/core'
// @validations
import riskClassificationSchema from '../validations'
// @hooks
import useRoleMutation from '../hooks/useRoleMutation'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'
import ModuleItem from './ModuleItem'
import BasicLoader from '../../../shared/components/BasicLoader'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import useFetchOne from '../../../shared/hooks/useFetchOne'

function RoleForm({ show, handleShow, roleToEdit }) {
  const { addRole, updateRole } = useRoleMutation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [items, setItems] = useState([])

  const formik = useFormik({
    initialValues: {
      name: roleToEdit ? roleToEdit.name : '',
      is_active: roleToEdit ? roleToEdit.is_active : true,
      permissions: []
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: riskClassificationSchema,
    onSubmit: (data, { resetForm }) => {
      if (roleToEdit) {
        return updateRole
          .mutateAsync([roleToEdit.id, { ...roleToEdit, ...data }])
          .then(() => {
            handleShow()
            resetForm()
          })
      }
      return addRole.mutateAsync(data).then(() => {
        handleShow()
        resetForm()
      })
    }
  })

  const { isLoading, isError } = useFetchOne(['roles', roleToEdit?.id], {
    enabled: show && !!roleToEdit?.id,
    onSuccess: (role) => {
      if (role.permissions.length) {
        formik.setFieldValue(
          'permissions',
          role?.permissions?.map((p) => p.permissionId)
        )
      }
    }
  })
  const permissions = fetchAll({
    key: 'permissions',
    customConfig: {
      enabled: show,
      staleTime: 60000 * 6
    }
  })

  const handleClose = () => {
    formik.resetForm()
    handleShow()
  }
  const handleChangePermission = (permissionId, isAdded) => {
    const currentPermission = formik.values.permissions
    if (isAdded) {
      return formik.setFieldValue('permissions', [
        ...currentPermission,
        permissionId
      ])
    }
    return formik.setFieldValue(
      'permissions',
      currentPermission.filter((p) => p !== permissionId)
    )
  }

  const handleFullExpanded = (value) => {
    setIsExpanded(value)
    if (value) {
      return setItems(permissions?.data?.map((module) => module.name))
    }
    return setItems([])
  }

  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Agrega o edita un perfil de usuario aquí"
    >
      {((roleToEdit && isLoading) || permissions.isLoading) && <BasicLoader />}
      {(isError || permissions.isError) && <ErrorAlert />}

      {!permissions.isLoading && !isError && permissions?.data?.length && (
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid.Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <TextInput
                placeholder="Nombre"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.errors.name}
              />
              <SwitchBase
                name="is_active"
                label="Activo"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                value={formik.values.is_active}
                error={formik.errors.is_active}
              />
            </Grid.Col>
            <Grid.Col
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Checkbox
                defaultChecked={isExpanded}
                onChange={(e) => handleFullExpanded(e.currentTarget.checked)}
                label={
                  <Text
                    sx={{
                      fontFamily: 'Montserrat'
                    }}
                  >
                    {isExpanded ? 'Contraer todo' : 'Expandir todo'}
                  </Text>
                }
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Accordion multiple value={items} onChange={setItems}>
                {permissions?.data?.map((module) => (
                  <ModuleItem
                    key={module.id}
                    module={module}
                    handleChangePermission={handleChangePermission}
                    currentPermissions={formik.values.permissions}
                  />
                ))}
              </Accordion>
            </Grid.Col>
            <Grid.Col span={12} mt={16}>
              <Button
                fullWidth
                loading={addRole.isLoading || updateRole.isLoading}
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

RoleForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  roleToEdit: PropTypes.object
}

RoleForm.defaultProps = {
  show: false,
  handleShow: () => {},
  roleToEdit: null
}

export default RoleForm
