import { Grid, Text, ActionIcon, Group } from '@mantine/core'
import { IoSearch } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
// @hooks
// @constants
import { TABLE_STATS_HEADERS } from '../constants'
// @components
import { useUser } from '../../../shared/hooks/useUser'
import fetchAll from '../../../shared/hooks/useFetchAll'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import FilterIconButton from '../../../shared/components/Buttons/FilterIconButton'

const statusDict = {
  PENDING: 'Iniciado'
}
function AgentCodeStatsList() {
  const navigate = useNavigate()
  const user = useUser()
  const { data } = fetchAll({
    key: `agents/${user.agent.id}/clients`
    // customConfig: {
    //   onSuccess: (responseData) => {
    //     console.log(responseData)
    //   }
    // }
  })

  if (!data?.length) return <NoDataAlert />

  return (
    <Grid>
      <Grid.Col xs={12}>
        <Text
          sx={{
            fontWeight: 700,
            fontSize: 24,
            letterSpacing: '0.04em',
            opacity: 0.8,
            lineHeight: '26px'
          }}
        >
          Clientes
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} mt={8}>
        <Grid px={12}>
          {TABLE_STATS_HEADERS.map((header) => (
            <Grid.Col xs={2} key={header.label}>
              <Text
                sx={{
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  display: 'flex'
                }}
              >
                {header.label}
                {header.isSortable && <FilterIconButton />}
              </Text>
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12} px={12}>
        {data?.map((agentStat) => (
          <Grid
            key={agentStat.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            <Grid.Col xs={2}>
              <Text
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {agentStat.rut}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Text
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {agentStat.name}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Text
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {agentStat.code_id ? 'Codigo' : 'Link'}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Text
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {agentStat.client_type_has_insurance_type?.clientType?.name} (
                {agentStat.client_type_has_insurance_type?.insuranceType?.name})
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Text
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {statusDict[agentStat.state]}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Group position="right">
                <ActionIcon
                  radius="xl"
                  onClick={() =>
                    navigate(
                      `/cotiza/${agentStat.client_type_has_insurance_type_id}`,
                      { state: { formAnswers: agentStat.formAnswers } }
                    )
                  }
                >
                  <IoSearch size={18} color="orange" />
                </ActionIcon>
              </Group>
            </Grid.Col>
          </Grid>
        ))}
      </Grid.Col>
    </Grid>
  )
}

export default AgentCodeStatsList
