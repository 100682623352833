// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Modal, ScrollArea } from '@mantine/core'

function ModalBase({ show, handleShow, children, ...props }) {
  return (
    <Modal
      size="md"
      closeOnClickOutside={false}
      opened={show}
      onClose={handleShow}
      title="Title"
      padding={40}
      scrollAreaComponent={ScrollArea.Autosize}
      {...props}
    >
      {children}
    </Modal>
  )
}

ModalBase.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  children: PropTypes.node
}

ModalBase.defaultProps = {
  show: false,
  handleShow: () => {},
  children: null
}

export default ModalBase
