// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Grid, Text } from '@mantine/core'
// @hooks
import useFetchOne from '../../../shared/hooks/useFetchOne'
// @components
import ErrorPage from '../../../shared/components/ErrorPage'
import BasicLoader from '../../../shared/components/BasicLoader'

function UserDetails({ isOpen, userId }) {
  const {
    data: user,
    isLoading,
    isError
  } = useFetchOne(['users', userId], {
    enabled: isOpen && !!userId
  })

  if (isError) return <ErrorPage code={500} />
  if (isLoading) return <BasicLoader />

  return (
    <Grid>
      <Grid.Col xs={12}>
        <Text
          sx={{
            fontWeight: 700,
            fontSize: 24,
            lineHeight: '31px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Detalles del usuario
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={4}>
        <Text
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Perfil: {user?.role?.name}
        </Text>
      </Grid.Col>
    </Grid>
  )
}
UserDetails.propTypes = {
  userId: PropTypes.number,
  isOpen: PropTypes.bool
}

UserDetails.defaultProps = {
  userId: null,
  isOpen: false
}
export default UserDetails
