import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  RiCloseLine,
  RiFileTextLine,
  RiCalculatorLine,
  RiCalendarEventFill,
  RiCalculatorFill,
  RiCheckboxLine
} from 'react-icons/ri'
import {
  Grid,
  Select,
  Group,
  Button,
  TextInput,
  NumberInput,
  Badge,
  ActionIcon
} from '@mantine/core'
// @components

const tableDataTypes = [
  'Texto',
  'Numero Entero',
  'Numero Decimal',
  'Fecha',
  'Si/No'
]

const initialState = {
  column: '',
  dataType: ''
}

function AttributesTableView({ formik }) {
  const [formData, setFormData] = useState(initialState)

  const handleChange = (e) => {
    const {
      target: { value, name }
    } = e

    setFormData({ ...formData, [name]: value })
  }

  const handleAddColumn = () => {
    const tempObject = {
      fieldName: formData.column,
      displayName: formData.column,
      dataType: formData.dataType
    }

    const { columns } = formik.values.attribute_data

    const isAlreadyIncluded = columns.some(
      (item) => item.fieldName === formData.column
    )

    if (!isAlreadyIncluded) {
      setFormData(initialState)
      formik.setFieldValue('attribute_data', {
        ...formik.values.attribute_data,
        columns: [...columns, tempObject]
      })
    }
  }

  const removeAttribute = (option, optionIndex) => {
    const { columns } = formik.values.attribute_data
    const filtered = columns.filter((item, index) => index !== optionIndex)
    formik.setFieldValue('attribute_data', {
      ...formik.values.attribute_data,
      columns: filtered
    })
  }

  return (
    <>
      <Grid.Col xs={12} mt={12}>
        <TextInput
          name="column"
          placeholder="Columnas"
          value={formData.column}
          onChange={handleChange}
        />
      </Grid.Col>
      <Grid.Col xs={12}>
        <Select
          placeholder="Tipos de dato"
          data={tableDataTypes}
          value={formData.dataType}
          onChange={(value) => setFormData({ ...formData, dataType: value })}
        />
      </Grid.Col>
      <Grid.Col xs={12}>
        <Button
          onClick={handleAddColumn}
          size="xs"
          color="teal"
          disabled={!formData.column || !formData.dataType}
        >
          Agregar columna
        </Button>
      </Grid.Col>
      {formik.values.attribute_data.columns &&
        formik.values.attribute_data.columns.length > 0 && (
          <Grid.Col xs={12} my={12}>
            <Group>
              {formik.values.attribute_data.columns.map((item, index) => (
                <Badge
                  pr={3}
                  mx={3}
                  size="lg"
                  color="gray"
                  variant="outline"
                  rightSection={
                    <ActionIcon
                      size="xs"
                      radius="xl"
                      color="red"
                      variant="transparent"
                      onClick={() => removeAttribute(item, index)}
                    >
                      <RiCloseLine />
                    </ActionIcon>
                  }
                  leftSection={
                    <ActionIcon
                      size="xs"
                      radius="xl"
                      variant="transparent"
                      color="orange"
                      title={item.dataType}
                    >
                      {item.dataType === 'Texto' && <RiFileTextLine />}
                      {item.dataType === 'Fecha' && <RiCalendarEventFill />}
                      {item.dataType === 'Si/No' && <RiCheckboxLine />}
                      {item.dataType === 'Numero Decimal' && (
                        <RiCalculatorFill />
                      )}
                      {item.dataType === 'Numero Entero' && (
                        <RiCalculatorLine />
                      )}
                    </ActionIcon>
                  }
                  sx={{ textTransform: 'none' }}
                >
                  {item.displayName}
                </Badge>
              ))}
            </Group>
          </Grid.Col>
        )}
      <Grid.Col xs={12} my={12}>
        <NumberInput
          placeholder="Filas"
          max={10}
          min={1}
          value={formik.values.attribute_data.rowNumber}
          onChange={(val) =>
            formik.setFieldValue('attribute_data', {
              ...formik.values.attribute_data,
              rowNumber: val
            })
          }
        />
      </Grid.Col>
    </>
  )
}

AttributesTableView.propTypes = {
  formik: PropTypes.object
}

AttributesTableView.defaultProps = {
  formik: null
}

export default AttributesTableView
