// @libs
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mantine-ui
import { Group, Grid, Switch, Text } from '@mantine/core'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
// @utils
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import PaginationBase from '../../../shared/components/PaginationBase'

function InsuranceSettingList() {
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    page: 1,
    limit: 5,
    onlyConfigured: true
  })

  const { data, isLoading, isError } = fetchAll({
    key: 'client-types-has-insurance-types',
    filters,
    customConfig: {
      refetchOnMount: true
    }
  })

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert code={500} />
  if (!data?.length) return <NoDataAlert />

  return (
    <Grid>
      <Grid.Col span={3}>
        <Text
          align="start"
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Tipo de Cliente
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text
          align="center"
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Tipo de Seguro
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text
          align="center"
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Activo
        </Text>
      </Grid.Col>
      <Grid.Col span={3} />
      <Grid.Col span={12}>
        {data?.map((uniqueTuple) => (
          <Grid
            key={uniqueTuple.id}
            sx={{
              backgroundColor: 'white',
              borderRadius: 16,
              marginBottom: 16,
              display: 'flex',
              alignItems: 'center'
            }}
            px={8}
          >
            <Grid.Col span={3}>
              <Text
                align="start"
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {uniqueTuple.clientType.name}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text
                align="center"
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {uniqueTuple.insuranceType.name}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Group position="center">
                <Switch size="sm" color="green" checked />
              </Group>
            </Grid.Col>
            <Grid.Col span={3}>
              <Group position="center">
                <EditIconButton
                  onClick={() =>
                    navigate(`/insurance-settings/${uniqueTuple.id}`)
                  }
                />
              </Group>
            </Grid.Col>
          </Grid>
        ))}
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <PaginationBase
          total={data?.meta?.lastPage}
          value={data?.meta?.currentPage}
          onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
        />
      </Grid.Col>
    </Grid>
  )
}

export default InsuranceSettingList
