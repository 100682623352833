import buttonOverride from './Button'
import inputOverride from './Input'
import passwordInputOverride from './PasswordInput'
import modalOverride from './Modal'
import selectOverride from './Select'
import multiSelectOverride from './MultiSelect'
import textOverride from './Text'
import switchOverride from './Switch'

const components = {
  Button: buttonOverride,
  Input: inputOverride,
  Modal: modalOverride,
  PasswordInput: passwordInputOverride,
  Select: selectOverride,
  MultiSelect: multiSelectOverride,
  Text: textOverride,
  Switch: switchOverride
}

export default components
