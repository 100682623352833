// @libs
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { useListState } from '@mantine/hooks'
import { AiOutlineDrag } from 'react-icons/ai'
// @mantine-ui
import { Button, Grid, Text, Paper, Group, LoadingOverlay } from '@mantine/core'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import useDraggableInPortal from '../../../shared/hooks/useDraggableInPortal'
import fetchOne from '../../../shared/hooks/useFetchOne'
import useFormsMutation from '../hooks/useFormsMutation'

function FormsOrderModal({ uniqueTupleId }) {
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [state, handlers] = useListState([])
  const renderDraggable = useDraggableInPortal()
  const { updateOrdersForms } = useFormsMutation()

  const { status } = fetchOne(
    ['client-types-has-insurance-types', uniqueTupleId],
    {
      refetchOnMount: true,
      onSuccess: (response) => {
        handlers.setState(
          response.forms.map((item) => ({
            id: item.id,
            name: item.name,
            order: item.order
          }))
        )
      }
    }
  )

  const handleDragEnd = async (result) => {
    const { source, destination } = result
    if (!destination) return

    handlers.reorder({ from: source.index, to: destination.index })
  }

  const handleUpdateForms = async () => {
    try {
      setIsLoading(true)
      await updateOrdersForms.mutateAsync(state)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button onClick={() => setShow((prev) => !prev)}>
        Orden de secciones de formularios
      </Button>

      <ModalBase
        title="Ingresa la información del orden de formularios aquí"
        show={show}
        handleShow={() => setShow((prev) => !prev)}
        size="lg"
      >
        <Grid>
          <LoadingOverlay visible={status === 'loading'} overlayBlur={2} />
          <Grid.Col xs={12}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="myDoppableId">
                {(provided) => (
                  <Paper {...provided.droppableProps} ref={provided.innerRef}>
                    {state.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}-${item.name}`}
                        index={index}
                      >
                        {renderDraggable((draggableProvided) => (
                          <Paper
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            ref={draggableProvided.innerRef}
                            radius="xl"
                            p="md"
                            my={6}
                            withBorder
                            sx={(theme) => ({
                              borderColor: theme.colors.orange[5]
                            })}
                          >
                            <Group grow>
                              <Text
                                sx={{
                                  fontWeight: 400,
                                  width: '90%'
                                }}
                              >
                                {index + 1} - {item.name}
                              </Text>
                              <AiOutlineDrag style={{ color: '#F28D01' }} />
                            </Group>
                          </Paper>
                        ))}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </DragDropContext>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Group position="center">
              <Button loading={isLoading} onClick={handleUpdateForms}>
                Guardar cambios
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </ModalBase>
    </>
  )
}

FormsOrderModal.propTypes = {
  uniqueTupleId: PropTypes.number
}

FormsOrderModal.defaultProps = {
  uniqueTupleId: null
}

export default FormsOrderModal
