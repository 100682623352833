// @libs
import { FaRegFileExcel } from 'react-icons/fa'
// @mantine-ui
import { ActionIcon, Tooltip } from '@mantine/core'

function ExcelIconButton({ ...props }) {
  return (
    <Tooltip
      label="Descargar formato"
      color="yellow"
      position="bottom"
      withArrow
    >
      <ActionIcon radius="xl" {...props}>
        <FaRegFileExcel size={18} color="orange" />
      </ActionIcon>
    </Tooltip>
  )
}

export default ExcelIconButton
