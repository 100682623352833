// @libs
import { useState } from 'react'
// @mantine-ui
import { Collapse, Grid, Switch, Text, Group } from '@mantine/core'
import PropTypes from 'prop-types'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useInsuranceCompanyMutation from '../hooks/useInsuranceCompanyMutation'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
import { openDeleteModal } from '../../../utils/alerts'
// @constants
import { TABLE_HEADERS } from '../constants'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import InsuranceCompanyForm from './InsuranceCompanyForm'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import DetailsIconButton from '../../../shared/components/Buttons/DetailsIconButton'
import InsuranceCompanyDetails from './InsuranceCompanyDetails'
import Can from '../../../shared/components/Can'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'

function InsuranceCompanyList({ searchText }) {
  const [showEditForm, setShowEditForm] = useState(false)
  const [insuranceCompanyToEdit, setInsuranceCompanyToEdit] = useState(null)
  const [insuranceCompanyDetails, setInsuranceCompanyDetails] = useState(null)
  const [filters, setFilters] = useState({
    sort_by: ['created_at:desc'],
    page: 1,
    limit: '50'
  })

  const { data, isLoading, isError } = fetchAll({
    key: 'insurance-companies',
    filters: { ...filters, searchText }
  })
  const { removeInsuranceCompany } = useInsuranceCompanyMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert code={500} />
  if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (insuranceCompany, i) =>
    openDeleteModal({
      title: 'Eliminar Compañia de Seguros',
      content: `¿Estás seguro de eliminar la compañía de seguros ${
        insuranceCompany.name || insuranceCompany.business_name
      }?.`,
      onConfirm: async () =>
        removeInsuranceCompany.mutateAsync(insuranceCompany.id).then(() => {
          if (!i && filters.page > 1) {
            setFilters((cf) => ({
              ...cf,
              page: cf.page - 1
            }))
          }
        })
    })
  const handleInsuranceCompanyDetails = (insuranceCompany) => {
    if (insuranceCompany === insuranceCompanyDetails)
      return setInsuranceCompanyDetails(null)
    return setInsuranceCompanyDetails(insuranceCompany)
  }
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS.map((header, index) => (
            <Grid.Col xs={header.col_size} key={header.label}>
              <Text
                align={index === 0 ? 'justify' : 'center'}
                sx={{
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {header.label}
              </Text>
            </Grid.Col>
          ))}
          <Grid.Col xs={2} />
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {data?.data?.map((insuranceCompany, i) => (
          <Grid
            key={insuranceCompany.id}
            sx={{
              backgroundColor: 'white',
              borderRadius: 16,
              marginBottom: 16,
              display: 'flex',
              alignItems: 'center'
            }}
            px={12}
          >
            {TABLE_HEADERS.map((header, index) => (
              <Grid.Col xs={header.col_size} key={header.key}>
                {!header.key.includes('is_active') ? (
                  <Text
                    align={index === 0 ? 'start' : 'center'}
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '26px',
                      letterSpacing: '0.04em',
                      opacity: 0.8
                    }}
                    truncate
                  >
                    {insuranceCompany[header.key]}
                  </Text>
                ) : (
                  <Group position="center">
                    <Switch
                      size="sm"
                      color="green"
                      checked={insuranceCompany[header.key]}
                    />
                  </Group>
                )}
              </Grid.Col>
            ))}
            <Grid.Col xs={2}>
              <Group position="center">
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_COMPANIES}
                  action="EDIT"
                  yes={() => (
                    <EditIconButton
                      onClick={() => {
                        setInsuranceCompanyToEdit(insuranceCompany)
                        setShowEditForm((prev) => !prev)
                      }}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_COMPANIES}
                  action="VIEW"
                  yes={() => (
                    <DetailsIconButton
                      onClick={() =>
                        handleInsuranceCompanyDetails(insuranceCompany)
                      }
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_COMPANIES}
                  action="DELETE"
                  yes={() => (
                    <DeleteIconButton
                      onClick={() => handleDelete(insuranceCompany, i)}
                    />
                  )}
                />
              </Group>
            </Grid.Col>
            <Collapse
              px={8}
              py={16}
              sx={{ width: '100%' }}
              in={insuranceCompanyDetails === insuranceCompany}
            >
              <InsuranceCompanyDetails
                isOpen={insuranceCompanyDetails === insuranceCompany}
                companyId={insuranceCompany.id}
              />
            </Collapse>
          </Grid>
        ))}
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <RowsCount
          value={filters.limit}
          onChange={(value) => setFilters({ ...filters, limit: value })}
        />
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <PaginationBase
          total={data?.meta?.lastPage}
          value={data?.meta?.currentPage}
          onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
        />
      </Grid.Col>
      <InsuranceCompanyForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        insuranceCompanyToEdit={insuranceCompanyToEdit}
      />
    </Grid>
  )
}

InsuranceCompanyList.propTypes = {
  searchText: PropTypes.string
}

InsuranceCompanyList.defaultProps = {
  searchText: ''
}

export default InsuranceCompanyList
