export const TABLE_HEADERS = [
  { label: 'Descripción', key: 'description' },
  { label: 'Agente', key: 'agent.user.name' },
  { label: 'Cantidad', key: 'quantity' },
  { label: 'Vencimiento', key: 'end_date' },
  { label: 'Activo', key: 'is_active' }
]
export const TABLE_HEADERS_DETAILS = [
  { label: 'Código', key: 'code' },
  { label: 'Rut asociado', key: 'associated_rut' }
]
export const PAGE_TITLE = ''
