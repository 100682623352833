import { useFormik } from 'formik'
// @mantine-ui
import { Button, Grid, TextInput, MultiSelect, Box } from '@mantine/core'
import PropTypes from 'prop-types'
// @validations
import insuranceTypeSchema from '../validations'
// @hooks
import useClientTypeMutation from '../hooks/useInsuranceTypeMutation'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import BasicLoader from '../../../shared/components/BasicLoader'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'

function InsuranceTypeForm({ show, handleShow, insuranceTypeToEdit }) {
  const { addInsuranceType, updateInsuranceType } = useClientTypeMutation()

  const { data, isLoading } = fetchAll({
    key: 'client-types',
    filters: {
      limit: 'all'
    },
    customConfig: {
      enabled: show,
      refetchOnMount: false
    }
  })

  const formik = useFormik({
    initialValues: {
      clientTypes: insuranceTypeToEdit
        ? insuranceTypeToEdit.clientTypes.map((item) => item.clientTypeId)
        : [],
      code: insuranceTypeToEdit ? insuranceTypeToEdit.code : '',
      name: insuranceTypeToEdit ? insuranceTypeToEdit.name : '',
      is_active: insuranceTypeToEdit ? insuranceTypeToEdit.is_active : true
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: insuranceTypeSchema,
    onSubmit: (formData, { resetForm }) => {
      if (insuranceTypeToEdit) {
        return updateInsuranceType
          .mutateAsync([
            insuranceTypeToEdit.id,
            { ...insuranceTypeToEdit, ...formData }
          ])
          .then(() => handleShow())
          .then(() => resetForm())
      }
      return addInsuranceType.mutateAsync(formData).then(() => {
        handleShow()
        resetForm()
      })
    }
  })

  const handleClose = () => {
    formik.resetForm()
    handleShow()
  }

  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Ingresa la información del tipo de seguro aquí"
    >
      {isLoading ? (
        <BasicLoader />
      ) : (
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid.Col xs={8}>
              <MultiSelect
                placeholder="Tipo de cliente*"
                name="clientTypes"
                data={
                  (data?.data &&
                    data?.data?.map((item) => ({
                      ...item,
                      value: item.id,
                      label: item.name
                    }))) ||
                  []
                }
                nothingFound="Sin resultados"
                clearable
                value={formik.values.clientTypes}
                onChange={(option) => {
                  formik.setFieldValue('clientTypes', option)
                }}
                error={formik.errors.clientTypes}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <SwitchBase
                label="Activo"
                name="is_active"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                value={formik.values.is_active}
                error={formik.errors.is_active}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                placeholder="Código*"
                name="code"
                onChange={formik.handleChange}
                value={formik.values.code}
                error={formik.errors.code}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                placeholder="Tipo de seguro*"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.errors.name}
              />
            </Grid.Col>
            <Grid.Col xs={12} mt={16}>
              <Button
                fullWidth
                loading={
                  addInsuranceType.isLoading || updateInsuranceType.isLoading
                }
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

InsuranceTypeForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  insuranceTypeToEdit: PropTypes.object
}

InsuranceTypeForm.defaultProps = {
  show: false,
  handleShow: () => {},
  insuranceTypeToEdit: null
}

export default InsuranceTypeForm
