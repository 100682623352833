export const PAGE_TITLE = 'Configurador de Agentes'
export const PAGE_TITLE_STATS = 'Agente '

export const TABLE_HEADERS = [
  { label: 'Rut', key: 'user.rut', isSortable: true },
  { label: 'Nombre', key: ['user.name', 'user.last_name'], isSortable: true },
  { label: 'Teléfono', key: 'phone', isSortable: true },
  { label: 'Mail', key: 'user.email', isSortable: true },
  { label: 'Activo', key: 'user.is_active', isSortable: true }
]
export const TABLE_STATS_HEADERS = [
  { label: 'Rut', key: 'rut', isSortable: true },
  { label: 'Nombre', key: 'name', isSortable: true },
  { label: 'Forma', key: 'form', isSortable: true },
  { label: 'Tipo de seguro', key: 'uniqueTuple', isSortable: true },
  { label: 'Estatus', key: 'state', isSortable: true }
]
export const QUERY_BASE_KEY = 'agents'
