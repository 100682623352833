// @libs
import PropTypes from 'prop-types'
import { useState } from 'react'
import _ from 'lodash'
// @mantine-ui
import { Accordion, ActionIcon, Grid, Text, TextInput } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useClipboard } from '@mantine/hooks'
import { FiPercent } from 'react-icons/fi'
import { BsLink45Deg } from 'react-icons/bs'
import * as jose from 'jose'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'

function AgentLinkConditions({ agent }) {
  const clipboard = useClipboard({ timeout: 1000 })

  const [currentConditions, setCurrentConditions] = useState([])

  const conditions = fetchAll({
    key: `agents/${agent?.id}/conditions`,
    customConfig: {
      enabled: !!agent?.id,
      onSuccess: (data) => {
        if (data?.length) {
          const clients = Object.entries(
            _.groupBy(data, 'client_type_has_insurance_type.clientType.name')
          ).map(([key, values]) => ({
            id: values[0]?.id,
            name: key,
            insurances: values.map((value) => ({
              tupleId: value?.client_type_has_insurance_type_id,
              name: value?.client_type_has_insurance_type?.insuranceType?.name,
              hiring_percentage: value?.hiring_percentage,
              renovation_percentage: value?.renovation_percentage
            }))
          }))
          setCurrentConditions(clients)
        }
      }
    }
  })

  const handleCopyLink = async (insurance) => {
    try {
      const secret = new TextEncoder().encode('ajXNWaJ7vuUQLn2kCkra')
      const alg = 'HS256'

      const jwt = await new jose.SignJWT({ agent_code: agent?.code })
        .setProtectedHeader({ alg })
        .sign(secret)

      const { origin } = window.location
      const link = `${origin}/cotiza/${insurance?.tupleId}?token=${jwt}`

      clipboard.copy(link)

      showNotification({
        title: 'Realizado',
        color: 'green',
        message: `${link} copiado al portapapeles`
      })
    } catch (err) {
      showNotification({
        title: 'Error',
        color: 'red',
        message: 'Lo sentimos, no fue posible copiar el valor solicitado'
      })
    }
  }

  return (
    <Grid>
      <Grid.Col xs={12}>
        <Text fw={700} fz={22} sx={{ opacity: 0.8 }}>
          Links y condiciones
        </Text>
      </Grid.Col>
      {conditions.isFetching && (
        <Grid.Col xs={12}>
          <BasicLoader />
        </Grid.Col>
      )}
      {conditions?.data?.length > 0 && (
        <Grid.Col xs={12}>
          <Accordion multiple>
            {currentConditions.map((option) => (
              <Accordion.Item key={option.name} value={option.name}>
                <Accordion.Control py={8} px={2}>
                  <Text
                    truncate
                    sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: 500
                    }}
                  >
                    {option.name}
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  {option.insurances.map((insurance) => (
                    <Grid
                      key={insurance.name}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid.Col
                        xs={6}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Text mr={8}>{insurance.name}</Text>
                        <ActionIcon
                          sx={{
                            '&:hover': {
                              transform: 'scale(1.2)'
                            }
                          }}
                          onClick={() => handleCopyLink(insurance)}
                        >
                          <BsLink45Deg size={18} color="orange" />
                        </ActionIcon>
                      </Grid.Col>
                      <Grid.Col
                        xs={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <TextInput
                          mr={16}
                          icon={<FiPercent />}
                          label="Contratación"
                          readOnly
                          defaultValue={insurance.hiring_percentage}
                          styles={{
                            input: {
                              maxHeight: '24px',
                              minHeight: '24px',
                              borderRadius: 16,
                              fontSize: 14
                            },
                            label: {
                              fontSize: 12
                            }
                          }}
                        />
                        <TextInput
                          icon={<FiPercent />}
                          label="Renovación"
                          readOnly
                          defaultValue={insurance.renovation_percentage}
                          styles={{
                            input: {
                              maxHeight: '24px',
                              minHeight: '24px',
                              borderRadius: 16,
                              fontSize: 14
                            },
                            label: {
                              fontSize: 12
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </Grid.Col>
      )}
      {!conditions.isFetching && !conditions?.data?.length && (
        <Grid.Col xs={12}>
          <NoDataAlert />
        </Grid.Col>
      )}
    </Grid>
  )
}

AgentLinkConditions.propTypes = {
  agent: PropTypes.object
}

AgentLinkConditions.defaultProps = {
  agent: {}
}

export default AgentLinkConditions
