// @libs
import PropTypes from 'prop-types'
import { FiPercent } from 'react-icons/fi'
// @mantine-ui
import {
  Accordion,
  Checkbox,
  Grid,
  NumberInput,
  Box,
  Text
} from '@mantine/core'

function AccordionControl(props) {
  const { uniqueTuple, formik, handleCheckboxChange, ...restProps } = props
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={formik.values.insurance_type_has_agents.some(
          (item) => item.client_type_has_insurance_type_id === uniqueTuple.id
        )}
        onChange={handleCheckboxChange}
      />
      <Accordion.Control {...restProps} />
    </Box>
  )
}

AccordionControl.propTypes = {
  uniqueTuple: PropTypes.object,
  formik: PropTypes.object,
  handleCheckboxChange: PropTypes.func
}

AccordionControl.defaultProps = {
  uniqueTuple: {},
  formik: {},
  handleCheckboxChange: () => null
}

function AgentInsurances({ uniqueTuple, formik }) {
  const handleCheckboxChange = (event) => {
    if (event.currentTarget.checked) {
      const newObj = {
        hiring_percentage: 0,
        renovation_percentage: 0,
        client_type_has_insurance_type_id: uniqueTuple.id
      }

      formik.setFieldValue('insurance_type_has_agents', [
        ...formik.values.insurance_type_has_agents,
        newObj
      ])
    } else {
      const filtered = formik.values.insurance_type_has_agents.filter(
        (item) => item.client_type_has_insurance_type_id !== uniqueTuple.id
      )
      formik.setFieldValue('insurance_type_has_agents', filtered)
    }
  }

  const handleComissionChange = (value, name) => {
    const updatedValues = formik.values.insurance_type_has_agents.map(
      (item) => {
        if (item.client_type_has_insurance_type_id === uniqueTuple.id) {
          return { ...item, [name]: value }
        }
        return item
      }
    )
    formik.setFieldValue('insurance_type_has_agents', updatedValues)
  }

  const fieldValue = formik.values.insurance_type_has_agents.find(
    (item) => item.client_type_has_insurance_type_id === uniqueTuple.id
  )

  return (
    <Accordion defaultValue={fieldValue?.client_type_has_insurance_type_id}>
      <Accordion.Item value={`${uniqueTuple.id}`}>
        <AccordionControl
          uniqueTuple={uniqueTuple}
          formik={formik}
          handleCheckboxChange={handleCheckboxChange}
        >
          {uniqueTuple.clientType.name} ({uniqueTuple.insuranceType.name})
        </AccordionControl>
        <Accordion.Panel>
          <Grid>
            <Grid.Col span={6}>
              <Text align="center">Contratación</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text align="center">Renovación</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <NumberInput
                icon={<FiPercent />}
                precision={2}
                value={
                  fieldValue?.hiring_percentage
                    ? +fieldValue.hiring_percentage
                    : 0
                }
                disabled={!fieldValue}
                size="xs"
                onChange={(newValue) =>
                  handleComissionChange(newValue, 'hiring_percentage')
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <NumberInput
                icon={<FiPercent />}
                value={
                  fieldValue?.renovation_percentage
                    ? +fieldValue.renovation_percentage
                    : 0
                }
                precision={2}
                disabled={!fieldValue}
                size="xs"
                onChange={(newValue) =>
                  handleComissionChange(newValue, 'renovation_percentage')
                }
              />
            </Grid.Col>
          </Grid>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}

AgentInsurances.propTypes = {
  uniqueTuple: PropTypes.object,
  formik: PropTypes.object
}

AgentInsurances.defaultProps = {
  uniqueTuple: {},
  formik: {}
}

export default AgentInsurances
