// @libs
import * as yup from 'yup'
// @constants
import { REQUIRED_MESSAGE } from '../../utils/contants'

const codeSchema = yup.object().shape({
  description: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  is_active: yup.boolean().default(true),
  is_nominative: yup.boolean().default(false),
  quantity: yup.number().when('is_nominative', {
    is: (value) => !value,
    then: yup
      .number()
      .positive()
      .required(REQUIRED_MESSAGE)
      .min(1, 'La cantidad debe ser al menos 1'),
    otherwise: yup.number().notRequired()
  }),
  with_validity: yup.boolean().default(false),
  start_date: yup.string().when('with_validity', {
    is: (value) => value,
    then: yup.string().required(REQUIRED_MESSAGE).nullable(),
    otherwise: yup.string().notRequired()
  }),
  end_date: yup.date().when('with_validity', {
    is: (value) => value,
    then: yup.date().required(REQUIRED_MESSAGE).nullable(),
    otherwise: yup.date().notRequired()
  })
})

export default codeSchema
