export const PAGE_TITLE = 'Tipos de Clientes'
export const TABLE_HEADERS = [
  { label: 'Nombre', key: 'name' },
  { label: 'Activo', key: 'is_active' }
]
export const QUERY_BASE_KEY = 'client-types'
export const DUPLICATED_OPTIONS = {
  code: 'código',
  name: 'nombre'
}
