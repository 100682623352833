// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Box, Checkbox, Grid, Text } from '@mantine/core'

function InsuranceCompanyDetailsItem({ insurances }) {
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Text
          sx={{
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          {insurances[0]?.client_type_has_insurance_type?.clientType?.name}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Box sx={{ display: 'flex' }}>
          {insurances.map(
            (insurance) =>
              insurance && (
                <Checkbox
                  key={insurance.id}
                  defaultChecked
                  mr={8}
                  label={
                    <Text
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        letterSpacing: '0.04em',
                        opacity: 0.8
                      }}
                    >
                      {
                        insurance?.client_type_has_insurance_type?.insuranceType
                          ?.name
                      }
                    </Text>
                  }
                />
              )
          )}
        </Box>
      </Grid.Col>
    </Grid>
  )
}

InsuranceCompanyDetailsItem.propTypes = {
  insurances: PropTypes.array
}
InsuranceCompanyDetailsItem.defaultProps = {
  insurances: []
}

export default InsuranceCompanyDetailsItem
