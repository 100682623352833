// @libs
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'dayjs/locale/es-mx'
// @mantine-ui
import { DatePicker } from '@mantine/dates'

function DatePickerBase({
  maxDate,
  initialLevel,
  placeholder,
  inputFormat,
  ...props
}) {
  return (
    <DatePicker
      locale="es-mx"
      maxDate={maxDate}
      initialLevel={initialLevel}
      placeholder={placeholder}
      inputFormat={inputFormat}
      {...props}
    />
  )
}

DatePickerBase.propTypes = {
  maxDate: PropTypes.string,
  initialLevel: PropTypes.oneOf(['date', 'month', 'year']),
  placeholder: PropTypes.string,
  inputFormat: PropTypes.string
}

DatePickerBase.defaultProps = {
  maxDate: dayjs(new Date()).toDate(),
  initialLevel: 'year',
  placeholder: 'Seleccione la fecha',
  inputFormat: 'DD/MM/YYYY'
}

export default DatePickerBase
