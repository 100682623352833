// @libs
import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// @services
import ApiService from '../../../services/api.service'

const queryKey = 'insurance-types'

const useInsuranceTypeMutation = () => {
  const queryClient = useQueryClient()

  const resetQueries = () => queryClient.invalidateQueries(queryKey)

  const addInsuranceType = useMutation(
    (body) => ApiService.create([queryKey, body]),
    {
      onSuccess: () => {
        resetQueries()
        showNotification({
          title: 'Realizado',
          message: '¡Registro creado!',
          color: 'green'
        })
      },
      onError: (err) => {
        if (err.statusCode === 409) {
          return showNotification({
            title: 'Error',
            message: 'Ya existe un registro con los datos ingresados.',
            color: 'red'
          })
        }
        return showNotification({
          title: 'Error',
          message: 'No fue posible crear el registro.',
          color: 'red'
        })
      }
    }
  )
  const updateInsuranceType = useMutation(
    ([id, body]) => ApiService.update([queryKey, id, body]),
    {
      onSuccess: () => {
        resetQueries()
        showNotification({
          title: 'Realizado',
          message: '¡Registro actualizado!',
          color: 'green'
        })
      },
      onError: (err) => {
        if (err.statusCode === 409) {
          return showNotification({
            title: 'Error',
            message: 'Ya existe un registro con los datos ingresados.',
            color: 'red'
          })
        }
        return showNotification({
          title: 'Error',
          message: 'No fue posible actualizar el registro.',
          color: 'red'
        })
      }
    }
  )
  const removeInsuranceType = useMutation(
    (id) => ApiService.remove([queryKey, id]),
    {
      onSuccess: () => {
        showNotification({
          title: 'Realizado',
          message: '¡Registro eliminado!',
          color: 'green'
        })
        resetQueries()
      },
      onError: (err) => {
        if (err.statusCode === 409) {
          return showNotification({
            title: 'Error',
            message:
              'No es posible eliminar este registro, hay datos asociados.',
            color: 'red'
          })
        }
        return showNotification({
          title: 'Error',
          message: 'No fue posible eliminar el registro.',
          color: 'red'
        })
      }
    }
  )

  return {
    addInsuranceType,
    updateInsuranceType,
    removeInsuranceType
  }
}

export default useInsuranceTypeMutation
