const Text = {
  styles: () => ({
    root: {
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '24px',
      letterSpacing: '-0.015em',
      color: '#1E1C3E'
    }
  })
}
export default Text
