const attributesTypeOptions = [
  'Texto',
  'Lista',
  'Lista predeterminada',
  'Adjunto',
  'Formula',
  'Tabla',
  'Rango numerico',
  'Vigencia',
  'Moneda',
  'Numerico',
  'Rut',
  'Verdadero / falso'
]

export default attributesTypeOptions
