// @libs
import { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { MdClose } from 'react-icons/md'
// @mantine-ui
import {
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Text,
  Box,
  ActionIcon,
  Switch
} from '@mantine/core'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import fetchOne from '../../../shared/hooks/useFetchOne'
import fetchAll from '../../../shared/hooks/useFetchAll'
import useContactsMutation from '../hooks/useContactsMutation'
import BasicLoader from '../../../shared/components/BasicLoader'
import contactSquema from '../validations'

function DistributionList() {
  const [isLoading, setIsLoading] = useState(false)
  const { uniqueTupleId } = useParams()
  const [show, setShow] = useState(false)
  const [contacts, setContacts] = useState([])
  const { addContacts, removeContacts, updateContacts } = useContactsMutation()

  const { data: contactsRaw, isFetching } = fetchAll({
    key: 'contacts',
    filters: {
      uniqueTupleId
    },
    customConfig: {
      refetchOnMount: true,
      onSuccess: (response) => {
        setContacts(response)
      }
    }
  })

  const { status, data } = fetchOne([
    'client-types-has-insurance-types',
    uniqueTupleId
  ])

  const formik = useFormik({
    initialValues: {
      insuranceTypeHasInsuranceCompany_id: '',
      name: '',
      email: ''
    },
    validationSchema: contactSquema,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      try {
        const exist = contacts.some((item) => item.email === values.email)
        if (exist) {
          setFieldError('email', 'Este correo ya se encuentra registrado.')
        } else {
          setIsLoading(true)
          await addContacts.mutateAsync(values)
          resetForm()
          setIsLoading(false)
        }
      } catch (err) {
        setIsLoading(false)
      }
    }
  })

  const handleDeleteContact = async (contactId) => {
    await removeContacts.mutateAsync(contactId)
  }

  const handleUpdateContact = async (contactData, event) => {
    await updateContacts.mutateAsync([
      contactData.id,
      { ...contactData, is_active: event.currentTarget.checked }
    ])
  }

  const handleClose = () => {
    setContacts(contactsRaw)
    formik.resetForm()
    setShow(false)
  }

  return (
    <>
      <Button onClick={() => setShow((prev) => !prev)}>
        Lista de distribución
      </Button>

      <ModalBase
        title="Lista de distribución"
        show={show}
        handleShow={handleClose}
        size="xl"
      >
        <Grid>
          <LoadingOverlay visible={status === 'loading'} overlayBlur={2} />
          <Grid.Col xs={3}>
            <Select
              value={formik.values.insuranceTypeHasInsuranceCompany_id}
              onChange={(company) => {
                formik.setFieldValue(
                  'insuranceTypeHasInsuranceCompany_id',
                  company
                )
                const filteredContacts = contactsRaw.filter(
                  (item) =>
                    item.insuranceTypeHasInsuranceCompany_id === +company
                )
                setContacts(filteredContacts)
              }}
              error={
                formik.touched.insuranceTypeHasInsuranceCompany_id &&
                formik.errors.insuranceTypeHasInsuranceCompany_id
              }
              placeholder="Compañia"
              nothingFound="Sin resultados"
              data={
                data?.insurance_types_has_insurance_companies
                  ? data?.insurance_types_has_insurance_companies?.map(
                      (tc) => ({
                        ...tc,
                        label:
                          tc.insurance_company.name ||
                          tc.insurance_company.business_name,
                        value: tc.id
                      })
                    )
                  : []
              }
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <TextInput
              value={formik.values.name}
              onChange={(event) => {
                formik.setFieldValue('name', event.currentTarget.value)
                const filteredContacts = contactsRaw.filter((item) =>
                  item.name
                    .toLowerCase()
                    .includes(event.currentTarget.value.toLowerCase())
                )
                setContacts(filteredContacts)
              }}
              placeholder="Nombre"
              error={formik.touched.name && formik.errors.name}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <TextInput
              value={formik.values.email}
              onChange={(event) => {
                formik.setFieldValue('email', event.currentTarget.value)
                const filteredContacts = contactsRaw.filter((item) =>
                  item.email
                    .toLowerCase()
                    .includes(event.currentTarget.value.toLowerCase())
                )
                setContacts(filteredContacts)
              }}
              placeholder="Email"
              error={formik.touched.email && formik.errors.email}
            />
          </Grid.Col>
          <Grid.Col xs={2}>
            <Group position="center">
              <Button onClick={formik.handleSubmit} loading={isLoading}>
                Asignar
              </Button>
            </Group>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Box
              sx={{
                minHeight: 300,
                backgroundColor: '#D9D9D9',
                borderRadius: 12,
                padding: 12
              }}
            >
              {isFetching ? (
                <BasicLoader />
              ) : (
                <Grid>
                  <Grid.Col xs={3}>Compañia</Grid.Col>
                  <Grid.Col xs={3}>Nombre</Grid.Col>
                  <Grid.Col xs={4}>Email</Grid.Col>
                  <Grid.Col xs={1}>Activo</Grid.Col>
                  <Grid.Col xs={1} />
                  {contacts.length ? (
                    contacts?.map((contact) => (
                      <Fragment key={contact.id}>
                        <Grid.Col
                          xs={3}
                          sx={{
                            borderRadius: '8px 0px 0px 8px',
                            backgroundColor: 'white',
                            marginBottom: 16,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          px={8}
                        >
                          <Text
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: '21px',
                              letterSpacing: '0.04em',
                              opacity: 0.8
                            }}
                          >
                            {contact.insuranceTypeHasInsuranceCompany
                              .insurance_company.name ||
                              contact.insuranceTypeHasInsuranceCompany
                                .insurance_company.business_name}
                          </Text>
                        </Grid.Col>
                        <Grid.Col
                          xs={3}
                          sx={{
                            backgroundColor: 'white',
                            marginBottom: 16,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          px={8}
                        >
                          <Text
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: '21px',
                              letterSpacing: '0.04em',
                              opacity: 0.8
                            }}
                          >
                            {contact.name}
                          </Text>
                        </Grid.Col>
                        <Grid.Col
                          xs={4}
                          sx={{
                            backgroundColor: 'white',
                            marginBottom: 16,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          px={8}
                        >
                          <Text
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: '21px',
                              letterSpacing: '0.04em',
                              opacity: 0.8
                            }}
                          >
                            {contact.email}
                          </Text>
                        </Grid.Col>
                        <Grid.Col
                          xs={1}
                          sx={{
                            backgroundColor: 'white',
                            marginBottom: 16,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Switch
                            size="sm"
                            color="green"
                            checked={contact.is_active}
                            onChange={(e) => handleUpdateContact(contact, e)}
                          />
                        </Grid.Col>
                        <Grid.Col
                          xs={1}
                          sx={{
                            borderRadius: '0px 8px 8px 0px',
                            backgroundColor: 'white',
                            marginBottom: 16,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          px={8}
                        >
                          <Group position="right">
                            <ActionIcon
                              radius="xl"
                              onClick={() => handleDeleteContact(contact.id)}
                            >
                              <MdClose size={18} color="orange" />
                            </ActionIcon>
                          </Group>
                        </Grid.Col>
                      </Fragment>
                    ))
                  ) : (
                    <Grid.Col
                      xs={12}
                      sx={{
                        borderRadius: 8,
                        backgroundColor: 'white',
                        marginBottom: 16,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      px={8}
                    >
                      <Text
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          lineHeight: '21px',
                          letterSpacing: '0.04em',
                          opacity: 0.8
                        }}
                      >
                        Sin contactos
                      </Text>
                    </Grid.Col>
                  )}
                </Grid>
              )}
            </Box>
          </Grid.Col>
        </Grid>
      </ModalBase>
    </>
  )
}

export default DistributionList
