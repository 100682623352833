// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import ClientTypes from './pages/client-types'

const clientTypesRoutes = [
  {
    path: '/client-types',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
        action="Tipos de Cliente:VIEW"
        yes={() => <ClientTypes />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default clientTypesRoutes
