// @libs
import { useDispatch } from 'react-redux'
// @mantine-ui
import { Box, Button, Grid } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import AttributesList from '../components/AttributesList'
import AttributesForm from '../components/AttributesForm'
import Can from '../../../shared/components/Can'
import SearchInput from '../../../shared/components/Inputs/SearchInput'
import { setModalShow } from '../../../store/attributesSlice'

function Attributes() {
  const dispatch = useDispatch()
  const [value, setValue] = useDebouncedState('', 500)

  return (
    <Page title="Tipos de Atributos">
      <Grid>
        <Grid.Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <PageTitle title="Tipos de Atributos" />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <SearchInput
                defaultValue={value}
                onChange={(event) => setValue(event.currentTarget.value)}
              />
              <Can
                moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
                action="Atributos:CREATE"
                yes={() => (
                  <Button
                    onClick={() => dispatch(setModalShow(true))}
                    sx={{ marginLeft: 16 }}
                  >
                    Agregar nuevo atributo
                  </Button>
                )}
              />
              <AttributesForm />
            </Box>
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} mt={16}>
          <AttributesList searchText={value} />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default Attributes
