// @libs
import PropTypes from 'prop-types'
import { FiUpload } from 'react-icons/fi'
// @mantine-ui
import { Button, FileButton } from '@mantine/core'

function FileButtonBase({ label, accept, sx, ...props }) {
  return (
    <FileButton accept={accept} {...props}>
      {(buttonProps) => (
        <Button
          leftIcon={<FiUpload />}
          sx={{
            color: 'orange',
            display: 'flex',
            justifyContent: 'flex-start',
            ...sx
          }}
          fullWidth
          variant="subtle"
          {...buttonProps}
        >
          {label}
        </Button>
      )}
    </FileButton>
  )
}

FileButtonBase.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string,
  sx: PropTypes.object
}

FileButtonBase.defaultProps = {
  label: 'Seleccionar archivo',
  accept: 'image/*',
  sx: {}
}

export default FileButtonBase
