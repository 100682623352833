// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Grid, Select, Text } from '@mantine/core'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import BasicLoader from '../../../shared/components/BasicLoader'
import { dateFormat } from '../../../utils/formats'

function AgentCampaigns({ agent, campaign, changeCampaign }) {
  const campaigns = fetchAll({
    key: 'code-campaigns',
    filters: {
      agent_id: agent?.id,
      limit: 'all'
    },
    customConfig: {
      enabled: !!agent?.id
    }
  })
  if (campaigns.isFetching) return <BasicLoader />
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Select
          w="50%"
          styles={{
            input: {
              maxHeight: '32px',
              minHeight: '32px',
              borderRadius: 4,
              fontSize: 14
            }
          }}
          clearable
          placeholder="Códigos asociados"
          nothingFound="Sin resultados"
          data={
            campaigns?.data?.data?.map(
              (agentCampaign) =>
                agentCampaign && {
                  ...agentCampaign,
                  value: agentCampaign.id,
                  label: agentCampaign.description
                }
            ) || []
          }
          onChange={(option) => {
            changeCampaign(campaigns?.data?.data?.find((c) => c.id === option))
          }}
        />
      </Grid.Col>
      {campaign?.id && (
        <Grid.Col xs={12}>
          <Grid>
            <Grid.Col xs={12}>
              <Text fw={600}>Descripción: {campaign?.description}</Text>
            </Grid.Col>
            <Grid.Col
              xs={12}
              sx={{
                display: 'flex'
              }}
            >
              <Text mr={8}>Total: {campaign?.codes?.length}</Text>
              <Text mr={8}>Utilizados: 1</Text>
              <Text mr={8}>
                Nominativos: {campaign?.is_nominative ? 'Sí' : 'No'}
              </Text>
              <Text mr={8}>
                Fecha de inicio:{' '}
                {campaign?.start_date && dateFormat(campaign.start_date)}
              </Text>
              <Text>
                Fecha de termino:{' '}
                {campaign?.end_date && dateFormat(campaign.end_date)}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      )}
    </Grid>
  )
}

AgentCampaigns.propTypes = {
  campaign: PropTypes.object,
  changeCampaign: PropTypes.func,
  agent: PropTypes.object
}

AgentCampaigns.defaultProps = {
  campaign: {},
  changeCampaign: () => {},
  agent: {}
}

export default AgentCampaigns
