// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Text } from '@mantine/core'

function PageTitle({ title, ...props }) {
  return (
    <Text
      sx={(theme) => ({
        fontWeight: 700,
        fontSize: 30,
        lineHeight: '39px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em',
        opacity: 0.8,
        [theme.fn.smallerThan('sm')]: {
          fontSize: 24,
          lineHeight: '26px'
        }
      })}
      {...props}
    >
      {title}
    </Text>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string
}

PageTitle.defaultProps = {
  title: 'Título'
}

export default PageTitle
