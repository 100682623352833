// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Accordion, Text } from '@mantine/core'
// @components
import ModuleItemActions from './ModuleItemActions'

function ModuleItem({ module, handleChangePermission, currentPermissions }) {
  return (
    <Accordion.Item value={module.name}>
      <Accordion.Control py={8} px={2}>
        <Text
          sx={{
            fontFamily: 'Montserrat'
          }}
        >
          {module.name}
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        {module.subModules.map((subModule) => (
          <ModuleItemActions
            title={module.subModules.length > 1 && subModule.name}
            permissions={subModule.permissions}
            handleChangePermission={handleChangePermission}
            currentPermissions={currentPermissions}
          />
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  )
}

ModuleItem.propTypes = {
  module: PropTypes.object,
  handleChangePermission: PropTypes.func,
  currentPermissions: PropTypes.array
}

ModuleItem.defaultProps = {
  module: {},
  handleChangePermission: () => {},
  currentPermissions: []
}

export default ModuleItem
