// @libs
import * as yup from 'yup'
// @constants
import { REQUIRED_MESSAGE } from '../../utils/contants'

const riskClassificationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  is_active: yup.boolean().default(true)
})

export default riskClassificationSchema
