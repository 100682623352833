// @libs
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
// @mantine-ui
import {
  Button,
  Grid,
  TextInput,
  Box,
  Select,
  // MultiSelect,
  Text,
  MultiSelect
} from '@mantine/core'
// @validations
import insuranceCompanySchema from '../validations'
// @hooks
import useGetAll from '../../../shared/hooks/useFetchAll'
import useInsuranceCompanyMutation from '../hooks/useInsuranceCompanyMutation'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'
import useFetchOne from '../../../shared/hooks/useFetchOne'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'

const noOp = () => {}

function InsuranceCompanyForm({ show, handleShow, insuranceCompanyToEdit }) {
  const { addInsuranceCompany, updateInsuranceCompany } =
    useInsuranceCompanyMutation()

  const {
    data: insuranceCompany,
    isLoading,
    isError
  } = useFetchOne(['insurance-companies', insuranceCompanyToEdit?.id], {
    enabled: show && !!insuranceCompanyToEdit?.id
  })
  const riskClassifications = useGetAll({
    key: 'risk-classifications',
    filters: {
      is_active: true,
      limit: 'all'
    },
    customConfig: {
      enabled: show,
      refetchOnMount: false
    }
  })
  const clientTypes = useGetAll({
    key: 'client-types',
    filters: {
      is_active: true,
      insurance_type: true,
      limit: 'all'
    },
    customConfig: {
      enabled: show,
      refetchOnMount: false
    }
  })

  const formik = useFormik({
    initialValues: {
      code: insuranceCompany?.code || '',
      rut: insuranceCompany?.rut || '',
      is_token_required: insuranceCompany?.is_token_required || false,
      endpoint: insuranceCompany?.endpoint || '',
      access_token: insuranceCompany?.access_token || '',
      header_prop_name: insuranceCompany?.header_prop_name || '',
      business_name: insuranceCompany?.business_name || '',
      name: insuranceCompany?.name || '',
      is_active: insuranceCompany?.is_active || true,
      risk_classification_id: insuranceCompany?.risk_classification_id || '',
      insurance_types_has_insurance_companies:
        insuranceCompany?.insurance_types_has_insurance_companies?.map(
          (item) => item.client_type_has_insurance_type_id
        ) || []
    },
    enableReinitialize: true,
    validationSchema: insuranceCompanySchema,
    onSubmit: (insuranceCompanyData, { resetForm }) => {
      if (insuranceCompanyToEdit) {
        return updateInsuranceCompany
          .mutateAsync([
            insuranceCompanyToEdit.id,
            { ...insuranceCompanyToEdit, ...insuranceCompanyData }
          ])
          .then(() => {
            handleShow()
            resetForm()
          })
      }
      return addInsuranceCompany.mutateAsync(insuranceCompanyData).then(() => {
        handleShow()
        resetForm()
      })
    }
  })

  const handleClose = () => {
    formik.resetForm()
    handleShow()
  }

  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Ingresa la información de la nueva compañía aquí"
    >
      {(insuranceCompanyToEdit && isLoading) ||
      riskClassifications.isLoading ||
      clientTypes.isLoading ? (
        <BasicLoader />
      ) : (
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {isError || riskClassifications.isError || clientTypes.isError ? (
              <Grid.Col xs={12}>
                <ErrorAlert />
              </Grid.Col>
            ) : (
              <Grid.Col xs={12}>
                <Grid>
                  <Grid.Col span={12} align="center">
                    <Text
                      sx={{
                        fontWeight: 700,
                        textAlign: 'center'
                      }}
                    >
                      Información de la compañía
                    </Text>
                  </Grid.Col>
                  <Grid.Col
                    span={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <TextInput
                      placeholder="Código*"
                      name="code"
                      onChange={formik.handleChange}
                      value={formik.values.code}
                      error={formik.errors.code}
                    />
                    <SwitchBase
                      name="is_active"
                      checked={formik.values.is_active}
                      onChange={formik.handleChange}
                      value={formik.values.is_active}
                      error={formik.errors.is_active}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Rut*"
                      name="rut"
                      onChange={formik.handleChange}
                      value={formik.values.rut}
                      error={formik.errors.rut}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Nombre*"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      error={formik.errors.name}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Razón Social*"
                      name="business_name"
                      onChange={formik.handleChange}
                      value={formik.values.business_name}
                      error={formik.errors.business_name}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Select
                      name="risk_classification_id"
                      value={formik.values.risk_classification_id}
                      clearable
                      placeholder="Clasificación de riesgo*"
                      nothingFound="Sin resultados"
                      data={
                        riskClassifications.data.data
                          ? riskClassifications.data.data.map(
                              (risk) =>
                                risk && {
                                  ...risk,
                                  value: risk.id,
                                  label: risk.name
                                }
                            )
                          : []
                      }
                      onChange={(option) => {
                        formik.setFieldValue('risk_classification_id', option)
                      }}
                      error={formik.errors.risk_classification_id}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Endpoint"
                      name="endpoint"
                      onChange={formik.handleChange}
                      value={formik.values.endpoint}
                      error={formik.errors.endpoint}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Nombre de header"
                      name="header_prop_name"
                      onChange={formik.handleChange}
                      value={formik.values.header_prop_name}
                      error={formik.errors.header_prop_name}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Token de acceso"
                      name="access_token"
                      onChange={formik.handleChange}
                      value={formik.values.access_token}
                      error={formik.errors.access_token}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} align="center">
                    <Text
                      sx={{
                        fontWeight: 700,
                        textAlign: 'center'
                      }}
                    >
                      Tipos de seguros según tipo de cliente
                    </Text>
                  </Grid.Col>
                  {clientTypes?.data?.data?.length > 0 && (
                    <Grid.Col span={12}>
                      <MultiSelect
                        name="insurance_types_has_insurance_companies"
                        value={
                          formik.values.insurance_types_has_insurance_companies
                        }
                        clearable
                        searchable
                        dropdownPosition="flip"
                        nothingFound="Sin resultados"
                        onChange={(option) => {
                          formik.setFieldValue(
                            'insurance_types_has_insurance_companies',
                            option
                          )
                        }}
                        data={
                          clientTypes?.data?.data
                            ? clientTypes?.data?.data
                                ?.filter((t) => t.insuranceTypes.length)
                                .map((c) => {
                                  const b = []
                                  c.insuranceTypes.forEach((it) => {
                                    const opt = {
                                      value: it.id,
                                      label: `${it.insuranceType.name} (${c.name})`,
                                      group: c.name
                                    }
                                    b.push(opt)
                                  })
                                  return b.flat()
                                })
                                .flat()
                            : []
                        }
                      />
                    </Grid.Col>
                  )}
                  <Grid.Col span={12} mt={16}>
                    <Button
                      fullWidth
                      loading={
                        addInsuranceCompany.isLoading ||
                        updateInsuranceCompany.isLoading
                      }
                      loaderPosition="right"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            )}
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

InsuranceCompanyForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  insuranceCompanyToEdit: PropTypes.object
}

InsuranceCompanyForm.defaultProps = {
  show: false,
  handleShow: noOp,
  insuranceCompanyToEdit: null
}

export default InsuranceCompanyForm
