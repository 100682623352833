// @libs
import { Outlet } from 'react-router-dom'
// @mantine-ui
import { Card, Container } from '@mantine/core'
// @components
import PublicLayout from '../../../shared/components/PublicLayout'

function AuthLayout() {
  return (
    <PublicLayout
      customStyles={{
        // backgroundImage: 'url(/static/public-background.svg)',
        backgroundSize: 'cover',
        backgroundColor: 'white'
      }}
    >
      <Container size="xs">
        <Card radius="lg" sx={{ backgroundColor: 'inherit' }}>
          <Outlet />
        </Card>
      </Container>
    </PublicLayout>
  )
}

export default AuthLayout
