import { useState } from 'react'
import {
  Center,
  Grid,
  Loader,
  Button,
  Text,
  FileButton,
  Modal,
  TextInput,
  Group,
  Select,
  Checkbox,
  Radio,
  Divider,
  Tabs,
  NumberInput
} from '@mantine/core'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import { FixedSizeList } from 'react-window'
import { FiUpload, FiDownload } from 'react-icons/fi'
import { useDisclosure } from '@mantine/hooks'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components

const readFileAsync = (fileData) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(fileData)
  })

function AttributesDefaultListView({ formik }) {
  const [isLoadingFile, setIsLoadingFile] = useState(false)
  const [selectedFather, setSelectedFather] = useState(null)
  const [searchString, setSearchString] = useState('')
  const [fileName, setFileName] = useState('')
  const [dataToDisplay, setDataToDisplay] = useState(
    formik.values.AttributesLinkedListOptions || []
  )
  const [opened, { open, close }] = useDisclosure(false)

  const { data } = fetchAll({
    key: 'attributes',
    filters: {
      limit: 'all',
      is_input: 'all',
      type: ['Lista predeterminada']
    },
    customConfig: {
      refetchOnMount: true,
      onSuccess: (response) => {
        const selectedAtrr = response?.data.find(
          (attr) => +formik.values.fatherAttributeId === attr.id
        )
        setSelectedFather({
          AttributesLinkedListOptions: selectedAtrr?.AttributesLinkedListOptions
        })
      }
    }
  })

  const { data: insuranceCompanies } = fetchAll({
    key: 'insurance-companies',
    filters: {
      limit: 'all'
    },
    customConfig: {
      refetchOnMount: true
    }
  })

  const handleChange = async (fileData) => {
    setIsLoadingFile((prev) => !prev)
    try {
      const bufferArray = await readFileAsync(fileData)
      // new book
      const wb = XLSX.read(bufferArray, { type: 'buffer' })
      const dataExcel = XLSX.utils.sheet_to_json(wb.Sheets.data)

      const parsedPayload = dataExcel.map((item, index) => {
        const { nombre, asociacion, ...companies } = item

        const payload = {
          id: index,
          name: nombre,
          AttributesListOptionsValues: insuranceCompanies?.data?.map(
            (company) => ({
              value:
                companies[
                  `${company.id}; ${company.name || company.business_name}`
                ] || '',
              insuranceCompany_id: company.id
            })
          )
        }

        if (
          formik.values.isMultilevelSelect &&
          formik.values.multilevelType === 'HIJO' &&
          selectedFather
        ) {
          const option = selectedFather?.AttributesLinkedListOptions?.find(
            (attr) => attr.name === asociacion
          )

          payload.fatherListOptionId = option?.id || null
        }

        return payload
      })

      formik.setFieldValue('AttributesLinkedListOptions', parsedPayload)
      formik.setFieldValue('defaultListFileLoaded', true)
      setFileName(fileData.name)
      setDataToDisplay(parsedPayload)
    } catch (e) {
      console.error('e', e)
    } finally {
      setIsLoadingFile((prev) => !prev)
    }
  }

  const handleTextChange = (value, optionId, companyId) => {
    const editedValue = formik.values.AttributesLinkedListOptions.map((item) =>
      item.id === optionId
        ? {
            ...item,
            updated: true,
            AttributesListOptionsValues: item.AttributesListOptionsValues.map(
              (option) =>
                option.insuranceCompany_id === companyId
                  ? {
                      ...option,
                      value,
                      updated: true
                    }
                  : option
            )
          }
        : item
    )
    formik.setFieldValue('AttributesLinkedListOptions', editedValue)
  }

  const handleGetValue = (optionId, companyId) => {
    let value = ''
    formik.values.AttributesLinkedListOptions.forEach((item) => {
      if (item.id === optionId) {
        item.AttributesListOptionsValues.forEach((option) => {
          if (option.insuranceCompany_id === companyId) value = option.value
        })
      }
    })
    return value
  }

  const handleFatherChange = (value) => {
    formik.setFieldValue('fatherAttributeId', value)
    const selectedAtrr = data?.data.find((attr) => +value === attr.id)
    setSelectedFather({
      AttributesLinkedListOptions: selectedAtrr?.AttributesLinkedListOptions
    })
  }

  const handleSearchString = (e) => {
    const {
      target: { value }
    } = e

    const filteredValues = formik.values.AttributesLinkedListOptions.filter(
      (item) =>
        item.name.trim().toLowerCase().includes(value.trim().toLowerCase())
    )
    setSearchString(value)
    setDataToDisplay(filteredValues)
  }

  const getChildFatherValue = (optionId) => {
    let value = ''
    formik.values.AttributesLinkedListOptions.forEach((item) => {
      if (item.id === optionId) {
        value = item.fatherListOptionId
      }
    })
    return value
  }

  const handleChildFatherChange = (value, optionId) => {
    const editedValue = formik.values.AttributesLinkedListOptions.map((item) =>
      item.id === optionId
        ? {
            ...item,
            fatherListOptionId: value,
            updated: true
          }
        : item
    )
    formik.setFieldValue('AttributesLinkedListOptions', editedValue)
  }

  const exportToCSV = () => {
    // workBook
    const book = XLSX.utils.book_new()
    const indicationsData = [
      ['Indicaciones:'],
      ['1) Todos los campos son obligatorios'],
      ['2) No debe cambiar el nombre de las columnas'],
      ['3) No debe cambiar el nombre a la hoja de datos (Data)']
    ]

    // resume sheet
    const companies =
      insuranceCompanies?.data?.map(
        (company) => `${company.id}; ${company.name || company.business_name}`
      ) || []
    const resumeData = [['nombre', ...companies]]

    if (
      formik.values.isMultilevelSelect &&
      formik.values.multilevelType === 'HIJO'
    ) {
      resumeData[0].splice(1, 0, 'asociacion')
      indicationsData.push([
        '4) En la columna asociacion debe colocar el valor de la opción del atributo padre (Distingue mayusculas de minusculas)'
      ])

      const fatherOptions = selectedFather?.AttributesLinkedListOptions?.map(
        (item) => [item.name]
      )
      if (fatherOptions && fatherOptions.length) {
        const optionsSheet = XLSX.utils.aoa_to_sheet([
          ['Opción'],
          ...fatherOptions
        ])
        XLSX.utils.book_append_sheet(book, optionsSheet, 'Opciones padre')
      }
    }

    const resumeSheet = XLSX.utils.aoa_to_sheet(resumeData)
    const indicationsSheet = XLSX.utils.aoa_to_sheet(indicationsData)
    XLSX.utils.book_append_sheet(book, indicationsSheet, 'Indicaciones')
    XLSX.utils.book_append_sheet(book, resumeSheet, 'data')

    XLSX.writeFile(book, 'formato.xlsx')
  }

  return (
    <>
      <Grid.Col xs={formik.values.isMultilevelSelect ? 6 : 12}>
        <Checkbox
          label="Jerarquico"
          checked={formik.values.isMultilevelSelect}
          onChange={(event) =>
            formik.setFieldValue(
              'isMultilevelSelect',
              event.currentTarget.checked
            )
          }
        />
      </Grid.Col>
      {formik.values.isMultilevelSelect && (
        <Grid.Col xs={6}>
          <Radio.Group
            value={formik.values.multilevelType}
            onChange={(newValue) => {
              formik.setFieldValue('multilevelType', newValue)
              formik.setFieldValue('fatherAttributeId', null)
              setSelectedFather(null)
            }}
            spacing="xl"
          >
            <Radio value="PADRE" label="Padre" />
            <Radio value="HIJO" label="Hijo" />
          </Radio.Group>
        </Grid.Col>
      )}
      <Grid.Col span={12}>
        <Divider />
      </Grid.Col>
      <Grid.Col xs={12}>
        {isLoadingFile && (
          <Center>
            <Loader />
          </Center>
        )}
        {formik.values.isMultilevelSelect &&
          formik.values.multilevelType === 'HIJO' && (
            <Grid.Col xs={12}>
              <Select
                label="Atributo padre"
                name="type"
                withAsterisk
                data={
                  data
                    ? data?.data
                        .filter(
                          (attr) =>
                            attr.isMultilevelSelect &&
                            attr.multilevelType === 'PADRE'
                        )
                        .map((attr) => ({
                          label: attr.name,
                          value: attr.id
                        }))
                    : []
                }
                value={formik.values.fatherAttributeId}
                onChange={(value) => handleFatherChange(value)}
              />
            </Grid.Col>
          )}
        {!isLoadingFile && (
          <Center>
            <Button
              leftIcon={<FiDownload />}
              sx={{
                color: 'orange',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              variant="subtle"
              onClick={exportToCSV}
            >
              Descargar formato
            </Button>
            <FileButton onChange={handleChange} accept=".xlsx">
              {(buttonProps) => (
                <Button
                  leftIcon={<FiUpload />}
                  sx={{
                    color: 'orange',
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                  variant="subtle"
                  {...buttonProps}
                >
                  Cargar data desde excel
                </Button>
              )}
            </FileButton>
          </Center>
        )}
      </Grid.Col>
      {fileName && (
        <Grid.Col xs={12}>
          <Text size="sm" align="center">
            Archivo: {fileName}
          </Text>
        </Grid.Col>
      )}
      {formik.values.AttributesLinkedListOptions.length > 0 && (
        <Grid.Col xs={12}>
          <Center>
            <Button onClick={open}>
              Asignar valor por compañías de seguro
            </Button>
          </Center>
        </Grid.Col>
      )}

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={fileName}
          centered
          size="50%"
        >
          <TextInput
            placeholder="Filtro de opciones"
            onChange={handleSearchString}
            value={searchString}
            sx={{ marginBottom: 12 }}
          />
          <Tabs
            keepMounted={false}
            defaultValue={`${dataToDisplay[0].id}-${dataToDisplay[0].name}`}
            orientation="vertical"
          >
            <Tabs.List>
              <FixedSizeList
                height={400}
                width={200}
                itemSize={50}
                itemCount={dataToDisplay.length}
              >
                {({ index, style }) => {
                  const item = dataToDisplay[index]
                  return (
                    <Tabs.Tab value={`${item.id}-${item.name}`} style={style}>
                      {item.name}
                    </Tabs.Tab>
                  )
                }}
              </FixedSizeList>
            </Tabs.List>

            {dataToDisplay.map((item) => (
              <Tabs.Panel
                value={`${item.id}-${item.name}`}
                sx={{ height: 400, overflow: 'auto', padding: 12 }}
              >
                {formik.values.isMultilevelSelect &&
                  formik.values.multilevelType === 'HIJO' && (
                    <>
                      <Select
                        label="Valor padre"
                        withAsterisk
                        data={
                          selectedFather
                            ? selectedFather?.AttributesLinkedListOptions?.map(
                                (attr) => ({
                                  label: attr.name,
                                  value: attr.id
                                })
                              )
                            : []
                        }
                        value={getChildFatherValue(item.id)}
                        onChange={(value) =>
                          handleChildFatherChange(value, item.id)
                        }
                      />
                      <Divider sx={{ marginTop: 12 }} />
                    </>
                  )}
                {insuranceCompanies?.data?.map((company) => (
                  <NumberInput
                    key={company.id}
                    label={company.name || company.business_name}
                    name={company.id}
                    placeholder="ID unico"
                    value={handleGetValue(item.id, company.id)}
                    onChange={(e) => handleTextChange(e, item.id, company.id)}
                  />
                ))}
              </Tabs.Panel>
            ))}
          </Tabs>
          <Group position="right" sx={{ marginTop: 16 }}>
            <Button onClick={close}>Guardar cambios</Button>
          </Group>
        </Modal>
      )}
    </>
  )
}

AttributesDefaultListView.propTypes = {
  formik: PropTypes.object
}

AttributesDefaultListView.defaultProps = {
  formik: null
}

export default AttributesDefaultListView
