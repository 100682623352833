// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import Plans from './pages/plans'

const plansRoutes = [
  {
    path: '/plans',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
        action="Planes:VIEW"
        yes={() => <Plans />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default plansRoutes
