// @libs
import { useState } from 'react'
// @mantine-ui
import { Box, Button, Grid } from '@mantine/core'
// @constants
import { PAGE_TITLE } from '../constants'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import ClientTypeList from '../components/ClientTypeList'
import ClientTypeForm from '../components/ClientTypeForm'
import Can from '../../../shared/components/Can'

function ClientTypes() {
  const [showForm, setShowForm] = useState(false)
  return (
    <Page title={PAGE_TITLE}>
      <Grid>
        <Grid.Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <PageTitle title={PAGE_TITLE} />
            <Can
              moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
              action="Tipos de Cliente:CREATE"
              yes={() => (
                <Button onClick={() => setShowForm((prev) => !prev)}>
                  Agregar tipo de cliente
                </Button>
              )}
            />
            <ClientTypeForm
              show={showForm}
              handleShow={() => setShowForm((prev) => !prev)}
            />
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} mt={16}>
          <ClientTypeList />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default ClientTypes
