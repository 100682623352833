import colors from './colors'
import headings from './headings'
import overrides from './overrides'

const theme = {
  colors: {
    ...colors
  },
  primaryColor: 'orange',
  ...headings,
  components: {
    ...overrides
  },
  globalStyles: () => ({
    // 'table, th, tr, td ': {
    //   border: 'none'
    // },
    '.mantine-Modal-body .mantine-Group-root': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }
  })
}

export default theme

/**
 * radius sizes xs:2, sm:4, md: 8, lg:16, xl: 32
 * sizes: xs: 30, sm: 36, md: 42, lg: 50, xl: 60
 *
 */
