export const PAGE_TITLE = 'Compañías de Seguros'
export const TABLE_HEADERS = [
  { label: 'Razón Social', key: 'business_name', col_size: 8 },
  { label: 'Activo', key: 'is_active', col_size: 2 }
]
export const QUERY_BASE_KEY = 'insurance-companies'
export const DUPLICATED_OPTIONS = {
  rut: 'rut',
  code: 'código'
}
