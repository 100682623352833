import { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Select, ActionIcon, Group, Button } from '@mantine/core'
// @components
import fetchAll from '../../../shared/hooks/useFetchAll'

const mathOperation = ['+', '-', '*', '/', '(', ')']

function getOperationAttributeDataStructure(formSelection) {
  const values = formSelection.map((operation) => `${operation.value}`)

  return {
    operation: values.join(' '),
    attributesIds: formSelection
      .filter((value) => value.type === 'ATTRIBUTE')
      .map((item) => item.value)
  }
}

function AttributesFormulaView({ formik }) {
  const [selectedAttributes, setSelectedAttributes] = useState([])
  const [value, setValue] = useState('')

  const { data } = fetchAll({
    key: 'attributes',
    filters: {
      limit: 'all',
      type: 'Numerico'
    },
    customConfig: {
      refetchOnMount: true
    }
  })

  const handleSelectChange = (option) => {
    const tempObj = {
      type: 'ATTRIBUTE',
      label: option.label,
      value: option.value
    }
    const isAlreadyIncluded = selectedAttributes.some(
      (item) => item.label === option
    )

    if (!isAlreadyIncluded) {
      const newSelection = [...selectedAttributes, tempObj]

      setSelectedAttributes(newSelection)
      formik.setFieldValue(
        'attribute_data',
        getOperationAttributeDataStructure(newSelection)
      )
      setValue(option)
    }
  }

  const removeAttribute = (option, optionIndex) => {
    const filtered = selectedAttributes.filter(
      (item, index) => index !== optionIndex
    )
    formik.setFieldValue(
      'attribute_data',
      getOperationAttributeDataStructure(filtered)
    )
    setSelectedAttributes(filtered)
  }

  const addMathSymbol = (option) => {
    if (selectedAttributes.length) {
      const tempObj = {
        type: 'MATH',
        label: option,
        value: option
      }
      setSelectedAttributes((prev) => {
        const lastAttribute = prev[prev.length - 1]

        const allowedRepeatedMathOperation = ['(', ')']

        if (
          mathOperation.indexOf(lastAttribute?.label) !== -1 &&
          mathOperation.indexOf(option) !== -1 &&
          allowedRepeatedMathOperation.indexOf(option) === -1
        ) {
          return [...prev.slice(0, prev.length - 1), tempObj]
        }

        return [...selectedAttributes, tempObj]
      })
    }
  }

  console.log('data: ', data)

  return (
    <>
      <Grid.Col xs={12}>
        <Select
          placeholder="Atributos*"
          name="type"
          data={
            data
              ? data?.data
                  .filter((item) => item.type === 'Numerico')
                  .map((item) => ({
                    label: item.name,
                    value: { label: item.name, value: item.id }
                  }))
              : []
          }
          value={value}
          onChange={handleSelectChange}
        />
      </Grid.Col>
      <Grid.Col xs={12}>
        <Group>
          {mathOperation.map((item) => (
            <ActionIcon
              color="orange"
              radius="xl"
              variant="filled"
              onClick={() => addMathSymbol(item)}
            >
              {item}
            </ActionIcon>
          ))}
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} my={12}>
        <Group>
          {selectedAttributes.map((item, index) => (
            <Button
              radius={item.type === 'ATTRIBUTE' ? 'sm' : 'xl'}
              color={item.type === 'ATTRIBUTE' ? 'gray' : 'orange'}
              onClick={() => removeAttribute(item, index)}
              size="xs"
            >
              {item.label}
            </Button>
          ))}
        </Group>
      </Grid.Col>
    </>
  )
}

AttributesFormulaView.propTypes = {
  formik: PropTypes.object
}

AttributesFormulaView.defaultProps = {
  formik: null
}

export default AttributesFormulaView
