// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import Roles from './pages/roles'

const rolesRoutes = [
  {
    path: '/roles',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.USERS}
        action="Perfiles:VIEW"
        yes={() => <Roles />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default rolesRoutes
