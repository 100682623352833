import ReactDOM from 'react-dom/client'
import { MantineProvider } from '@mantine/core'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NotificationsProvider } from '@mantine/notifications'
// @theme
import { ModalsProvider } from '@mantine/modals'
import { Provider } from 'react-redux'
import theme from './theme'
// @fonts
import '@fontsource/assistant'
import '@fontsource/montserrat'
// @components
import App from './App'
// @hooks
import { ProvideAuth } from './shared/hooks/useAuth'
// @utils
import store from './store'
import 'dayjs/locale/es-mx'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <NotificationsProvider position="top-right">
        <ModalsProvider>
          <BrowserRouter>
            <ProvideAuth>
              <Provider store={store}>
                <App />
              </Provider>
            </ProvideAuth>
          </BrowserRouter>
        </ModalsProvider>
      </NotificationsProvider>
    </QueryClientProvider>
  </MantineProvider>
)
