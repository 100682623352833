// @libs
import { useQuery } from '@tanstack/react-query'
// @services
import ApiService from '../../services/api.service'

export const useFetchAll = ({ key = '', customConfig = {}, filters = {} }) =>
  useQuery([key, filters], ApiService.fetchAll, {
    ...customConfig
  })

export default useFetchAll
