const Select = {
  styles: (theme) => ({
    input: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '24px',
      letterSpacing: '-0.015em',
      borderRadius: theme.radius.sm,
      color: '#1E1C3E',
      height: 42
    }
  })
}
export default Select
