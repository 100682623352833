// @libs
import { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// @mantine-ui
import { Grid, Switch, Text, Group } from '@mantine/core'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import usePlanMutation from '../hooks/usePlanMutation'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
import { openDeleteModal } from '../../../utils/alerts'
// @constants
import { TABLE_HEADERS } from '../constants'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import PlanForm from './PlanForm'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import Can from '../../../shared/components/Can'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'

function PlanList({ q }) {
  const [showEditForm, setShowEditForm] = useState(false)
  const [planToEdit, setPlanToEdit] = useState(null)
  const [filters, setFilters] = useState({
    page: 1,
    limit: '50'
  })
  const { data, isLoading, isError } = fetchAll({
    key: 'plans',
    filters: { ...filters, q }
  })
  const { removePlan } = usePlanMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert code={500} />
  if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (plan) =>
    openDeleteModal({
      title: 'Eliminar Plan',
      content: `¿Estás seguro de eliminar el Plan ${plan.name}?.`,
      onConfirm: async () => removePlan.mutateAsync(plan.id)
    })
  const getValue = (plan, key) => {
    if (key.includes('.')) {
      return _.get(plan, key)
    }
    return plan[key]
  }
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS.map((header, index) => (
            <Grid.Col xs={1.7} key={header.label}>
              <Text
                align={index === 0 ? 'start' : 'center'}
                sx={{
                  fontSize: 16,
                  lineHeight: '24px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {header.label}
              </Text>
            </Grid.Col>
          ))}
          <Grid.Col xs={1.7} />
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {data?.data?.map((plan) => (
          <Grid
            key={plan.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            {TABLE_HEADERS.map((header, index) => (
              <Grid.Col xs={1.7} key={header.key}>
                {header.key !== 'is_active' ? (
                  <Text
                    align={index === 0 ? 'start' : 'center'}
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '24px',
                      letterSpacing: '0.04em',
                      opacity: 0.8
                    }}
                    truncate
                  >
                    {getValue(plan, header.key)}
                  </Text>
                ) : (
                  <Group position="center">
                    <Switch
                      size="sm"
                      color="green"
                      checked={getValue(plan, header.key)}
                    />
                  </Group>
                )}
              </Grid.Col>
            ))}
            <Grid.Col xs={1.7}>
              <Group position="center">
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
                  action="Planes:EDIT"
                  yes={() => (
                    <EditIconButton
                      onClick={() => {
                        setPlanToEdit(plan)
                        setShowEditForm((prev) => !prev)
                      }}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
                  action="Planes:DELETE"
                  yes={() => (
                    <DeleteIconButton onClick={() => handleDelete(plan)} />
                  )}
                />
              </Group>
            </Grid.Col>
          </Grid>
        ))}
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <RowsCount
          value={filters.limit}
          onChange={(value) => setFilters({ ...filters, limit: value })}
        />
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <PaginationBase
          total={data?.meta?.lastPage}
          value={data?.meta?.currentPage}
          onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
        />
      </Grid.Col>
      <PlanForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        planToEdit={planToEdit}
      />
    </Grid>
  )
}

PlanList.propTypes = {
  q: PropTypes.string
}

PlanList.defaultProps = {
  q: ''
}

export default PlanList
