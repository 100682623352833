// @libs
import { forwardRef } from 'react'
import { FiFilter } from 'react-icons/fi'
// @mantine-ui
import { ActionIcon } from '@mantine/core'

function FilterIconButton({ ...props }, ref) {
  return (
    <ActionIcon ref={ref} size="md" color="dark" radius="sm" {...props}>
      <FiFilter />
    </ActionIcon>
  )
}

export default forwardRef(FilterIconButton)
