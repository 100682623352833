// @libs
import PropTypes from 'prop-types'
import { Box } from '@mantine/core'
import { memo } from 'react'
// @mantine-ui

function Logo({ name, ...props }) {
  return <Box component="img" src={`/static/${name}`} {...props} />
}

Logo.propTypes = {
  name: PropTypes.string
}

Logo.defaultProps = {
  name: 'logo.svg'
}

export default memo(Logo)
