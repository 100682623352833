// @libs
import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// @services
import ApiService from '../../../services/api.service'

const queryKey = 'forms'

const useFormsMutation = () => {
  const queryClient = useQueryClient()

  const resetQueries = () => queryClient.invalidateQueries(queryKey)

  const addForms = useMutation((body) => ApiService.create([queryKey, body]), {
    onSuccess: () => {
      resetQueries()
      showNotification({
        title: 'Realizado',
        message: '¡Registro creado!',
        color: 'green'
      })
    },
    onError: (err) => {
      if (err.statusCode === 409) {
        return showNotification({
          title: 'Error',
          message: 'Ya existe un registro con los datos ingresados.',
          color: 'red'
        })
      }
      return showNotification({
        title: 'Error',
        message: 'No fue posible crear el registro.',
        color: 'red'
      })
    }
  })
  const updateForms = useMutation(
    ([id, body]) => ApiService.update([queryKey, id, body]),
    {
      onSuccess: () => {
        resetQueries()
        showNotification({
          title: 'Realizado',
          message: '¡Registro actualizado!',
          color: 'green'
        })
      },
      onError: (err) => {
        if (err.statusCode === 409) {
          return showNotification({
            title: 'Error',
            message: 'Ya existe un registro con los datos ingresados.',
            color: 'red'
          })
        }
        return showNotification({
          title: 'Error',
          message: 'No fue posible actualizar el registro.',
          color: 'red'
        })
      }
    }
  )
  const removeForms = useMutation((id) => ApiService.remove([queryKey, id]), {
    onSuccess: () => {
      showNotification({
        title: 'Realizado',
        message: '¡Registro eliminado!',
        color: 'green'
      })
      resetQueries()
    },
    onError: (err) => {
      if (err.statusCode === 409) {
        return showNotification({
          title: 'Error',
          message: 'No es posible eliminar este registro, hay datos asociados.',
          color: 'red'
        })
      }
      return showNotification({
        title: 'Error',
        message: 'No fue posible eliminar el registro.',
        color: 'red'
      })
    }
  })
  const updateOrdersForms = useMutation(
    (body) => ApiService.create([`${queryKey}/order`, body]),
    {
      onSuccess: () => {
        resetQueries()
        showNotification({
          title: 'Realizado',
          message: '¡Cambios realizados con exito!',
          color: 'green'
        })
      },
      onError: () =>
        showNotification({
          title: 'Error',
          message: 'No fue posible aplicar los cambios.',
          color: 'red'
        })
    }
  )

  return {
    addForms,
    updateForms,
    removeForms,
    updateOrdersForms
  }
}

export default useFormsMutation
