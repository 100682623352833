// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import Forms from './pages/forms'

const formsRoutes = [
  {
    path: '/forms',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
        action="Formularios:VIEW"
        yes={() => <Forms />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default formsRoutes
