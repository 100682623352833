// @mantine-ui
import { Box, Text, Grid, Card, Container, Center, Button } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import successImage from '../../../assets/images/success_logo.png'

function Success() {
  const { uniqueTupleId } = useParams()
  const navigate = useNavigate()

  return (
    <Container>
      <Card
        sx={{
          marginTop: 12,
          background: '#FFFFFF',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '26px'
        }}
      >
        <Grid>
          <Grid.Col xs={12}>
            <Center>
              <Box component="img" src={successImage} sx={{ width: 130 }} />
            </Center>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Text
              align="center"
              sx={(theme) => ({
                fontWeight: 700,
                fontSize: 22,
                lineHeight: '39px',
                letterSpacing: '0.04em',
                opacity: 0.8,
                [theme.fn.smallerThan('sm')]: {
                  fontSize: 24,
                  lineHeight: '26px'
                }
              })}
            >
              La propuesta junto con los siguientes pasos han sido enviados al
              correo electronico indicado. Gracias por contratar tu seguro a
              traves de nosotros.
            </Text>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Center>
              <Button onClick={() => navigate(`/cotiza/${uniqueTupleId}`)}>
                Cotizar otro producto
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      </Card>
    </Container>
  )
}

export default Success
