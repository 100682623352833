// @libs
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import PropTypes from 'prop-types'
// @mantine-ui
import { useDebouncedState } from '@mantine/hooks'
import {
  Grid,
  Switch,
  Text,
  Group,
  Popover,
  Checkbox,
  SegmentedControl,
  Divider
} from '@mantine/core'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useAttributesMutation from '../hooks/useAttributesMutation'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
import { openDeleteModal } from '../../../utils/alerts'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
// import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import Can from '../../../shared/components/Can'
import {
  setAttributeDataToUpdate,
  setModalShow
} from '../../../store/attributesSlice'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'
import FilterIconButton from '../../../shared/components/Buttons/FilterIconButton'
import attributesTypeOptions from '../../../utils/attributesType'

function AttributesList({ searchText }) {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({
    sort_by: ['created_at:desc'],
    page: 1,
    limit: '50'
  })
  const [checked, setChecked] = useState({ ALL: true, NONE: false })
  const [isInputFilter, setIsInputFilter] = useDebouncedState('all', 500)
  const [typeFilter, setTypeFilter] = useDebouncedState(
    attributesTypeOptions,
    1000
  )
  const [typeValues, setTypeValues] = useState(attributesTypeOptions)

  const { data, isLoading, isError } = fetchAll({
    key: 'attributes',
    filters: {
      ...filters,
      is_input: isInputFilter,
      type: typeFilter,
      searchText
    }
  })
  const { removeAttributes } = useAttributesMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert />
  // if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (attributes) => {
    const formSectionItemsCountIsGreaterThanZero =
      // eslint-disable-next-line no-underscore-dangle
      attributes?._count?.FormSectionItem > 0

    const content = !formSectionItemsCountIsGreaterThanZero
      ? `¿Estás seguro de eliminar el Atributo ${attributes.name}?.`
      : 'Este atributo se encuentra en uso en un formulario por lo que no se puede eleminar.'

    openDeleteModal({
      title: 'Eliminar Atributo',
      content,
      onConfirm: async () => removeAttributes.mutateAsync(attributes.id),
      confirmExtraProps: formSectionItemsCountIsGreaterThanZero && {
        display: 'none'
      }
    })
  }

  const handleTypeFilterChange = (value) => {
    setChecked({ ALL: false, NONE: false })
    setTypeValues(value)
    setTypeFilter(value)
  }

  const handleAllChange = (event) => {
    const {
      currentTarget: { name }
    } = event

    if (name === 'ALL') {
      setChecked({ ALL: true, NONE: false })
      setTypeValues(attributesTypeOptions)
      setTypeFilter(attributesTypeOptions)
    }

    if (name === 'NONE') {
      setChecked({ ALL: false, NONE: true })
      setTypeValues([])
      setTypeFilter(['NONE'])
    }
  }

  return (
    <Grid gutter={42}>
      <Grid.Col xs={12}>
        <Grid>
          <Grid.Col xs={4}>
            <Text
              align="justify"
              sx={{
                fontFamily: 'Assistant',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '26px',
                letterSpacing: '0.04em',
                color: '#1E1C3E',
                opacity: 0.8
              }}
            >
              Nombre
            </Text>
          </Grid.Col>
          <Grid.Col xs={2}>
            <Group position="center">
              <Text
                align="center"
                sx={{
                  fontFamily: 'Assistant',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  color: '#1E1C3E',
                  opacity: 0.8
                }}
              >
                Tipo
              </Text>
              <Popover width={220} position="bottom" withArrow shadow="md">
                <Popover.Target>
                  <FilterIconButton />
                </Popover.Target>
                <Popover.Dropdown>
                  <SegmentedControl
                    fullWidth
                    color="orange"
                    sx={{
                      backgroundColor: '#fff',
                      border: '1px solid lightgray'
                    }}
                    defaultValue={isInputFilter}
                    onChange={setIsInputFilter}
                    data={[
                      { label: 'Todos', value: 'all' },
                      { label: 'Entrada', value: 'true' },
                      { label: 'Salida', value: 'false' }
                    ]}
                  />
                </Popover.Dropdown>
              </Popover>
            </Group>
          </Grid.Col>
          <Grid.Col xs={2}>
            <Group position="center">
              <Text
                align="center"
                sx={{
                  fontFamily: 'Assistant',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  color: '#1E1C3E',
                  opacity: 0.8
                }}
              >
                Tipo de campo
              </Text>
              <Popover width={170} position="bottom" withArrow shadow="md">
                <Popover.Target>
                  <FilterIconButton />
                </Popover.Target>
                <Popover.Dropdown>
                  <Checkbox
                    name="ALL"
                    label="TODOS"
                    checked={checked.ALL}
                    onChange={handleAllChange}
                  />
                  <Checkbox
                    name="NONE"
                    checked={checked.NONE}
                    label="NINGUNO"
                    onChange={handleAllChange}
                    sx={{ marginTop: 6 }}
                  />
                  <Divider my={3} />
                  <Checkbox.Group
                    value={typeValues}
                    onChange={handleTypeFilterChange}
                  >
                    {attributesTypeOptions.map((attr) => (
                      <Checkbox key={attr} value={attr} label={attr} />
                    ))}
                  </Checkbox.Group>
                </Popover.Dropdown>
              </Popover>
            </Group>
          </Grid.Col>
          <Grid.Col xs={2}>
            <Text
              align="center"
              sx={{
                fontFamily: 'Assistant',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '26px',
                letterSpacing: '0.04em',
                color: '#1E1C3E',
                opacity: 0.8
              }}
            >
              Activo
            </Text>
          </Grid.Col>
          <Grid.Col span={2} />
          <Grid.Col span={12}>
            {data?.data?.map((attributes) => (
              <Grid
                key={attributes.id}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 16,
                  marginBottom: 16,
                  display: 'flex',
                  alignItems: 'center'
                }}
                px={8}
              >
                <Grid.Col xs={4}>
                  <Text
                    align="start"
                    sx={{
                      fontFamily: 'Assistant',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '26px',
                      letterSpacing: '0.04em',
                      color: '#1E1C3E',
                      opacity: 0.8
                    }}
                  >
                    {attributes.name}
                  </Text>
                </Grid.Col>
                <Grid.Col xs={2}>
                  <Text
                    align="center"
                    sx={{
                      fontFamily: 'Assistant',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '26px',
                      letterSpacing: '0.04em',
                      color: '#1E1C3E',
                      opacity: 0.8
                    }}
                  >
                    {attributes.is_input ? 'Entrada' : 'Salida'}
                  </Text>
                </Grid.Col>
                <Grid.Col xs={2}>
                  <Text
                    align="center"
                    sx={{
                      fontFamily: 'Assistant',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '26px',
                      letterSpacing: '0.04em',
                      color: '#1E1C3E',
                      opacity: 0.8
                    }}
                  >
                    {attributes.type}
                  </Text>
                </Grid.Col>
                <Grid.Col xs={2}>
                  <Group position="center">
                    <Switch
                      size="sm"
                      color="green"
                      checked={attributes.is_active}
                    />
                  </Group>
                </Grid.Col>
                <Grid.Col xs={2}>
                  <Group position="center">
                    <Can
                      moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
                      action="Atributos:EDIT"
                      yes={() => (
                        <EditIconButton
                          onClick={() => {
                            dispatch(setAttributeDataToUpdate(attributes))
                            dispatch(setModalShow(true))
                          }}
                        />
                      )}
                    />
                    <Can
                      moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
                      action="Atributos:DELETE"
                      yes={() => (
                        <DeleteIconButton
                          onClick={() => handleDelete(attributes)}
                        />
                      )}
                    />
                  </Group>
                </Grid.Col>
              </Grid>
            ))}
          </Grid.Col>
          <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <RowsCount
              value={filters.limit}
              onChange={(value) => setFilters({ ...filters, limit: value })}
            />
          </Grid.Col>
          <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <PaginationBase
              total={data?.meta?.lastPage}
              value={data?.meta?.currentPage}
              onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

AttributesList.propTypes = {
  searchText: PropTypes.string
}

AttributesList.defaultProps = {
  searchText: ''
}

export default AttributesList
