const Modal = {
  styles: (theme) => ({
    modal: {
      borderRadius: theme.radius.lg,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    close: {
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#494862'
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 16,
      fontFamily: 'Assistant',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '-0.015em',
      color: '#1E1C3E'
    }
  })
}
export default Modal
