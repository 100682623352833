// @libs
import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// @services
import ApiService from '../../../services/api.service'

const queryKey = 'client-types-has-insurance-types'

const useInsuranceSettingsMutation = () => {
  const queryClient = useQueryClient()

  const resetQueries = () => queryClient.invalidateQueries(queryKey)

  const updateInsuranceSettings = useMutation(
    ([id, body]) => ApiService.update([queryKey, id, body]),
    {
      onSuccess: () => {
        resetQueries()
        showNotification({
          title: 'Realizado',
          message: 'Configuración actualizada!',
          color: 'green'
        })
      },
      onError: () =>
        showNotification({
          title: 'Error',
          message: 'No fue posible actualizar el registro.',
          color: 'red'
        })
    }
  )

  return {
    updateInsuranceSettings
  }
}

export default useInsuranceSettingsMutation
