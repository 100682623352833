// @libs
import { HiPlus } from 'react-icons/hi'
// @mantine-ui
import { ActionIcon } from '@mantine/core'

function AddIconButton({ ...props }) {
  return (
    <ActionIcon radius="xl" {...props}>
      <HiPlus size={18} color="orange" />
    </ActionIcon>
  )
}

export default AddIconButton
