// @libs
import * as yup from 'yup'
import validateRut from 'verificador-rut'
// @constants
import { REQUIRED_MESSAGE } from '../../utils/contants'

yup.addMethod(yup.string, 'isRut', function (errorMessage) {
  return this.test(`validate-rut`, errorMessage, function (value) {
    const { path, createError } = this
    const regex = /^\d{7,8}-[\dkK]{1}$/
    if (!value)
      return createError({ path, message: errorMessage ?? REQUIRED_MESSAGE })
    if (!value.match(regex))
      return createError({
        path,
        message: errorMessage ?? 'El formato es incorrecto'
      })
    if (!validateRut(value.toLowerCase()))
      return createError({
        path,
        message: errorMessage ?? 'El rut no es válido'
      })
    return true
  })
})

const agentSchema = yup.object().shape({
  rut: yup.string().isRut(),
  code: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  last_name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  phone: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  email: yup
    .string()
    .email('El formato no es válido')
    .required(REQUIRED_MESSAGE),
  address: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  city: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  commune: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  is_active: yup.boolean().default(true)
})

export default agentSchema
