// @libs
import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// @services
import ApiService from '../../../services/api.service'
// @constants
import { DUPLICATED_OPTIONS, QUERY_BASE_KEY } from '../constants'

const useInsuranceCompanyMutation = () => {
  const queryClient = useQueryClient()

  const resetQueries = () => queryClient.invalidateQueries(QUERY_BASE_KEY)
  const getDuplicateFieldLabel = (fieldName) => DUPLICATED_OPTIONS[fieldName]

  const addInsuranceCompany = useMutation(
    (body) => ApiService.create([QUERY_BASE_KEY, body]),
    {
      onSuccess: () => {
        resetQueries()
        showNotification({
          title: 'Realizado',
          message: '¡Registro creado!',
          color: 'green'
        })
      },
      onError: (err) => {
        if (err.statusCode === 409) {
          const label = getDuplicateFieldLabel(err?.errorField) || ''
          return showNotification({
            title: 'Error',
            message: `Ya existe un registro con el ${label}  ingresado`,
            color: 'red'
          })
        }
        return showNotification({
          title: 'Error',
          message: 'No fue posible crear el registro.',
          color: 'red'
        })
      }
    }
  )
  const updateInsuranceCompany = useMutation(
    ([id, body]) => ApiService.update([QUERY_BASE_KEY, id, body]),
    {
      onSuccess: () => {
        resetQueries()
        showNotification({
          title: 'Realizado',
          message: '¡Registro actualizado!',
          color: 'green'
        })
      },
      onError: (err) => {
        if (err.statusCode === 409) {
          const label = getDuplicateFieldLabel(err?.errorField) || ''
          return showNotification({
            title: 'Error',
            message: `Ya existe un registro con el ${label}  ingresado`,
            color: 'red'
          })
        }
        return showNotification({
          title: 'Error',
          message: 'No fue posible actualizar el registro.',
          color: 'red'
        })
      }
    }
  )
  const removeInsuranceCompany = useMutation(
    (id) => ApiService.remove([QUERY_BASE_KEY, id]),
    {
      onSuccess: () => {
        showNotification({
          title: 'Realizado',
          message: '¡Registro eliminado!',
          color: 'green'
        })
        resetQueries()
      },
      onError: (err) => {
        if (err.statusCode === 409) {
          return showNotification({
            title: 'Error',
            message:
              'No es posible eliminar este registro, hay datos asociados.',
            color: 'red'
          })
        }
        return showNotification({
          title: 'Error',
          message: 'No fue posible eliminar el registro.',
          color: 'red'
        })
      }
    }
  )

  return {
    addInsuranceCompany,
    updateInsuranceCompany,
    removeInsuranceCompany
  }
}

export default useInsuranceCompanyMutation
