// @libs
import { FiEdit2 } from 'react-icons/fi'
// @mantine-ui
import { ActionIcon } from '@mantine/core'

function EditIconButton({ ...props }) {
  return (
    <ActionIcon radius="xl" {...props}>
      <FiEdit2 size={18} color="orange" />
    </ActionIcon>
  )
}

export default EditIconButton
