// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import CodeCampaigns from './pages/code-campaigns'
import CodeCampaignsDetails from './pages/code-campaign-details'

const codeCampaignRoutes = [
  {
    path: '/code-campaigns',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.AGENTS}
        action="Códigos:VIEW"
        yes={() => <CodeCampaigns />}
        no={() => <ErrorPage code={401} />}
      />
    )
  },
  {
    path: '/code-campaigns/:codeCampaignId',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.AGENTS}
        action="Códigos:VIEW"
        yes={() => <CodeCampaignsDetails />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default codeCampaignRoutes
