import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, TextInput, Text, Center, Button } from '@mantine/core'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import axiosInstance from '../../../helpers/axios'

function FidEmailVerificationModal({ open, handleClose, insurance }) {
  const [isLoading, setIsLoading] = useState(false)
  // const navigate = useNavigate()
  const { uniqueTupleId } = useParams()

  const formik = useFormik({
    initialValues: {
      emailCode: ''
    },
    onSubmit: async (formValues, { resetForm }) => {
      setIsLoading(true)
      const { data } = await axiosInstance.post(
        `/client-types-has-insurance-types/fid_validate`,
        {
          emailCode: formValues.emailCode,
          dealId: insurance.fidDealID,
          uniqueTupleId
        }
      )
      // redirect to data.paymentPageUrl
      window.location.href = data.paymentRegisterPortal
      // navigate(`/cotiza/${uniqueTupleId}/exito`)
      setIsLoading(false)
      resetForm()
    }
  })

  return (
    <ModalBase
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={open}
      onClose={handleClose}
      title="Validar email FID"
    >
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Grid.Col span={12}>
          <Text>
            Hemos enviado un codigo al correo especificado, por favor ingreselo
            abajo para continuar el proceso.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Codigo"
            name="emailCode"
            onChange={formik.handleChange}
            value={formik.values.emailCode}
            error={formik.touched.emailCode && formik.errors.emailCode}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Center>
            <Button onClick={formik.handleSubmit} loading={isLoading}>
              Validar
            </Button>
          </Center>
        </Grid.Col>
      </Grid>
    </ModalBase>
  )
}

FidEmailVerificationModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  insurance: PropTypes.object
}

FidEmailVerificationModal.defaultProps = {
  handleClose: () => null,
  open: false,
  insurance: {}
}

export default FidEmailVerificationModal
