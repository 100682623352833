export const localStorageKeys = {
  AUTENTICARED_USER: 'authenticatedUser',
  DATA: 'data',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken'
}

export const saveInLocalStorage = (key, value) => {
  let currentItem = localStorage.getItem(key) || {}
  currentItem = typeof currentItem === 'string' && JSON.parse(currentItem)
  const newItem = {
    ...currentItem,
    ...value
  }

  localStorage.setItem(key, JSON.stringify(newItem))
}

export const getInLocalStorage = (key) => {
  const result = localStorage.getItem(key)
  return !!result && JSON.parse(result)
}

export const removeInLocalStorage = (key) => {
  localStorage.removeItem(key)
}
