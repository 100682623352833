import { Grid, Select } from '@mantine/core'
import PropTypes from 'prop-types'

const tableDataTypes = [
  { label: 'Numero Entero', value: 'INT' },
  { label: 'Numero Decimal', value: 'FLOAT' },
  { label: 'Porcentaje', value: 'PERCENT' }
]

function AttributesNumericView({ formik }) {
  return (
    <Grid.Col xs={12}>
      <Select
        placeholder="Tipos de dato"
        data={tableDataTypes}
        value={formik.values.attribute_data.numberType}
        onChange={(value) =>
          formik.setFieldValue('attribute_data', {
            numberType: value
          })
        }
      />
    </Grid.Col>
  )
}

AttributesNumericView.propTypes = {
  formik: PropTypes.object
}

AttributesNumericView.defaultProps = {
  formik: null
}

export default AttributesNumericView
