// @libs
import { useState } from 'react'
import PropTypes from 'prop-types'
// @mantine-ui
import {
  Button,
  Grid,
  Text,
  Paper,
  Modal,
  Badge,
  TextInput,
  Select,
  MultiSelect,
  Table,
  Checkbox,
  NumberInput,
  Container,
  Group,
  FileInput,
  Tooltip,
  SegmentedControl,
  Input
} from '@mantine/core'
import 'dayjs/locale/es-mx'
import { DatePicker } from '@mantine/dates'
import { validateRut } from 'rutlib'
import { RiPercentLine } from 'react-icons/ri'

function PreviewFormModal({ formik, isLoading }) {
  const [show, setShow] = useState(false)
  const [formAnswers, setFormAnswers] = useState({})

  const createTableData = (tableOptions) => {
    const { columns, rowNumber } = tableOptions
    const th = (
      <thead>
        <tr>
          {columns?.map((column) => (
            <th>{column.displayName}</th>
          ))}
        </tr>
      </thead>
    )
    const rows = []

    for (let i = 0; i < rowNumber; i += 1) {
      rows.push(
        <tr>
          {columns?.map((column) => {
            if (column.dataType === 'Fecha') {
              return (
                <td>
                  <DatePicker
                    size="md"
                    locale="es-mx"
                    dropdownType="modal"
                    placeholder="Ingrese fecha"
                  />
                </td>
              )
            }
            if (column.dataType === 'Numero Entero') {
              return (
                <td>
                  <NumberInput />
                </td>
              )
            }
            if (column.dataType === 'Numero Decimal') {
              return (
                <td>
                  <NumberInput precision={2} />
                </td>
              )
            }
            if (column.dataType === 'Si/No') {
              return (
                <td>
                  <Checkbox />
                </td>
              )
            }
            return (
              <td>
                <TextInput />
              </td>
            )
          })}
        </tr>
      )
    }

    return (
      <>
        {th} <tbody>{rows}</tbody>
      </>
    )
  }

  const generateRange = (attributeProp) => {
    const minValue = attributeProp.attribute.attribute_data.min
    const maxValue = attributeProp.attribute.attribute_data.max
    const options = []

    for (let index = minValue; index <= maxValue; index += 1) {
      options.push({
        label: index.toString(),
        value: index
      })
    }
    return options
  }

  const submitHandler = async () => {
    await formik.submitForm()

    setShow(false)
  }

  return (
    <>
      <Button
        color="dark"
        disabled={formik.values.formSections.length === 0}
        onClick={() => setShow((prev) => !prev)}
        sx={{ marginRight: 12 }}
      >
        Vista previa
      </Button>

      <Modal
        overflow="inside"
        size="55%"
        closeOnClickOutside={false}
        opened={show}
        onClose={() => setShow((prev) => !prev)}
        title="Vista Previa Formulario de Seguros"
      >
        <Container>
          <Grid>
            <Grid.Col xs={12} sx={{ textAlign: 'end' }}>
              <Badge
                color="dark"
                size="xl"
                radius="sm"
                variant="filled"
                sx={{ textTransform: 'none' }}
              >
                Vista previa
              </Badge>
            </Grid.Col>
            {formik.values.formSections.map((section) => (
              <>
                <Grid.Col xs={12}>
                  <Text
                    sx={{
                      fontFamily: 'Assistant',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: '24px',
                      letterSpacing: '0.04em',
                      color: '#1E1C3E'
                    }}
                  >
                    {section.title}
                  </Text>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Paper
                    shadow="xs"
                    radius="md"
                    p="md"
                    sx={{ backgroundColor: '#d7d7d7' }}
                  >
                    <Grid>
                      <Grid.Col xs={12}>
                        <Text>{section.description}</Text>
                      </Grid.Col>
                      {section.type === 'ATTRIBUTES' && (
                        <>
                          {section.formSectionItem.map((sectionItem) => {
                            if (sectionItem.type === 'Lista predeterminada') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <Select
                                      label={sectionItem.text}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(newValue) =>
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]: newValue
                                        })
                                      }
                                      data={
                                        sectionItem.attribute?.AttributesLinkedListOptions?.map(
                                          (option) => ({
                                            label: option.name,
                                            value: option.id
                                          })
                                        ) ?? []
                                      }
                                      withAsterisk={sectionItem.is_required}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }
                            if (
                              sectionItem.type === 'Lista' &&
                              !sectionItem.is_multiple
                            ) {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <Select
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(newValue) =>
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]: newValue
                                        })
                                      }
                                      label={sectionItem.text}
                                      data={sectionItem.formSectionListItem.map(
                                        (option) => ({
                                          label: option.name,
                                          value: option.name // forDemo
                                          // value: option.id
                                        })
                                      )}
                                      withAsterisk={sectionItem.is_required}
                                      multiple={sectionItem.is_multiple}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (
                              sectionItem.type === 'Lista' &&
                              sectionItem.is_multiple
                            ) {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <MultiSelect
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      label={sectionItem.text}
                                      data={sectionItem.formSectionListItem.map(
                                        (option) => ({
                                          label: option.name,
                                          value: option.id
                                        })
                                      )}
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(newValue) =>
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]: newValue
                                        })
                                      }
                                      withAsterisk={sectionItem.is_required}
                                      multiple={sectionItem.is_multiple}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Tabla') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Text>{sectionItem.text}</Text>
                                  <Table withBorder withColumnBorders>
                                    {createTableData(
                                      sectionItem.attribute.attribute_data
                                    )}
                                  </Table>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Texto') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <TextInput
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(e) =>
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]:
                                            e.currentTarget.value
                                        })
                                      }
                                      label={sectionItem.text}
                                      withAsterisk={sectionItem.is_required}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Rango numerico') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <Select
                                      label={sectionItem.text}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      data={generateRange(sectionItem)}
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(newValue) =>
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]: newValue
                                        })
                                      }
                                      withAsterisk={sectionItem.is_required}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Vigencia') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <DatePicker
                                      locale="es-mx"
                                      label={sectionItem.text}
                                      withAsterisk={sectionItem.is_required}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      placeholder="Ingrese fecha"
                                      minDate={
                                        new Date(
                                          sectionItem.attribute.attribute_data.startDate
                                        )
                                      }
                                      maxDate={
                                        new Date(
                                          sectionItem.attribute.attribute_data.endDate
                                        )
                                      }
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(newValue) => {
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]: newValue
                                        })
                                      }}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Numerico') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <NumberInput
                                      label={sectionItem.text}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      withAsterisk={sectionItem.is_required}
                                      {...(sectionItem.attribute.attribute_data
                                        .numberType === 'FLOAT' && {
                                        precision: 2
                                      })}
                                      {...(sectionItem.attribute.attribute_data
                                        .numberType === 'PERCENT' && {
                                        min: 0,
                                        max: 100,
                                        icon: <RiPercentLine />
                                      })}
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(newValue) => {
                                        let valueParsed = newValue
                                        if (
                                          sectionItem.attribute.attribute_data
                                            .numberType === 'PERCENT'
                                        ) {
                                          valueParsed /= 100
                                        }
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]:
                                            valueParsed
                                        })
                                      }}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Moneda') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <NumberInput
                                      label={sectionItem.text}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      withAsterisk={sectionItem.is_required}
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      onChange={(newValue) =>
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]: newValue
                                        })
                                      }
                                      parser={(value) =>
                                        value.replace(/\$\s?|(\.*)/g, '')
                                      }
                                      formatter={(value) =>
                                        !Number.isNaN(parseFloat(value))
                                          ? `${value}`.replace(
                                              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                              '.'
                                            )
                                          : ''
                                      }
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Rut') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <TextInput
                                      label={sectionItem.text}
                                      withAsterisk={sectionItem.is_required}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      value={
                                        formAnswers[
                                          `${sectionItem.attribute_id}`
                                        ] || ''
                                      }
                                      {...(sectionItem.attribute.attribute_data
                                        .validate && {
                                        error:
                                          !validateRut(
                                            formAnswers[
                                              `${sectionItem.attribute_id}`
                                            ] || ''
                                          ) && 'Rut invalido'
                                      })}
                                      onChange={(e) =>
                                        setFormAnswers({
                                          ...formAnswers,
                                          [sectionItem.attribute_id]:
                                            e.target.value
                                        })
                                      }
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Adjunto') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <FileInput
                                      label={sectionItem.text}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      withAsterisk={sectionItem.is_required}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    />
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Formula') {
                              const { operation, attributesIds } =
                                sectionItem.attribute.attribute_data

                              let formOperation = operation

                              attributesIds.forEach((attributeId) => {
                                const attributeInputValue =
                                  formAnswers[attributeId] ?? 0

                                formOperation = formOperation.replaceAll(
                                  attributeId,
                                  attributeInputValue
                                )
                              })

                              // regex que verifica la operacion posea solo numeros, espacios y los siguientes simbolos '+', '-', '*', '/', (', ')', '.', ','
                              const validOperation =
                                // eslint-disable-next-line no-useless-escape
                                /^[0-9+\-*\/\(\) .,]+$/.test(formOperation)

                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Text>{sectionItem.text}</Text>
                                  <Text>
                                    {sectionItem.attribute.description_text}
                                  </Text>
                                  <Text>
                                    {sectionItem.attribute.description_text}
                                  </Text>
                                  {validOperation && (
                                    <>
                                      {/* eslint-disable-next-line no-eval */}
                                      <Text>{eval(formOperation.trim())}</Text>
                                    </>
                                  )}
                                  {!validOperation && (
                                    <Text>Operacion no permitida</Text>
                                  )}
                                </Grid.Col>
                              )
                            }

                            if (sectionItem.type === 'Verdadero / falso') {
                              return (
                                <Grid.Col xs={sectionItem.col_span}>
                                  <Tooltip
                                    multiline
                                    withArrow
                                    label={
                                      sectionItem.attribute.description_text
                                    }
                                    position="bottom"
                                    events={{
                                      hover:
                                        sectionItem.attribute.display_tooltip,
                                      focus: false,
                                      touch: false
                                    }}
                                  >
                                    <Input.Wrapper
                                      label={sectionItem.text}
                                      description={
                                        !sectionItem.attribute
                                          .display_tooltip &&
                                        sectionItem.attribute.description_text
                                      }
                                      withAsterisk={sectionItem.is_required}
                                      inputWrapperOrder={[
                                        'label',
                                        'input',
                                        'description'
                                      ]}
                                    >
                                      <SegmentedControl
                                        fullWidth
                                        data={[
                                          { label: 'Si', value: 'true' },
                                          { label: 'No', value: 'false' }
                                        ]}
                                        value={
                                          formAnswers[
                                            `${sectionItem.attribute_id}`
                                          ] || false
                                        }
                                        onChange={(newValue) =>
                                          setFormAnswers({
                                            ...formAnswers,
                                            [sectionItem.attribute_id]: newValue
                                          })
                                        }
                                        color="orange"
                                        sx={{ backgroundColor: '#fff' }}
                                      />
                                    </Input.Wrapper>
                                  </Tooltip>
                                </Grid.Col>
                              )
                            }

                            return null
                          })}
                        </>
                      )}
                    </Grid>
                  </Paper>
                </Grid.Col>
              </>
            ))}
            <Grid.Col xs={12}>
              <Group position="center">
                <Button size="md" onClick={submitHandler} loading={isLoading}>
                  Finalizar y grabar
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </Container>
      </Modal>
    </>
  )
}

PreviewFormModal.propTypes = {
  formik: PropTypes.object,
  isLoading: PropTypes.bool
}

PreviewFormModal.defaultProps = {
  formik: null,
  isLoading: false
}

export default PreviewFormModal
