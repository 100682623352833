// @components
import { Text, Grid, Button } from '@mantine/core'

function InsurancesFilterNoData() {
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Text fz={72}>😕</Text>
      </Grid.Col>
      <Grid.Col xs={12} mt={16}>
        <Text c="orange" fw={700} fz={32}>
          No encontramos el seguro que estas buscando.
        </Text>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Text fz={24}>
          Cambia la información de busqueda y reinicia el proceso. Si necesitas
          ayuda contáctanos.
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} mt={16}>
        {/* // agregar enviar cotización a whatsapp */}
        <Button fw={500} mr={16}>
          Hablemos por whatsapp
        </Button>
        <Button fw={500} variant="outline" sx={{ color: 'orange' }}>
          Llamanos
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default InsurancesFilterNoData
