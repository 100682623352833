const colors = {
  orange: [
    '#E2D9CD',
    '#D7C7B0',
    '#D1B793',
    '#D1A972',
    '#D89E4E',
    '#E69525',
    '#F28D01',
    '#C47B17',
    '#A16D24',
    '#86602C',
    '#70552F',
    '#5F4C30',
    '#514330'
  ],
  'dark-blue': [
    '#494858',
    '#414051',
    '#39384C',
    '#323047',
    '#2B2944',
    '#242340',
    '#1E1C3E',
    '#1E1C35',
    '#1D1C2D',
    '#1B1A27',
    '#191922',
    '#18171E',
    '#16161A'
  ]
}
export default colors
