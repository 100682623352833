// @libs
import * as yup from 'yup'
// @constants
import { REQUIRED_MESSAGE } from '../../utils/contants'

const contactSquema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  email: yup
    .string()
    .email('El formato no es válido')
    .required(REQUIRED_MESSAGE),
  insuranceTypeHasInsuranceCompany_id: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres')
})

export default contactSquema
