const headings = {
  fontWeight: 400,
  fontFamily: 'Assistant',
  sizes: {
    h1: { fontWeight: 700, fontSize: 90, lineHeight: 90 },
    h2: { fontSize: 28, lineHeight: 1.5 },
    // ...up to h6
    h6: { fontWeight: 900 }
  }
}
export default headings
