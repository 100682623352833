// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import Agents from './pages/agents'
import AgentsStats from './pages/agents-stats'

const agentsRoutes = [
  {
    path: '/agents',
    element: <Agents />
  },
  {
    path: '/agents-stats',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.AGENTS}
        action="Estadísticas:VIEW"
        yes={() => <AgentsStats />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default agentsRoutes
