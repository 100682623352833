// @libs
import { useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import validateRut from 'verificador-rut'
import dayjs from 'dayjs'
import { NavLink } from 'react-router-dom'
// @mantine-ui
import {
  Button,
  Grid,
  TextInput,
  Box,
  Select,
  NumberInput,
  LoadingOverlay,
  Chip,
  ScrollArea
} from '@mantine/core'
// @validations
import codeSchema from '../validations'
// @hooks
import useCodeCampaignMutation from '../hooks/useCodeCampaignMutations'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'
import DatePickerBase from '../../../shared/components/DatePickers/DatePickerBase'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import FileButtonBase from '../../../shared/components/Buttons/FileButtonBase'
import Excel from '../../../shared/components/Excel'
import BasicLoader from '../../../shared/components/BasicLoader'

function CodeCampaignForm({ show, handleShow, codeToEdit }) {
  const [isLoadingFile, setIsLoadingFile] = useState(false)
  const { addCodeCampaign, updateCodeCampaign } = useCodeCampaignMutation()

  const agents = fetchAll({
    key: 'agents',
    customConfig: {
      enabled: show
    }
  })

  const formik = useFormik({
    initialValues: {
      description: codeToEdit?.description || '',
      insurance_company_id: codeToEdit?.insurance_company_id || '',
      is_active: codeToEdit?.is_active || true,
      is_nominative: codeToEdit?.is_nominative || false,
      name: codeToEdit?.name || '',
      quantity: codeToEdit?.quantity || '',
      with_validity: !!codeToEdit?.start_date || false,
      start_date: codeToEdit?.start_date || '',
      end_date: codeToEdit?.end_date || '',
      agent_id: codeToEdit?.agent?.id || '',
      codes: []
    },
    enableReinitialize: true,
    validationSchema: codeSchema,
    validateOnChange: true,
    onSubmit: (codeCampaignData, { resetForm }) => {
      if (codeToEdit) {
        return updateCodeCampaign
          .mutateAsync([codeToEdit.id, { ...codeToEdit, ...codeCampaignData }])
          .then(() => {
            handleShow()
            resetForm()
          })
      }
      return addCodeCampaign
        .mutateAsync({
          ...codeCampaignData,
          quantity: codeCampaignData.is_nominative
            ? codeCampaignData.codes.length
            : codeCampaignData.quantity
        })
        .then(() => {
          handleShow()
          resetForm()
        })
    }
  })
  const handleClose = () => {
    formik.resetForm()
    handleShow()
  }
  const readFileAsync = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsArrayBuffer(file)
    })

  const handleChange = async (file) => {
    setIsLoadingFile((prev) => !prev)
    try {
      const bufferArray = await readFileAsync(file)
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1500))
      // new book
      const wb = XLSX.read(bufferArray, { type: 'buffer' })
      const rutsData = XLSX.utils.sheet_to_json(wb.Sheets.RUTS)
      const codes = rutsData
        .filter((rut) => validateRut(String(rut.RUT)))
        .map((rut) => rut.RUT)
      formik.setFieldValue('codes', codes)
    } catch (e) {
      console.error('e', e)
    } finally {
      setIsLoadingFile((prev) => !prev)
    }
  }
  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Ingresa un nuevo código aquí"
    >
      <LoadingOverlay radius="lg" visible={isLoadingFile} />
      {agents.isLoading ? (
        <BasicLoader />
      ) : (
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid.Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <TextInput
                placeholder="Descripción*"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                error={formik.errors.description}
              />
              <SwitchBase
                label="Activo"
                name="is_active"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                value={formik.values.is_active}
                error={formik.errors.is_active}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              {formik.values.is_nominative ? (
                <TextInput
                  disabled
                  placeholder="Cantidad"
                  name="quantity"
                  value={formik.values.quantity}
                />
              ) : (
                <NumberInput
                  placeholder="Cantidad*"
                  name="quantity"
                  onChange={(val) => formik.setFieldValue('quantity', val)}
                  value={formik.values.quantity}
                  error={formik.errors.quantity}
                  min={1}
                  parser={(value) => value.replace(/\$\s?|(\.*)/g, '')}
                  formatter={(value) =>
                    !Number.isNaN(parseFloat(value))
                      ? `${value}`.replace(
                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                          '.'
                        )
                      : ''
                  }
                />
              )}
            </Grid.Col>
            <Grid.Col xs={12}>
              <Select
                name="agent_id"
                value={formik.values.agent_id}
                clearable
                placeholder="Agente asignado"
                nothingFound="Sin resultados"
                data={
                  agents?.data
                    ? agents.data.map(
                        (agent) =>
                          agent && {
                            ...agent,
                            value: agent.id,
                            label: `${agent?.user?.name} ${agent?.user?.last_name}`
                          }
                      )
                    : []
                }
                onChange={(option) => {
                  formik.setFieldValue('agent_id', option)
                }}
                error={formik.errors.agent_id}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <SwitchBase
                label="Maneja vigencia"
                name="with_validity"
                checked={formik.values.with_validity}
                onChange={(event) => {
                  formik.setFieldValue(
                    'with_validity',
                    event.currentTarget.checked
                  )
                  formik.setFieldValue('start_date', '')
                  formik.setFieldValue('end_date', '')
                }}
              />
            </Grid.Col>
            {formik.values.with_validity && (
              <Grid.Col xs={12}>
                <Grid>
                  <Grid.Col xs={12} sm={6}>
                    <DatePickerBase
                      placeholder="Fecha de inicio*"
                      name="start_date"
                      minDate={dayjs(new Date()).toDate()}
                      maxDate={dayjs(new Date()).add(2, 'y').toDate()}
                      onChange={(value) =>
                        formik.setFieldValue('start_date', value)
                      }
                      value={
                        codeToEdit?.start_date
                          ? dayjs(formik.values.start_date).toDate()
                          : formik.values.start_date
                      }
                      error={formik.errors.start_date}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <DatePickerBase
                      placeholder="Fecha de término*"
                      name="end_date"
                      minDate={dayjs(new Date()).toDate()}
                      maxDate={dayjs(new Date()).add(2, 'y').toDate()}
                      onChange={(value) =>
                        formik.setFieldValue('end_date', value)
                      }
                      value={
                        codeToEdit?.end_date
                          ? dayjs(formik.values.end_date).toDate()
                          : formik.values.end_date
                      }
                      error={formik.errors.end_date}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            )}
            {!codeToEdit && (
              <Grid.Col xs={12}>
                <SwitchBase
                  label="Nominativo"
                  name="is_nominative"
                  checked={formik.values.is_nominative}
                  onChange={(event) => {
                    formik.setFieldValue(
                      'is_nominative',
                      event.currentTarget.checked
                    )
                    formik.setFieldValue('codes', [])
                    formik.setFieldValue('quantity', '')
                  }}
                />
              </Grid.Col>
            )}
            {formik.values.is_nominative && !codeToEdit && (
              <Grid.Col xs={12}>
                <Grid>
                  <Grid.Col
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <FileButtonBase
                      onChange={handleChange}
                      label="Cargar RUT desde excel"
                      accept=".xlsx"
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <EditIconButton />
                      <DeleteIconButton />
                      <Excel
                        formatName="codesRutsFormat"
                        isFullButton={false}
                      />
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <ScrollArea
                      h={100}
                      type="always"
                      p={8}
                      mb={8}
                      sx={{
                        display: 'flex',
                        borderRadius: 8,
                        border: '1px solid red'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-between'
                        }}
                      >
                        {formik.values.codes.map((rut) => (
                          <Chip defaultChecked m={2}>
                            {rut}
                          </Chip>
                        ))}
                      </Box>
                    </ScrollArea>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            )}
            {codeToEdit && (
              <Grid.Col xs={12} align="right">
                <Button
                  component={NavLink}
                  to={`/code-campaigns/${codeToEdit?.id}`}
                >
                  Ver códigos
                </Button>
              </Grid.Col>
            )}
            <Grid.Col xs={12} mt={16}>
              <Button
                fullWidth
                loading={
                  addCodeCampaign.isLoading || updateCodeCampaign.isLoading
                }
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

CodeCampaignForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  codeToEdit: PropTypes.object
}

CodeCampaignForm.defaultProps = {
  show: false,
  handleShow: () => {},
  codeToEdit: null
}

export default CodeCampaignForm
