import { utils } from 'xlsx'

export default () => {
  // workBook
  const book = utils.book_new()
  // resume sheet
  const resumeData = [['RUT'], ['17954899-2']]
  const resumeSheet = utils.aoa_to_sheet(resumeData)
  utils.book_append_sheet(book, resumeSheet, 'RUTS')
  return book
}
