// @libs
import { useState } from 'react'
// @mantine-ui
import { Box, Button, Grid } from '@mantine/core'
// @constants
import { PAGE_TITLE } from '../constants'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import RiskClassificationList from '../components/RiskClassificationList'
import RiskClassificationForm from '../components/RiskClassificationForm'
import Can from '../../../shared/components/Can'

function RiskClassifications() {
  const [showForm, setShowForm] = useState(false)
  return (
    <Page title={PAGE_TITLE}>
      <Grid>
        <Grid.Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <PageTitle title="Clasificaciones de Riesgo" />
            <Can
              moduleName={MODULES_PERMISSIONS.RISK_CLASSIFICATIONS}
              action="CREATE"
              yes={() => (
                <Button onClick={() => setShowForm((prev) => !prev)}>
                  Nueva Clasificación
                </Button>
              )}
            />

            <RiskClassificationForm
              show={showForm}
              handleShow={() => setShowForm((prev) => !prev)}
            />
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} mt={16}>
          <RiskClassificationList />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default RiskClassifications
