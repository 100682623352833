// @libs
import PropTypes from 'prop-types'
import { IoSearch } from 'react-icons/io5'
// @mantine-ui
import { TextInput } from '@mantine/core'

function SearchInput({ placeholder, content, color, ...props }) {
  return (
    <TextInput
      rightSection={<IoSearch size={22} color="orange" />}
      placeholder={placeholder}
      styles={(theme) => ({
        input: {
          borderRadius: theme.radius.xl
        }
      })}
      {...props}
    />
  )
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.string
}

SearchInput.defaultProps = { placeholder: 'Buscar', content: '', color: '' }

export default SearchInput
