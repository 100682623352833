// @libs
import { useState } from 'react'
// @mantine-ui
import { Grid, Group, Switch, Text } from '@mantine/core'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useClientTypeMutation from '../hooks/useClientTypeMutation'
// @utils
import { openDeleteModal } from '../../../utils/alerts'
import { MODULES_PERMISSIONS } from '../../../utils/contants'
// @constants
import { TABLE_HEADERS } from '../constants'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import ClientTypeForm from './ClientTypeForm'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import Can from '../../../shared/components/Can'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'

function ClientTypeList() {
  const [showEditForm, setShowEditForm] = useState(false)
  const [clientTypeToEdit, setClientTypeToEdit] = useState(null)
  const [filters, setFilters] = useState({
    sort_by: ['created_at:desc'],
    page: 1,
    limit: '50'
  })

  const { data, isLoading, isError } = fetchAll({
    key: 'client-types',
    filters
  })
  const { removeClientType } = useClientTypeMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert />
  if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (clientType, i) =>
    openDeleteModal({
      title: 'Eliminar Tipo de Cliente',
      content: `¿Estás seguro de eliminar el Tipo de Cliente ${clientType.name}?.`,
      onConfirm: async () =>
        removeClientType.mutateAsync(clientType.id).then(() => {
          if (!i && filters.page > 1) {
            setFilters((cf) => ({
              ...cf,
              page: cf.page - 1
            }))
          }
        })
    })

  return (
    <Grid>
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS.map((header, index) => (
            <Grid.Col xs={4} key={header.label}>
              <Text
                align={index === 0 ? 'justify' : 'center'}
                sx={{
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {header.label}
              </Text>
            </Grid.Col>
          ))}
          <Grid.Col xs={4} />
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {data?.data?.map((clientType, i) => (
          <Grid
            key={clientType.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            {TABLE_HEADERS.map((header, index) => (
              <Grid.Col xs={4} key={header.key}>
                {!header.key.includes('is_active') ? (
                  <Text
                    align={index === 0 ? 'start' : 'center'}
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '26px',
                      letterSpacing: '0.04em',
                      opacity: 0.8
                    }}
                    truncate
                  >
                    {clientType[header.key]}
                  </Text>
                ) : (
                  <Group position="center">
                    <Switch
                      size="sm"
                      color="green"
                      checked={clientType[header.key]}
                    />
                  </Group>
                )}
              </Grid.Col>
            ))}
            <Grid.Col xs={4}>
              <Group position="center">
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
                  action="Tipos de Cliente:EDIT"
                  yes={() => (
                    <EditIconButton
                      onClick={() => {
                        setClientTypeToEdit(clientType)
                        setShowEditForm((prev) => !prev)
                      }}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
                  action="Tipos de Cliente:DELETE"
                  yes={() => (
                    <DeleteIconButton
                      onClick={() => handleDelete(clientType, i)}
                    />
                  )}
                />
              </Group>
            </Grid.Col>
          </Grid>
        ))}
        <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <RowsCount
            value={filters.limit}
            onChange={(value) => setFilters({ ...filters, limit: value })}
          />
        </Grid.Col>
        <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <PaginationBase
            total={data?.meta?.lastPage}
            value={data?.meta?.currentPage}
            onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
          />
        </Grid.Col>
      </Grid.Col>
      <ClientTypeForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        clientTypeToEdit={clientTypeToEdit}
      />
    </Grid>
  )
}

export default ClientTypeList
