// @components
import PublicLayout from '../../shared/components/PublicLayout'
// @pages
import Client from './pages/client'
import Cotization from './pages/cotization'
import Compare from './pages/compare'
import Success from './pages/Success'

const ClientRoutes = [
  {
    path: '/cotiza/:uniqueTupleId',
    element: (
      <PublicLayout
        customStyles={{
          backgroundColor: '#F4F4F4'
        }}
        showAccessButton={false}
      >
        <Client />
      </PublicLayout>
    )
  },
  {
    path: '/cotiza/:uniqueTupleId/cotization',
    element: (
      <PublicLayout
        customStyles={{
          backgroundColor: 'white',
          overflow: 'hidden'
        }}
        showAccessButton={false}
      >
        <Cotization />
      </PublicLayout>
    )
  },
  {
    path: '/cotiza/:uniqueTupleId/compare',
    element: (
      <PublicLayout
        customStyles={{
          backgroundColor: '#F8F6F6'
        }}
        showAccessButton={false}
      >
        <Compare />
      </PublicLayout>
    )
  },
  {
    path: '/cotiza/:uniqueTupleId/exito',
    element: (
      <PublicLayout>
        <Success />
      </PublicLayout>
    )
  }
]

export default ClientRoutes
