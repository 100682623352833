// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Switch } from '@mantine/core'

function SwitchBase({ color, size, label, ...props }) {
  return (
    <Switch
      color={color}
      size={size}
      labelPosition="top"
      label={label}
      {...props}
    />
  )
}

SwitchBase.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string
}

SwitchBase.defaultProps = {
  color: 'green',
  size: 'md',
  label: 'Activa'
}

export default SwitchBase
