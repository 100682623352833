// @libs
import * as yup from 'yup'
// @constants
import { REQUIRED_MESSAGE } from '../../utils/contants'

const attributesSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  type: yup.string().required(REQUIRED_MESSAGE)
})

export default attributesSchema
