// @libs
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
// @routes
import attributesRoutes from '../features/attributes/routes'
import riskClassificationRoutes from '../features/risk-classifications/routes'
import clientTypesRoutes from '../features/client-types/routes'
import insuranceCompaniesRoutes from '../features/insurance-companies/routes'
import agentsRoutes from '../features/agents/routes'
import insuranceTypesRoutes from '../features/insurance-types/routes'
import insuranceSettingsRoutes from '../features/insurance-settings/routes'
import plansRoutes from '../features/plans/routes'
import productsRoutes from '../features/products/routes'
import formsRoutes from '../features/forms/routes'
import codeCampaignRoutes from '../features/code-campaigns/routes'
import usersRoutes from '../features/users/routes'
import rolesRoutes from '../features/roles/routes'
import authRoutes from '../features/auth/routes'
import clientRoutes from '../features/client/routes'

// @components
const Layout = lazy(() => import('../shared/components/Layout'))
const NotFound = lazy(() => import('../shared/components/NotFound'))

const privateRouter = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '/', element: <Navigate to="/risk-classifications" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '404', element: <NotFound /> },
      ...attributesRoutes,
      ...riskClassificationRoutes,
      ...clientTypesRoutes,
      ...insuranceCompaniesRoutes,
      ...agentsRoutes,
      ...insuranceTypesRoutes,
      ...insuranceSettingsRoutes,
      ...plansRoutes,
      ...formsRoutes,
      ...codeCampaignRoutes,
      ...usersRoutes,
      ...rolesRoutes,
      ...productsRoutes
    ]
  },
  ...clientRoutes,
  ...authRoutes
]

export default privateRouter
