import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formAnswers: null,
  apiResponse: {
    Fault: { codigoError: '0', detalleError: 'OK' },
    OfertasBase: {
      OfertaBase: [
        {
          Deducibles: {
            DeducibleBase: [
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 7,
                deducible: 'SD',
                nombrePlan: 'PT+RC_SD',
                valorEnPeso: 225954,
                valorEnUf: 6.27,
                valorUf: 36037.38
              }
            ]
          },
          codigoProducto: 1,
          producto: 'PT+RC'
        },
        {
          Deducibles: {
            DeducibleBase: [
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 1,
                deducible: 'SD',
                nombrePlan: 'CLASSIC_SD',
                valorEnPeso: 596418,
                valorEnUf: 16.55,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 2,
                deducible: 'D3',
                nombrePlan: 'CLASSIC_D3',
                valorEnPeso: 440376,
                valorEnUf: 12.22,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 3,
                deducible: 'D5',
                nombrePlan: 'CLASSIC_D5',
                valorEnPeso: 390645,
                valorEnUf: 10.84,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 4,
                deducible: 'D10',
                nombrePlan: 'CLASSIC_D10',
                valorEnPeso: 344877,
                valorEnUf: 9.57,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 9,
                deducible: 'D20',
                nombrePlan: 'CLASSIC_D20',
                valorEnPeso: 314245,
                valorEnUf: 8.72,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 10,
                deducible: 'D50',
                nombrePlan: 'CLASSIC_D50',
                valorEnPeso: 268478,
                valorEnUf: 7.45,
                valorUf: 36037.38
              }
            ]
          },
          codigoProducto: 2,
          producto: 'Classic'
        },
        {
          Deducibles: {
            DeducibleBase: [
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 1,
                deducible: 'SD',
                nombrePlan: 'FULL_SD',
                valorEnPeso: 727234,
                valorEnUf: 20.18,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 2,
                deducible: 'D3',
                nombrePlan: 'FULL_D3',
                valorEnPeso: 544164,
                valorEnUf: 15.1,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 3,
                deducible: 'D5',
                nombrePlan: 'FULL_D5',
                valorEnPeso: 485783,
                valorEnUf: 13.48,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 4,
                deducible: 'D10',
                nombrePlan: 'FULL_D10',
                valorEnPeso: 432448,
                valorEnUf: 12,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 9,
                deducible: 'D20',
                nombrePlan: 'FULL_D20',
                valorEnPeso: 396771,
                valorEnUf: 11.01,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 10,
                deducible: 'D50',
                nombrePlan: 'FULL_D50',
                valorEnPeso: 343075,
                valorEnUf: 9.52,
                valorUf: 36037.38
              }
            ]
          },
          codigoProducto: 3,
          producto: 'Full'
        },
        {
          Deducibles: {
            DeducibleBase: [
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 1,
                deducible: 'SD',
                nombrePlan: 'PREMIUM_SD',
                valorEnPeso: 1020938,
                valorEnUf: 28.33,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 2,
                deducible: 'D3',
                nombrePlan: 'PREMIUM_D3',
                valorEnPeso: 828859,
                valorEnUf: 23,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 3,
                deducible: 'D5',
                nombrePlan: 'PREMIUM_D5',
                valorEnPeso: 767956,
                valorEnUf: 21.31,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 4,
                deducible: 'D10',
                nombrePlan: 'PREMIUM_D10',
                valorEnPeso: 711377,
                valorEnUf: 19.74,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 9,
                deducible: 'D20',
                nombrePlan: 'PREMIUM_D20',
                valorEnPeso: 674259,
                valorEnUf: 18.71,
                valorUf: 36037.38
              },
              {
                Opcionales: {
                  Opcional: [
                    {
                      descripcion: '-',
                      factor: '0'
                    }
                  ]
                },
                codigoDeducible: 10,
                deducible: 'D50',
                nombrePlan: 'PREMIUM_D50',
                valorEnPeso: 618041,
                valorEnUf: 17.15,
                valorUf: 36037.38
              }
            ]
          },
          codigoProducto: 4,
          producto: 'Premium'
        }
      ]
    }
  },
  customResponses: {},
  FID_RESPONSE_TEST: {
    items: [
      {
        itemId: 0,
        quotations: [
          {
            id: 0,
            planId: '177',
            FIDId: '0000086874',
            expiryDate: '2022-06-20',
            inspectionRequired: 0,
            policyInceptionDate: '2022-06-06',
            policyExpiryDate: '2023-06-06',
            policyPeriodVigency: 365,
            deductible: 0,
            netPremium: 77.94,
            vat: 14.81,
            grossWrittenPremium: 92.75,
            brokerage: 10.16,
            liabilityAmount: 500,
            garageType: 'Multimarca',
            vehicleReplacement: 'Sin reposicion',
            mobilityCoverage: 0,
            additionalCoverages: [1, 2, 3],
            monthlyPremium: 301879.69,
            valueUf: 31955.2,
            plan: {
              id: 1,
              name: 'Automotriz Cobertura Low Cost',
              parameters: [
                {
                  id: 21,
                  name: 'Deducible',
                  type: 'Deducible',
                  valueType_N: 'UF',
                  value_N: 0,
                  rangeDescriptions: ['deducible'],
                  ranges: [
                    {
                      id: 1,
                      name: 'deducible',
                      values: [
                        {
                          value: 0,
                          type: 'UF'
                        }
                      ]
                    }
                  ]
                }
              ],
              coverages: [
                {
                  id: 1,
                  name: 'Daños materiales al vehículo',
                  generalDescription:
                    'Cubre los gastos en reparaciones, mano de obra y refacciones que necesite el vehículo en caso de que resulte dañado en algún siniestro vial o de cualquier tipo.',
                  isMain: false,
                  isParam: 0,
                  valueDescription: 'Valor comercial',
                  value: 'Valor comercial',
                  polCad: 'POL120000420'
                }
              ],
              exclusions: [
                {
                  id: 15,
                  name: 'Vehículos de uso comercial',
                  generalDescription:
                    'Se excluye vehículos de uso comercial, como: Taxi, Taxi colectivo y similares (UBER, CABIFY, BEAT, DIDI y otros de transporte de pasajeros), vehículos dados en arriendo o tipo Rent a Car, vehículos destinados al uso de locomoción colectiva, furgones escolares, vehículos de turismo, vehículos de emergencia (policiales, ambulancias, carros bomba, vehículos de auxilio o similares), vehículos de escuelas de conductores, vehículos utilizados para exhibiciones o de pruebas (test car), vehículos de competición y/o colección, y vehículos cuyos modelos no correspondan a versiones vendidas en Chile por representantes de la marca debidamente autorizados.'
                }
              ],
              benefits: [
                {
                  id: 22,
                  name: 'Remolque o transporte del vehículo',
                  generalDescription:
                    'En caso de que el Vehículo Asegurado no pudiera circular por accidente o avería de cualquier naturaleza, el prestador de asistencia se hará cargo del remolque o transporte hasta el taller que elija el Titular o domicilio del asegurado.',
                  isParam: false,
                  valueDescription: 'UF 15',
                  value: 'UF 15'
                }
              ]
            }
          }
        ]
      }
    ]
  },
  productsToCompare: []
}

export const cotization = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setFormAnswersAndResponse: (state, action) => {
      state.formAnswers = action.payload.formAnswers
      state.apiResponse = action.payload.apiResponse
    },
    setCustomResponses: (state, action) => {
      state.customResponses = { ...state.customResponses, ...action.payload }
    },
    setProductsToCompare: (state, action) => {
      state.productsToCompare = action.payload
    }
  }
})

export const {
  setFormAnswersAndResponse,
  setProductsToCompare,
  setCustomResponses
} = cotization.actions

export default cotization.reducer
