// @libs
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useState } from 'react'
// @mantine-ui
import { Button, Grid, Text, TextInput, Box } from '@mantine/core'
// @validations
import agentSchema from '../validations'
// @utilities
import { getRamdomString } from '../../../utils'
// @hooks
import useAgentMutation from '../hooks/useAgentMutation'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'
import DatePickerBase from '../../../shared/components/DatePickers/DatePickerBase'
import BasicLoader from '../../../shared/components/BasicLoader'

function AgentForm({ show, handleShow, agentToEdit }) {
  const { addAgent, updateAgent } = useAgentMutation()
  const [dischargeDate, setDischargeDate] = useState(new Date())
  const [birthDate, setBirthDate] = useState(
    dayjs(new Date()).subtract(18, 'years').toDate()
  )

  const formik = useFormik({
    initialValues: {
      rut: agentToEdit?.user?.rut || '',
      is_active: agentToEdit?.user?.is_active || true,
      name: agentToEdit?.user?.name || '',
      last_name: agentToEdit?.user?.last_name || '',
      code: agentToEdit?.code || '',
      phone: agentToEdit?.phone || '',
      email: agentToEdit?.user?.email || '',
      address: agentToEdit?.address || '',
      city: agentToEdit?.city || '',
      commune: agentToEdit?.commune || '',
      birth_date: agentToEdit?.user?.birth_date || birthDate,
      discharge_date: agentToEdit?.discharge_date || dischargeDate
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: agentSchema,
    onSubmit: (agentData, { resetForm }) => {
      if (agentToEdit) {
        return updateAgent
          .mutateAsync([agentToEdit.id, { ...agentToEdit, ...agentData }])
          .then(() => {
            handleShow()
            resetForm()
          })
      }
      return addAgent
        .mutateAsync({ ...agentData, password: getRamdomString(8) })
        .then(() => {
          handleShow()
          resetForm()
        })
    }
  })

  const clientsInsurancesData = fetchAll({
    key: 'client-types-has-insurance-types',
    customConfig: {
      enabled: show,
      refetchOnMount: false,
      staleTime: 60000
    }
  })

  const handleClose = () => {
    formik.resetForm()
    handleShow()
  }
  const handleCode = () => {
    const newCode = getRamdomString(6)
    return formik.setFieldValue('code', newCode)
  }

  return (
    <ModalBase
      opened={show}
      onClose={() => handleClose()}
      title="Ingresa la información del Agente"
    >
      {clientsInsurancesData.isLoading ? (
        <BasicLoader />
      ) : (
        <Box
          component="form"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          sx={{ marginTop: 16 }}
        >
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid.Col xs={12} align="center">
              <Text
                sx={{
                  fontWeight: 700,
                  textAlign: 'center'
                }}
              >
                Información del Agente
              </Text>
            </Grid.Col>
            <Grid.Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <TextInput
                placeholder="Rut*"
                name="rut"
                onChange={formik.handleChange}
                value={formik.values.rut}
                error={formik.errors.rut}
              />
              <SwitchBase
                label="Activo"
                name="is_active"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                value={formik.values.is_active}
                error={formik.errors.is_active}
              />
            </Grid.Col>
            <Grid.Col xs={8}>
              <TextInput
                placeholder="Nombre*"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.errors.name}
              />
            </Grid.Col>
            <Grid.Col xs={4} align="right">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Text>Código:</Text>
                <Text
                  px={4}
                  sx={{
                    backgroundColor: '#D89E4E',
                    borderRadius: 16,
                    color: '#FFF',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  onClick={handleCode}
                >
                  {formik.values.code || 'Generar'}
                </Text>
              </Box>
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Apellido*"
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                error={formik.errors.last_name}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Teléfono*"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={formik.errors.phone}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Correo*"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.errors.email}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Dirección*"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                error={formik.errors.address}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder="Ciudad*"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                error={formik.errors.city}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder="Comuna*"
                name="commune"
                onChange={formik.handleChange}
                value={formik.values.commune}
                error={formik.errors.commune}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <DatePickerBase
                placeholder="Fecha de nacimiento*"
                name="birth_date"
                maxDate={dayjs(new Date()).toDate()}
                onChange={setBirthDate}
                value={birthDate}
                error={formik.errors.birth_date}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <DatePickerBase
                placeholder="Fecha de alta*"
                name="discharge_date"
                onChange={setDischargeDate}
                value={dischargeDate}
                error={formik.errors.discharge_date}
              />
            </Grid.Col>
            {/* <Grid.Col xs={12}>
              <AgentInsurances
                data={Object.entries(
                  _.groupBy(clientsInsurancesData.data, 'clientType.name')
                ).map(([key, values]) => ({
                  client_type_has_insurance_type_id: values[0].id,
                  name: key,
                  insurances: values.map((insurance) => insurance.insuranceType)
                }))}
              />
            </Grid.Col> */}
            <Grid.Col xs={8}>
              <Button
                fullWidth
                loading={addAgent.isLoading || updateAgent.isLoading}
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

AgentForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  agentToEdit: PropTypes.object
}

AgentForm.defaultProps = {
  show: false,
  handleShow: () => {},
  agentToEdit: null
}

export default AgentForm
