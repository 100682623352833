// @libs
import { IoSearch } from 'react-icons/io5'
// @mantine-ui
import { ActionIcon } from '@mantine/core'

function DetailsIconButton({ ...props }) {
  return (
    <ActionIcon radius="xl" {...props}>
      <IoSearch size={18} color="orange" />
    </ActionIcon>
  )
}

export default DetailsIconButton
