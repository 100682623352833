// @libs
import PropTypes from 'prop-types'
// @hooks
import { useUser } from '../hooks/useUser'

function Can({ moduleName, action, yes, no }) {
  const user = useUser()
  if (!user) {
    return no()
  }

  return user?.role?.permissions?.some(
    (p) =>
      p.permission.module.name === moduleName && p.permission.action === action
  )
    ? yes()
    : no()
}

Can.propTypes = {
  moduleName: PropTypes.string,
  action: PropTypes.string,
  yes: PropTypes.any,
  no: PropTypes.any
}
Can.defaultProps = {
  moduleName: '',
  action: '',
  yes: () => null,
  no: () => null
}

export default Can
