// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Box, Checkbox, Text } from '@mantine/core'
// @components
import AddIconButton from '../../../shared/components/Buttons/AddIconButton'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import DetailsIconButton from '../../../shared/components/Buttons/DetailsIconButton'

const actions = {
  CREATE: <AddIconButton />,
  VIEW: <DetailsIconButton />,
  EDIT: <EditIconButton />,
  DELETE: <DeleteIconButton />
}

function ModuleItemActions({
  title,
  permissions,
  handleChangePermission,
  currentPermissions
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: title ? 'space-between' : 'flex-end',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Text>{title}</Text>
      <Box sx={{ display: 'flex' }}>
        {permissions.map((permission) => (
          <Checkbox
            styles={{
              body: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0
              },
              label: {
                paddingLeft: 0
              }
            }}
            checked={currentPermissions.includes(permission.id)}
            title={permission.name}
            value={permission.id}
            onClick={(e) =>
              handleChangePermission(permission.id, e.currentTarget.checked)
            }
            label={
              actions[permission.action.split(':')[1] || permission.action]
            }
          />
        ))}
      </Box>
    </Box>
  )
}

ModuleItemActions.propTypes = {
  title: PropTypes.string,
  permissions: PropTypes.array,
  handleChangePermission: PropTypes.func,
  currentPermissions: PropTypes.array
}

ModuleItemActions.defaultProps = {
  title: '',
  permissions: [],
  handleChangePermission: () => {},
  currentPermissions: []
}

export default ModuleItemActions
