// @libs
import { useState } from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
// @mantine-ui
import {
  Collapse,
  Grid,
  Group,
  Switch,
  Text,
  Button,
  Popover,
  Checkbox
} from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import { FiDownload } from 'react-icons/fi'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useUserMutation from '../hooks/useUserMutation'
// @utilities
import { MODULES_PERMISSIONS } from '../../../utils/contants'
import { openDeleteModal } from '../../../utils/alerts'
// @constants
import { TABLE_HEADERS } from '../constants'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import UserForm from './UserForm'
// import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import DetailsIconButton from '../../../shared/components/Buttons/DetailsIconButton'
import UserDetails from './UserDetails'
import FilterIconButton from '../../../shared/components/Buttons/FilterIconButton'
import Can from '../../../shared/components/Can'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'

function UserList({ q }) {
  const [showEditForm, setShowEditForm] = useState(false)
  const [userToEdit, setUserToEdit] = useState(null)
  const [userDetails, setUserDetails] = useState(null)
  const [filters, setFilters] = useState({
    sort_by: ['created_at:asc'],
    page: 1,
    limit: '50',
    roles: []
  })
  const [rolesFilters, setRolesFilters] = useDebouncedState([], 500)

  const { data, isLoading, isError } = fetchAll({
    key: 'users',
    filters: { ...filters, q, roles: rolesFilters }
  })

  const { data: rolesData } = fetchAll({
    key: 'roles',
    filters: {
      limit: 'all'
    }
  })
  const { removeUser } = useUserMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert />
  // if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (user) =>
    openDeleteModal({
      title: 'Eliminar Usuario',
      content: `¿Estás seguro de eliminar la Usuario ${user.name} ${user.last_name}?.`,
      onConfirm: async () => removeUser.mutateAsync(user.id)
    })

  const handleUserDetails = (user) => {
    if (user === userDetails) return setUserDetails(null)
    return setUserDetails(user)
  }

  const exportToCSV = () => {
    // workBook
    const book = XLSX.utils.book_new()

    // resume sheet
    const users =
      data?.data?.map((user) => [
        user.rut,
        user.name,
        user.last_name,
        user.email,
        user.role?.name,
        user.agent?.code,
        user.agent?.city,
        user.agent?.commune,
        user.agent?.phone
      ]) || []
    const resumeData = [
      [
        'rut',
        'nombre',
        'apellido',
        'correo',
        'perfil',
        'codigo agente',
        'ciudad',
        'comuna',
        'telefono'
      ],
      ...users
    ]
    const resumeSheet = XLSX.utils.aoa_to_sheet(resumeData)

    XLSX.utils.book_append_sheet(book, resumeSheet, 'usuarios')

    XLSX.writeFile(book, 'usuarios.xlsx')
  }

  return (
    <Grid>
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS.map((header, index) => (
            <Grid.Col xs={2} key={header.label}>
              <Group position={index === 0 ? 'left' : 'center'}>
                <Text
                  sx={{
                    fontSize: 16,
                    lineHeight: '26px',
                    letterSpacing: '0.04em',
                    opacity: 0.8,
                    display: 'flex'
                  }}
                >
                  {header.label}
                </Text>

                {header.isSortable && (
                  <Popover width={150} position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <FilterIconButton />
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Checkbox.Group
                        defaultValue={rolesFilters}
                        onChange={(value) => setRolesFilters(value)}
                      >
                        {rolesData?.data.map((role) => (
                          <Checkbox
                            key={role.id}
                            value={role.id.toString()}
                            label={role.name}
                          />
                        ))}
                      </Checkbox.Group>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </Group>
            </Grid.Col>
          ))}
          <Grid.Col xs={2}>
            <Button
              leftIcon={<FiDownload />}
              sx={{
                color: 'orange',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              variant="subtle"
              onClick={exportToCSV}
            >
              Descargar data
            </Button>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {data?.data?.map((user) => (
          <Grid
            key={user.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            <Grid.Col xs={2}>
              <Text
                align="start"
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  cursor: 'context-menu'
                }}
                truncate
                title={user.rut}
              >
                {user.rut}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Text
                align="center"
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  cursor: 'context-menu'
                }}
                truncate
                title={`${user.name} ${user.last_name}`}
              >
                {`${user.name} ${user.last_name}`}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Text
                align="center"
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  cursor: 'context-menu'
                }}
                truncate
                title={user.email}
              >
                {user.email}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Text
                align="center"
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  cursor: 'context-menu'
                }}
                truncate
                title={user.role.name}
              >
                {user.role.name}
              </Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Group position="center">
                <Switch size="sm" color="green" checked={user.is_active} />
              </Group>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Group position="center">
                <Can
                  moduleName={MODULES_PERMISSIONS.USERS}
                  action="Usuarios:EDIT"
                  yes={() => (
                    <EditIconButton
                      onClick={() => {
                        setUserToEdit(user)
                        setShowEditForm((prev) => !prev)
                      }}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.USERS}
                  action="Usuarios:VIEW"
                  yes={() => (
                    <DetailsIconButton
                      onClick={() => handleUserDetails(user)}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.USERS}
                  action="Usuarios:DELETE"
                  yes={() => (
                    <DeleteIconButton onClick={() => handleDelete(user)} />
                  )}
                />
              </Group>
            </Grid.Col>
            <Collapse
              px={8}
              py={16}
              sx={{ width: '100%' }}
              in={userDetails === user}
            >
              <UserDetails isOpen={userDetails === user} userId={user.id} />
            </Collapse>
          </Grid>
        ))}
        <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <RowsCount
            value={filters.limit}
            onChange={(value) => setFilters({ ...filters, limit: value })}
          />
        </Grid.Col>
        <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <PaginationBase
            total={data?.meta?.lastPage}
            value={data?.meta?.currentPage}
            onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
          />
        </Grid.Col>
      </Grid.Col>
      <UserForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        userToEdit={userToEdit}
      />
    </Grid>
  )
}

UserList.propTypes = {
  q: PropTypes.string
}

UserList.defaultProps = {
  q: ''
}

export default UserList
