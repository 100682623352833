// @libs
import { writeFile } from 'xlsx'
import PropTypes from 'prop-types'
import { FaRegFileExcel } from 'react-icons/fa'
// @mantine-ui
import { Button } from '@mantine/core'
// @formats
import formats from './Formats'
import ExcelIconButton from '../Buttons/ExcelIconButton'

const fileExtension = '.xlsx'

function Excel({ data, isFullButton, fileName, formatName, title, ...props }) {
  const exportToCSV = () => {
    writeFile(formats[formatName](), fileName + fileExtension)
  }

  return isFullButton ? (
    <Button
      leftIcon={<FaRegFileExcel />}
      onClick={() => exportToCSV()}
      {...props}
    >
      {title}
    </Button>
  ) : (
    <ExcelIconButton onClick={() => exportToCSV()} />
  )
}

Excel.propTypes = {
  data: PropTypes.string,
  fileName: PropTypes.string,
  formatName: PropTypes.string,
  title: PropTypes.string,
  isFullButton: PropTypes.bool
}

Excel.defaultProps = {
  data: null,
  fileName: 'download',
  formatName: 'defaultFormat',
  title: 'Descargar',
  isFullButton: true
}

export default Excel
