// @libs
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
// @components
import {
  Card,
  Image,
  Text,
  Button,
  Divider,
  Group,
  Checkbox,
  LoadingOverlay
} from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import { openBasicModal } from '../../../utils/alerts'
import axiosInstance from '../../../helpers/axios'
import { setProductsToCompare } from '../../../store/cotizationSlice'
import FidEmailVerificationModal from './FidEmailVerificationModal'

const pesosLocale = Intl.NumberFormat('es')
const MAX_INSURANCE_TO_COMPARE = 4

function InsuranceCard({ insurance }) {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { productsToCompare, apiResponse } = useSelector(
    (state) => state.cotization
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { uniqueTupleId } = useParams()

  const handleProductToCompare = (e) => {
    const {
      currentTarget: { checked }
    } = e
    if (checked) {
      dispatch(setProductsToCompare([...productsToCompare, insurance]))
    } else {
      const filtered = productsToCompare.filter(
        (item) => item.nombrePlan !== insurance.nombrePlan
      )
      dispatch(setProductsToCompare(filtered))
    }
  }

  const handleContract = () => {
    openBasicModal({
      title: `Contratar seguro ${insurance.nombrePlan}`,
      content: `¿Estás seguro que quieres contratar la poliza ${insurance.nombrePlan}?. Un agente de Unidad Seguros se pondra en contacto contigo`,
      onConfirm: async () => {
        try {
          setIsLoading(true)
          if (insurance.type === 'FID') {
            await axiosInstance.post(
              `/client-types-has-insurance-types/fid_email_verification`,
              {
                dealId: insurance.fidDealID,
                itemId: insurance.fidItemId,
                quotationId: insurance.quotationId
              }
            )
            setOpen(true)
          }

          if (insurance.type === 'SURA') {
            await axiosInstance.post(
              `/client-types-has-insurance-types/sura_cotization`,
              {
                cotizacionServicioID:
                  apiResponse.suraResponse.DatosTransaccionBaseResponse
                    .cotizacionServicioID,
                nombrePlan: insurance.nombrePlan
              }
            )
            navigate(`/cotiza/${uniqueTupleId}/exito`)
          }
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    })
  }

  return (
    <Card shadow="xl" radius="xl" withBorder>
      <Card.Section
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
        pt={16}
      >
        <Text fw={700} lh={1} fz={40} sx={{ color: 'orange' }}>
          $ {pesosLocale.format(insurance.valorEnPeso)}
        </Text>
        <Text fz={15} lh={2} sx={{ color: '#E24515' }}>
          UF {insurance.valorEnUf}
          <Divider size="lg" sx={{ color: '#767689', fontSize: 12 }} />
        </Text>
      </Card.Section>
      <Card.Section
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Image
          src={insurance.image}
          width={100}
          alt="Norway"
          fit="contain"
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        />
      </Card.Section>
      <Card.Section
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Text>
          Producto: <strong>{insurance.producto}</strong>
        </Text>
        <Text>
          Plan: <strong>{insurance.nombrePlan}</strong>
        </Text>
        <Text>
          Deducible: <strong>{insurance.deducible}</strong>
        </Text>
      </Card.Section>
      <Card.Section
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Button color="orange" mt="md" radius="md" onClick={handleContract}>
          Comprar
        </Button>
      </Card.Section>
      <Card.Section pb={24}>
        <Group position="center" mt="md" mb="xs">
          <Checkbox
            label="Comparar"
            checked={productsToCompare.some(
              (item) => item.nombrePlan === insurance.nombrePlan
            )}
            onChange={handleProductToCompare}
            disabled={
              !productsToCompare.some(
                (item) => item.nombrePlan === insurance.nombrePlan
              ) && productsToCompare.length === MAX_INSURANCE_TO_COMPARE
            }
          />
        </Group>
      </Card.Section>
      <FidEmailVerificationModal
        open={open}
        handleClose={() => setOpen(false)}
        insurance={insurance}
      />
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
    </Card>
  )
}

InsuranceCard.propTypes = {
  insurance: PropTypes.object
}

InsuranceCard.defaultProps = {
  insurance: null
}

export default InsuranceCard
