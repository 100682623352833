// @libs
import axios from '../helpers/axios'

const signIn = async (user) => {
  try {
    const { data } = await axios.post('/auth/sign-in', user)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
const signUp = async (newUser) => {
  try {
    const { data } = await axios.post('/auth/sign-up', newUser)
    return data
  } catch (e) {
    return Promise.reject(e.response.data)
  }
}
const signOut = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1000)
  })
}
const forgotPassword = async (email) => {
  try {
    const { data } = await axios.post('/auth/forgot-password', email)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
const resetPassword = async (resetData) => {
  try {
    const { data } = await axios.post('/auth/reset-password', resetData)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const AuthService = {
  signIn,
  signUp,
  signOut,
  forgotPassword,
  resetPassword
}

export default AuthService
