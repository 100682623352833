// @libs
import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
// @routes
import Router from './routes'
// @hooks
import { useAuth } from './shared/hooks/useAuth'
// @components
import FullPageLoader from './shared/components/FullPageLoader'

function App() {
  const { user, signOut } = useAuth()
  return (
    <HelmetProvider>
      {signOut.isLoading && <FullPageLoader />}
      <Suspense fallback={<FullPageLoader />}>
        <Router user={user} />
      </Suspense>
    </HelmetProvider>
  )
}

export default App
