// @libs
import { useState } from 'react'
// @mantine-ui
import { Box, Button, Grid } from '@mantine/core'
// @constants
import { PAGE_TITLE } from '../constants'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import AgentList from '../components/AgentList'
import AgentForm from '../components/AgentForm'
import SearchInput from '../../../shared/components/Inputs/SearchInput'

function Agents() {
  const [showForm, setShowForm] = useState(false)
  return (
    <Page title={PAGE_TITLE}>
      <Grid>
        <Grid.Col xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <PageTitle title={PAGE_TITLE} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <SearchInput />
              <Button
                sx={{ marginLeft: 16 }}
                onClick={() => setShowForm((prev) => !prev)}
              >
                Agregar nuevo agente
              </Button>
            </Box>
            <AgentForm
              show={showForm}
              handleShow={() => setShowForm((prev) => !prev)}
            />
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} mt={16}>
          <AgentList />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default Agents
