// @libs
import dayjs from 'dayjs'
import { useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import Input from 'react-phone-number-input/input'
// @mantine-ui
import {
  Button,
  Grid,
  TextInput,
  Box,
  Select,
  Text,
  Checkbox,
  Input as MantineInput,
  ScrollArea
} from '@mantine/core'
// @validations
import userSchema from '../validations'
// @hooks
import useUserMutation from '../hooks/useUserMutation'
import useGetAll from '../../../shared/hooks/useFetchAll'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'
import BasicLoader from '../../../shared/components/BasicLoader'
import DatePickerBase from '../../../shared/components/DatePickers/DatePickerBase'
import AgentInsurances from './AgentInsurances'
import ErrorAlert from '../../../shared/components/ErrorAlert'

function UserForm({ show, handleShow, userToEdit }) {
  const { addUser, updateUser } = useUserMutation()
  const [selectAllInsurances, setSelectAllInsurances] = useState(false)

  const formik = useFormik({
    initialValues: {
      is_password_required: false,
      is_agent: !!userToEdit?.agent,
      rut: userToEdit ? userToEdit.rut : '',
      name: userToEdit ? userToEdit.name : '',
      last_name: userToEdit ? userToEdit.last_name : '',
      email: userToEdit ? userToEdit.email : '',
      is_active: userToEdit ? userToEdit.is_active : true,
      role_id: userToEdit?.role_id || '',
      password: '',
      birth_date: userToEdit?.birth_date
        ? dayjs(userToEdit?.birth_date).toDate()
        : '',
      discharge_date: userToEdit?.agent?.discharge_date
        ? dayjs(userToEdit?.agent?.discharge_date).toDate()
        : '',
      phone: userToEdit?.agent?.phone || '',
      address: userToEdit?.agent?.address || '',
      city: userToEdit?.agent?.city || '',
      commune: userToEdit?.agent?.commune || '',
      insurance_type_has_agents:
        userToEdit?.agent?.insurance_type_has_agents || []
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit: (data, { resetForm }) => {
      if (userToEdit) {
        return updateUser
          .mutateAsync([userToEdit.id, { ...userToEdit, ...data }])
          .then(() => {
            handleShow()
            resetForm()
          })
      }
      return addUser.mutateAsync(data).then(() => {
        handleShow()
        resetForm()
      })
    }
  })

  const roles = useGetAll({
    key: 'roles',
    filters: {
      is_active: true,
      limit: 'all'
    },
    customConfig: {
      enabled: show,
      refetchOnMount: false
    }
  })

  const uniqueTuples = useGetAll({
    key: 'client-types-has-insurance-types',
    filters: {
      limit: 'all'
    },
    customConfig: {
      enabled: show && formik.values.is_agent,
      refetchOnMount: true
    }
  })

  const handleClose = () => {
    setSelectAllInsurances(false)
    formik.resetForm()
    handleShow()
  }

  const handleRole = (roleId) => {
    const role = roles.data.data.find(
      (currentRole) => currentRole.id === roleId
    )
    const hasAgentPermissions = role.permissions.some((rp) =>
      rp.permission.action.includes('Estadísticas:')
    )

    formik.setFieldValue('role_id', role.id)
    formik.setFieldValue('is_agent', hasAgentPermissions)
  }
  const handleSelectAll = (checked) => {
    if (checked) {
      const alreadyIncludedComissions =
        formik.values.insurance_type_has_agents.map(
          (item) => item.client_type_has_insurance_type_id
        )

      const updatedComissionArray = uniqueTuples.data.map((item) => ({
        hiring_percentage: 0,
        renovation_percentage: 0,
        client_type_has_insurance_type_id: item.id
      }))

      const uniqueIncluded = updatedComissionArray.filter(
        (item) =>
          !alreadyIncludedComissions.includes(
            item.client_type_has_insurance_type_id
          )
      )

      formik.setFieldValue('insurance_type_has_agents', [
        ...formik.values.insurance_type_has_agents,
        ...uniqueIncluded
      ])
    } else {
      formik.setFieldValue('insurance_type_has_agents', [])
    }
    setSelectAllInsurances(checked)
  }

  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Edita o agrega un usuario aquí"
    >
      {roles.isLoading ? (
        <BasicLoader />
      ) : (
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid.Col xs={12}>
              <Grid
                sx={{
                  backgroundColor: '#f4f4f5',
                  borderRadius: 8,
                  padding: 8
                }}
              >
                <Grid.Col xs={12}>
                  <Text fw={700}>Información de Usuario</Text>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <TextInput
                    placeholder="Rut *"
                    name="rut"
                    onChange={formik.handleChange}
                    value={formik.values.rut}
                    error={formik.errors.rut}
                  />
                  <SwitchBase
                    label="Activo"
                    name="is_active"
                    checked={formik.values.is_active}
                    onChange={formik.handleChange}
                    value={formik.values.is_active}
                    error={formik.errors.is_active}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="Nombre *"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors.name}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <TextInput
                    placeholder="Apellido *"
                    name="last_name"
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                    error={formik.errors.last_name}
                  />
                </Grid.Col>
                <Grid.Col xs={12}>
                  <TextInput
                    placeholder="Correo *"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                  />
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Select
                    name="role_id"
                    value={formik.values.role_id}
                    clearable
                    placeholder="Perfil *"
                    nothingFound="Sin resultados"
                    data={
                      roles?.data?.data
                        ? roles.data.data.map(
                            (role) =>
                              role && {
                                ...role,
                                value: role.id,
                                label: role.name
                              }
                          )
                        : []
                    }
                    onChange={(roleId) => handleRole(roleId)}
                    error={formik.errors.role_id}
                  />
                </Grid.Col>
                <Grid.Col xs={12}>
                  <DatePickerBase
                    placeholder="Fecha de nacimiento"
                    name="birth_date"
                    maxDate={dayjs(new Date()).toDate()}
                    value={formik.values.birth_date}
                    onChange={(newValue) => {
                      formik.setFieldValue('birth_date', newValue)
                    }}
                    error={formik.errors.birth_date}
                  />
                </Grid.Col>
                {userToEdit && (
                  <Grid.Col xs={12}>
                    <Checkbox
                      checked={formik.values.is_password_required}
                      name="is_password_required"
                      onChange={formik.handleChange}
                      label="Restablecer contraseña"
                    />
                  </Grid.Col>
                )}
                {formik.values.is_password_required && (
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder={
                        userToEdit
                          ? 'Nueva contraseña *'
                          : 'Contaseña inicial *'
                      }
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={formik.errors.password}
                    />
                  </Grid.Col>
                )}
              </Grid>
            </Grid.Col>

            {formik.values.is_agent && (
              <Grid.Col xs={12} mt={16}>
                <Grid
                  sx={{
                    backgroundColor: '#f4f4f5',
                    borderRadius: 8,
                    padding: 8
                  }}
                >
                  <Grid.Col xs={12}>
                    <Text fw={700}>Información de Agente</Text>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Input
                      placeholder="Teléfono *"
                      value={formik.values.phone}
                      onChange={(newValue) =>
                        formik.setFieldValue('phone', newValue)
                      }
                      inputComponent={MantineInput}
                      error={formik.errors.phone}
                    />
                    {formik.errors.phone && (
                      <Text fz="xs" c="red">
                        {formik.errors.phone}
                      </Text>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <DatePickerBase
                      placeholder="Fecha de alta *"
                      name="discharge_date"
                      maxDate={dayjs(new Date()).toDate()}
                      value={formik.values.discharge_date}
                      onChange={(newValue) => {
                        formik.setFieldValue('discharge_date', newValue)
                      }}
                      error={formik.errors.discharge_date}
                    />
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <TextInput
                      placeholder="Ciudad *"
                      name="city"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      error={formik.errors.city}
                    />
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <TextInput
                      placeholder="Comuna *"
                      name="commune"
                      onChange={formik.handleChange}
                      value={formik.values.commune}
                      error={formik.errors.commune}
                    />
                  </Grid.Col>

                  {uniqueTuples.isLoading && (
                    <Grid.Col xs={12}>
                      <BasicLoader />
                    </Grid.Col>
                  )}
                  {uniqueTuples.isError && (
                    <Grid.Col xs={12}>
                      <ErrorAlert message="No fue posible descargar la información de los seguros" />
                    </Grid.Col>
                  )}

                  {!uniqueTuples.isLoading && !uniqueTuples.isError && (
                    <>
                      <Grid.Col
                        xs={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Text fw={700}>Tipos de Seguros</Text>
                        <Checkbox
                          label="Todos"
                          checked={selectAllInsurances}
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                      </Grid.Col>
                      <ScrollArea style={{ maxHeight: 300 }}>
                        {uniqueTuples?.data.map((uniqueTuple) => (
                          <Grid.Col xs={12} key={uniqueTuple.id}>
                            <Grid>
                              <Grid.Col xs={12}>
                                <AgentInsurances
                                  uniqueTuple={uniqueTuple}
                                  formik={formik}
                                  onChange={formik.handleChange}
                                />
                              </Grid.Col>
                            </Grid>
                          </Grid.Col>
                        ))}
                      </ScrollArea>
                    </>
                  )}
                </Grid>
              </Grid.Col>
            )}

            <Grid.Col span={12} mt={16}>
              <Button
                fullWidth
                loading={addUser.isLoading || updateUser.isLoading}
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

UserForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  userToEdit: PropTypes.object
}

UserForm.defaultProps = {
  show: false,
  handleShow: () => {},
  userToEdit: null
}

export default UserForm
