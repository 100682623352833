// @mantine-ui
import { Grid } from '@mantine/core'
// @components
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import InsuranceSettingList from '../components/InsuranceSettingList'

function InsuranceSettings() {
  return (
    <Page title="Configuración de Seguros">
      <Grid>
        <Grid.Col span={12}>
          <PageTitle title="Configurador Genérico de Seguros" />
        </Grid.Col>
        <Grid.Col span={12} mt={16}>
          <InsuranceSettingList />
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default InsuranceSettings
