// @libs
import PropTypes from 'prop-types'
import _ from 'lodash'
// @mantine-ui
import { Grid, Text } from '@mantine/core'
// @hooks
import useFetchOne from '../../../shared/hooks/useFetchOne'
// @components
import InsuranceCompanyDetailsItem from './InsuranceCompanyDetailsItem'
import ErrorPage from '../../../shared/components/ErrorPage'
import BasicLoader from '../../../shared/components/BasicLoader'

function InsuranceCompanyDetails({ isOpen, companyId }) {
  const {
    data: company,
    isLoading,
    isError
  } = useFetchOne(['insurance-companies', companyId], {
    enabled: isOpen && !!companyId
  })

  if (isError) return <ErrorPage code={500} />
  if (isLoading) return <BasicLoader />
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Text
          sx={(theme) => ({
            fontWeight: 700,
            fontSize: 24,
            lineHeight: '31px',
            letterSpacing: '0.04em',
            opacity: 0.8,
            [theme.fn.smallerThan('sm')]: {
              fontSize: 16,
              lineHeight: '26px'
            }
          })}
        >
          Detalles de la compañía
        </Text>
      </Grid.Col>
      <Grid.Col xs={6} sm={4}>
        <Text
          sx={(theme) => ({
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8,
            [theme.fn.smallerThan('sm')]: {
              fontSize: 16,
              lineHeight: '22px'
            }
          })}
        >
          Rut: {company.rut}
        </Text>
      </Grid.Col>
      <Grid.Col xs={6} sm={4}>
        <Text
          sx={(theme) => ({
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8,
            [theme.fn.smallerThan('sm')]: {
              fontSize: 16,
              lineHeight: '22px'
            }
          })}
        >
          Código: {company.code}
        </Text>
      </Grid.Col>
      <Grid.Col xs={6} sm={4}>
        <Text
          sx={(theme) => ({
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8,
            [theme.fn.smallerThan('sm')]: {
              fontSize: 16,
              lineHeight: '22px'
            }
          })}
        >
          Clasificación de Riesgo: {company?.risk_classification?.name}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Text
          sx={(theme) => ({
            fontWeight: 700,
            fontSize: 24,
            lineHeight: '31px',
            letterSpacing: '0.04em',
            opacity: 0.8,
            [theme.fn.smallerThan('sm')]: {
              fontSize: 16,
              lineHeight: '26px'
            }
          })}
        >
          Tipos de Seguros
        </Text>
      </Grid.Col>
      {Object.entries(
        _.groupBy(
          company.insurance_types_has_insurance_companies,
          'client_type_has_insurance_type.clientTypeId'
        )
      ).map(([, insurances]) => (
        <Grid.Col xs={12} key={insurances[0].id}>
          <InsuranceCompanyDetailsItem insurances={insurances} />
        </Grid.Col>
      ))}
    </Grid>
  )
}
InsuranceCompanyDetails.propTypes = {
  isOpen: PropTypes.bool,
  companyId: PropTypes.number
}

InsuranceCompanyDetails.defaultProps = {
  isOpen: false,
  companyId: null
}
export default InsuranceCompanyDetails
