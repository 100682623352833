// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { SegmentedControl } from '@mantine/core'

function RowsCount({ total, value, onChange, ...props }) {
  return (
    <SegmentedControl
      color="orange"
      sx={{ backgroundColor: '#fff', border: '1px solid lightgray' }}
      value={value}
      onChange={onChange}
      data={[
        { label: '5', value: '5' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
        { label: 'todas', value: 'all' }
      ]}
      {...props}
    />
  )
}

RowsCount.propTypes = {
  total: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func
}
RowsCount.defaultProps = {
  total: 1,
  value: 1,
  onChange: () => {}
}

export default RowsCount
