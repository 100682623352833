// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Grid, Text } from '@mantine/core'
import { dateFormat } from '../../../utils/formats'

function AgentDetails({ agent }) {
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Text
          sx={{
            fontWeight: 700,
            fontSize: 24,
            lineHeight: '31px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Detalles del Agente
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={2.4}>
        <Text
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Dirección: {agent?.address}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={2.4}>
        <Text
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Comuna: {agent?.city}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={2.4}>
        <Text
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Región: {agent?.commune}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={2.4}>
        <Text
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Fecha de nacimiento: {dateFormat(agent?.user?.birth_date)}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={2.4}>
        <Text
          sx={{
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Fecha de alta: {dateFormat(agent?.discharge_date)}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Text
          sx={{
            fontWeight: 700,
            fontSize: 24,
            lineHeight: '31px',
            letterSpacing: '0.04em',
            opacity: 0.8
          }}
        >
          Tipos de Seguros
        </Text>
      </Grid.Col>
    </Grid>
  )
}
AgentDetails.propTypes = {
  agent: PropTypes.object
}

AgentDetails.defaultProps = {
  agent: {}
}
export default AgentDetails
