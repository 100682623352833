// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import InsuranceCompanies from './pages/insurance-companies'

const insuranceCompaniesRoutes = [
  {
    path: '/insurance-companies',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.INSURANCES_COMPANIES}
        action="VIEW"
        yes={() => <InsuranceCompanies />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default insuranceCompaniesRoutes
