// @libs
import { useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useFormik } from 'formik'
import {
  RiDragMove2Fill,
  RiDeleteBinFill,
  RiArrowDownCircleFill,
  RiAddCircleLine
} from 'react-icons/ri'
import { FcPlus } from 'react-icons/fc'
// @mantine-ui
import {
  Grid,
  Group,
  Select,
  Box,
  Stack,
  Text,
  Button,
  TextInput,
  Textarea,
  ActionIcon,
  Paper,
  Checkbox,
  Collapse,
  NumberInput,
  Switch,
  Affix,
  Transition
} from '@mantine/core'
import { useWindowScroll } from '@mantine/hooks'
// @components
import useFormsMutation from '../hooks/useFormsMutation'
import BasicLoader from '../../../shared/components/BasicLoader'
import fetchAll from '../../../shared/hooks/useFetchAll'
import Page from '../../../shared/components/Page'
import PageTitle from '../../../shared/components/PageTitle'
import OptionsList from '../components/OptionsList'
import PreviewFormModal from '../components/PreviewFormModal'
import FormsOrderModal from '../components/FormsOrderModal'
import { openDeleteModal } from '../../../utils/alerts'

const typesDic = {
  TEXT: 'Texto',
  ATTRIBUTES: 'Atributos'
}

let uniqueIdCount = 0

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function Forms() {
  const { addForms, removeForms, updateForms } = useFormsMutation()
  const [filterData, setFilterData] = useState({
    clientType: null,
    insuranceType: null,
    formSelected: null
  })
  const [insuranceTypes, setInsuranceTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTuple, setSelectedTuple] = useState(null)
  const [collapseOpen, setCollapseOpen] = useState({})
  const [optionFormData, setOptionFormData] = useState({})
  const [scroll] = useWindowScroll()

  const formik = useFormik({
    initialValues: {
      name: '',
      is_api_filled: false,
      deletedSections: [],
      delectedSectionItems: [],
      deletedSectionListItems: [],
      formSections: []
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)
        if (filterData.formSelected && filterData.formSelected !== 'NEW_FORM') {
          await updateForms.mutateAsync([filterData.formSelected, values])
        } else {
          const dataToSubmit = {
            ...values,
            clientType_has_insuranceType_id: selectedTuple.id,
            order: selectedTuple?.forms?.length
          }
          await addForms.mutateAsync(dataToSubmit)
          resetForm()
        }
        setIsLoading(false)
      } catch {
        setIsLoading(false)
      }
    }
  })

  const { data: clientTypesData, isLoading: clientTypeLoading } = fetchAll({
    key: 'client-types',
    filters: {
      limit: 'all',
      is_active: true,
      insurance_type: true
    },
    customConfig: {
      refetchOnMount: true
    }
  })

  const { data: uniqueTuples, isLoading: uniqueTupleLoading } = fetchAll({
    key: 'client-types-has-insurance-types',
    filters: {
      page: 1,
      limit: 'all',
      onlyConfigured: true
    },
    customConfig: {
      refetchOnMount: true
    }
  })

  const { data: insuranceCompanies } = fetchAll({
    key: 'insurance-companies',
    filters: {
      limit: 'all'
    },
    customConfig: {
      refetchOnMount: true
    }
  })

  const handleSectionCollapse = (id) => {
    setCollapseOpen({ ...collapseOpen, [id]: !collapseOpen[id] })
  }

  const addNewSection = (type) => {
    const newSection = {
      id: uniqueIdCount,
      type,
      title: '',
      description: ''
    }
    if (type === 'ATTRIBUTES') {
      newSection.formSectionItem = []
    }
    formik.setFieldValue('formSections', [
      ...formik.values.formSections,
      newSection
    ])

    uniqueIdCount += 1
  }

  const addNewSectionItem = (sectionId) => {
    const newSection = {
      id: uniqueIdCount,
      text: '',
      type: '',
      formSectionListItem: [],
      attribute_id: '',
      is_required: false,
      is_multiple: false,
      col_span: 12
    }
    const newData = formik.values.formSections.map((item) =>
      item.id === sectionId
        ? { ...item, formSectionItem: [...item.formSectionItem, newSection] }
        : item
    )
    formik.setFieldValue('formSections', newData)

    uniqueIdCount += 1
  }

  const removeSectionItem = (sectionId, sectionItemId) => {
    const newData = formik.values.formSections.map((item) =>
      item.id === sectionId
        ? {
            ...item,
            formSectionItem: item.formSectionItem.filter(
              (sectionItem) => sectionItem.id !== sectionItemId
            )
          }
        : item
    )
    if (filterData.formSelected && filterData.formSelected !== 'NEW_FORM') {
      formik.setFieldValue('delectedSectionItems', [
        ...formik.values.delectedSectionItems,
        sectionItemId
      ])
    }
    formik.setFieldValue('formSections', newData)
  }

  const removeSection = (sectionId) => {
    const filtered = formik.values.formSections.filter(
      (item) => item.id !== sectionId
    )
    if (filterData.formSelected && filterData.formSelected !== 'NEW_FORM') {
      formik.setFieldValue('deletedSections', [
        ...formik.values.deletedSections,
        sectionId
      ])
    }
    formik.setFieldValue('formSections', filtered)
  }

  const onSectionDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      formik.values.formSections,
      result.source.index,
      result.destination.index
    )
    formik.setFieldValue('formSections', items)
  }

  const onSectionItemDragEnd = (result, sectionId) => {
    if (!result.destination) {
      return
    }

    const newData = formik.values.formSections.map((item) =>
      item.id === sectionId
        ? {
            ...item,
            formSectionItem: reorder(
              item.formSectionItem,
              result.source.index,
              result.destination.index
            )
          }
        : item
    )
    formik.setFieldValue('formSections', newData)
  }

  const handleAttributeSelectChange = (fieldName, value, sectionId) => {
    const selectedAttr = selectedTuple.insuranceTypesHasAttributes.find(
      (attr) => attr.attributes_id === +value
    )

    if (selectedAttr.attributes.childAttribute) {
      const newSection = {
        id: uniqueIdCount,
        text: '',
        type: selectedAttr.attributes.childAttribute.type,
        formSectionListItem: [],
        attribute_id: selectedAttr.attributes.childAttribute.id,
        is_required: false,
        is_multiple: false,
        col_span: 12,
        attribute: selectedAttr.attributes.childAttribute
      }
      const newData = formik.values.formSections.map((item) =>
        item.id === sectionId
          ? { ...item, formSectionItem: [...item.formSectionItem, newSection] }
          : item
      )
      formik.setFieldValue('formSections', newData)

      uniqueIdCount += 1
    }
    formik.setFieldValue(`${fieldName}.attribute_id`, value)
    formik.setFieldValue(`${fieldName}.type`, selectedAttr.attributes.type)
    formik.setFieldValue(`${fieldName}.attribute`, selectedAttr.attributes)
  }

  const addSectionOptionItem = (sectionId, sectionItemId) => {
    const newData = formik.values.formSections.map((item) =>
      item.id === sectionId
        ? {
            ...item,
            formSectionItem: item.formSectionItem.map((sectionItem) =>
              sectionItem.id === sectionItemId
                ? {
                    ...sectionItem,
                    formSectionListItem: [
                      ...sectionItem.formSectionListItem,
                      { ...optionFormData[sectionItemId], id: uniqueIdCount }
                    ]
                  }
                : sectionItem
            )
          }
        : item
    )
    formik.setFieldValue('formSections', newData)
    setOptionFormData({
      ...optionFormData,
      [sectionItemId]: null
    })
    uniqueIdCount += 1
  }

  const removeSectionOptionItem = (sectionId, sectionItemId, optionId) => {
    const newData = formik.values.formSections.map((item) =>
      item.id === sectionId
        ? {
            ...item,
            formSectionItem: item.formSectionItem.map((sectionItem) =>
              sectionItem.id === sectionItemId
                ? {
                    ...sectionItem,
                    formSectionListItem: sectionItem.formSectionListItem.filter(
                      (option) => option.id !== optionId
                    )
                  }
                : sectionItem
            )
          }
        : item
    )
    if (filterData.formSelected && filterData.formSelected !== 'NEW_FORM') {
      formik.setFieldValue('deletedSectionListItems', [
        ...formik.values.deletedSectionListItems,
        optionId
      ])
    }
    formik.setFieldValue('formSections', newData)
  }

  const handleEditOption = (sectionId, sectionItemId, optionData) => {
    setOptionFormData({
      ...optionFormData,
      [sectionItemId]: { ...optionData }
    })
    removeSectionOptionItem(sectionId, sectionItemId, optionData.id)
  }

  const onOptionItemDragEnd = (result, sectionId, sectionItemId) => {
    if (!result.destination) {
      return
    }

    const newData = formik.values.formSections.map((section) =>
      section.id === sectionId
        ? {
            ...section,
            formSectionItem: section.formSectionItem.map((sectionItem) =>
              sectionItem.id === sectionItemId
                ? {
                    ...sectionItem,
                    formSectionListItem: reorder(
                      sectionItem.formSectionListItem,
                      result.source.index,
                      result.destination.index
                    )
                  }
                : sectionItem
            )
          }
        : section
    )

    formik.setFieldValue('formSections', newData)
  }

  const handleDelete = () =>
    openDeleteModal({
      title: 'Eliminar Atributo',
      content: `¿Estás seguro de eliminar el formulario actual?.`,
      onConfirm: async () => {
        await removeForms.mutateAsync(filterData.formSelected)

        formik.resetForm()

        setSelectedTuple((prev) => ({
          ...prev,
          forms: prev.forms.filter(
            (form) => form.id !== filterData.formSelected
          )
        }))

        setFilterData((prev) => ({ ...prev, formSelected: 'NEW_FORM' }))
      }
    })

  const forms = useMemo(() => {
    if (uniqueTuples) {
      const foundTouple = uniqueTuples.find(
        (touple) => touple.id === selectedTuple?.id
      )

      return foundTouple ? foundTouple.forms : []
    }

    return []
  }, [selectedTuple, uniqueTuples])

  if (clientTypeLoading || uniqueTupleLoading) return <BasicLoader />

  return (
    <Page title="Formularios de Seguros">
      <Grid>
        <Grid.Col xs={12}>
          <Group>
            <PageTitle title="Formularios de Seguros" />
            <Select
              variant="filled"
              clearable
              value={filterData.clientType}
              onChange={(clientTypeValue) => {
                setFilterData({ ...filterData, clientType: clientTypeValue })
                const insuranceTypesData = clientTypesData?.data.find(
                  (item) => item.id === +clientTypeValue
                ).insuranceTypes
                const filtered = insuranceTypesData.filter(
                  // eslint-disable-next-line no-underscore-dangle
                  (item) => item._count.insuranceTypesHasAttributes > 0
                )
                setInsuranceTypes(filtered)
              }}
              placeholder="Tipo de cliente*"
              nothingFound="Sin resultados"
              data={
                clientTypesData?.data
                  ? clientTypesData?.data?.map((tc) => ({
                      ...tc,
                      label: tc.name,
                      value: tc.id
                    }))
                  : []
              }
            />
            <Select
              variant="filled"
              clearable
              placeholder="Tipo de seguro*"
              nothingFound="Sin resultados"
              value={filterData.insuranceType}
              onChange={(insuranceTypeValue) => {
                setFilterData({
                  ...filterData,
                  insuranceType: insuranceTypeValue
                })
                const foundItem = uniqueTuples.find(
                  (item) => item.id === +insuranceTypeValue
                )
                setSelectedTuple(foundItem)
              }}
              data={insuranceTypes.map((it) => ({
                ...it,
                label: it.insuranceType.name,
                value: it.id
              }))}
            />
            {selectedTuple && (
              <>
                <Select
                  variant="filled"
                  clearable
                  placeholder="Formularios"
                  nothingFound="Sin resultados"
                  value={filterData.formSelected}
                  onChange={(form) => {
                    formik.resetForm()
                    if (Number(form)) {
                      const foundItem = forms.find((item) => item.id === +form)
                      formik.setFieldValue('name', foundItem.name)
                      formik.setFieldValue(
                        'is_api_filled',
                        foundItem.is_api_filled
                      )
                      formik.setFieldValue(
                        'formSections',
                        foundItem.formSections
                      )
                    }
                    setFilterData({
                      ...filterData,
                      formSelected: form
                    })
                  }}
                  data={[
                    { label: 'Agregar nuevo formulario', value: 'NEW_FORM' },
                    ...forms.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                  ]}
                />
                <FormsOrderModal uniqueTupleId={selectedTuple.id} />
              </>
            )}
          </Group>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Switch
            disabled={!selectedTuple}
            checked={formik.values.is_api_filled}
            onChange={(event) =>
              formik.setFieldValue('is_api_filled', event.currentTarget.checked)
            }
            label="Habilitar llenado mediante API"
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <Box
            sx={(theme) => ({
              borderRadius: theme.radius.md,
              padding: theme.spacing.sm,
              backgroundColor: '#D9D9D9'
            })}
          >
            <Stack>
              <Text
                sx={{
                  fontFamily: 'Assistant',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: '24px',
                  letterSpacing: '0.04em',
                  color: '#1E1C3E'
                }}
              >
                Constructor de formularios
              </Text>
              <TextInput
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                variant="filled"
                placeholder="Nombre de sección"
              />
              <Group position="apart">
                <Group spacing="xl">
                  <Stack>
                    <Text
                      sx={{
                        fontFamily: 'Assistant',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.04em',
                        color: '#1E1C3E'
                      }}
                    >
                      Secciones
                    </Text>
                    <Text c="dimmed">Agregar secciones</Text>
                  </Stack>
                  <Button
                    onClick={() => addNewSection('TEXT')}
                    disabled={!selectedTuple}
                  >
                    Texto
                  </Button>
                  <Button
                    onClick={() => addNewSection('ATTRIBUTES')}
                    disabled={!selectedTuple}
                  >
                    Atributos
                  </Button>
                </Group>
                <PreviewFormModal
                  formik={formik}
                  isLoading={isLoading}
                  selectedTuple={selectedTuple}
                />
              </Group>
              <DragDropContext onDragEnd={onSectionDragEnd}>
                <Droppable droppableId="formSections">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {formik.values.formSections.map(
                        (section, sectionIndex) => (
                          <Draggable
                            key={section.id}
                            draggableId={String(section.id)}
                            index={sectionIndex}
                          >
                            {(draggableProvided) => (
                              <Box
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                sx={(theme) => ({
                                  borderRadius: theme.radius.md,
                                  padding: theme.spacing.sm,
                                  marginBottom: theme.spacing.sm,
                                  backgroundColor: '#A9A9A9'
                                })}
                              >
                                <Grid align="center">
                                  <Grid.Col xs={0.5}>
                                    <Text>Titulo:</Text>
                                  </Grid.Col>
                                  <Grid.Col xs={5.5}>
                                    <TextInput
                                      fullWidth
                                      name={`formSections.${sectionIndex}.title`}
                                      value={
                                        formik.values.formSections[sectionIndex]
                                          .title
                                      }
                                      onChange={formik.handleChange}
                                      variant="filled"
                                      placeholder="Titulo"
                                    />
                                  </Grid.Col>
                                  <Grid.Col xs={2}>
                                    <Text>#id: {sectionIndex}</Text>
                                  </Grid.Col>
                                  <Grid.Col xs={2}>
                                    <Text>
                                      Tipo:{' '}
                                      {
                                        typesDic[
                                          formik.values.formSections[
                                            sectionIndex
                                          ].type
                                        ]
                                      }
                                    </Text>
                                  </Grid.Col>
                                  <Grid.Col xs={2}>
                                    <Group spacing="xs" position="right">
                                      <ActionIcon
                                        color="dark"
                                        variant="transparent"
                                        onClick={() =>
                                          handleSectionCollapse(section.id)
                                        }
                                      >
                                        <RiArrowDownCircleFill />
                                      </ActionIcon>
                                      <ActionIcon
                                        color="dark"
                                        variant="transparent"
                                        onClick={() =>
                                          removeSection(section.id)
                                        }
                                      >
                                        <RiDeleteBinFill />
                                      </ActionIcon>
                                      <ActionIcon
                                        color="dark"
                                        variant="transparent"
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        <RiDragMove2Fill />
                                      </ActionIcon>
                                    </Group>
                                  </Grid.Col>
                                </Grid>
                                <Collapse in={!collapseOpen[section.id]}>
                                  <Textarea
                                    variant="filled"
                                    name={`formSections.${sectionIndex}.description`}
                                    value={
                                      formik.values.formSections[sectionIndex]
                                        .description
                                    }
                                    onChange={formik.handleChange}
                                    placeholder="Descripción"
                                    autosize
                                    minRows={2}
                                    maxRows={4}
                                    sx={{ marginTop: 12 }}
                                  />
                                  {section.type === 'ATTRIBUTES' && (
                                    <Stack>
                                      <ActionIcon
                                        color="dark"
                                        radius="xl"
                                        size="lg"
                                        onClick={() =>
                                          addNewSectionItem(section.id)
                                        }
                                        sx={{ placeSelf: 'end' }}
                                      >
                                        <FcPlus size="1.5em" />
                                      </ActionIcon>
                                      <DragDropContext
                                        onDragEnd={(result) =>
                                          onSectionItemDragEnd(
                                            result,
                                            section.id
                                          )
                                        }
                                      >
                                        <Droppable
                                          droppableId={`formSectionsItem_${section.id}`}
                                        >
                                          {(sectionItemProvided) => (
                                            <div
                                              {...sectionItemProvided.droppableProps}
                                              ref={sectionItemProvided.innerRef}
                                            >
                                              {section.formSectionItem.map(
                                                (
                                                  sectionItem,
                                                  sectionItemIndex
                                                ) => (
                                                  <Draggable
                                                    key={sectionItem.id}
                                                    draggableId={String(
                                                      sectionItem.id
                                                    )}
                                                    index={sectionItemIndex}
                                                  >
                                                    {(
                                                      sectionItemDraggableProvided
                                                    ) => (
                                                      <Paper
                                                        {...sectionItemDraggableProvided.draggableProps}
                                                        ref={
                                                          sectionItemDraggableProvided.innerRef
                                                        }
                                                        sx={(theme) => ({
                                                          borderRadius:
                                                            theme.radius.md,
                                                          padding:
                                                            theme.spacing.sm,
                                                          marginBottom:
                                                            theme.spacing.sm
                                                        })}
                                                      >
                                                        <Grid
                                                          justify="center"
                                                          align="center"
                                                        >
                                                          <Grid.Col xs={1}>
                                                            <Text>
                                                              Atributo:
                                                            </Text>
                                                          </Grid.Col>
                                                          <Grid.Col xs={4}>
                                                            <Select
                                                              variant="filled"
                                                              searchable
                                                              value={
                                                                formik.values
                                                                  .formSections[
                                                                  sectionIndex
                                                                ]
                                                                  .formSectionItem[
                                                                  sectionItemIndex
                                                                ].attribute_id
                                                              }
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                handleAttributeSelectChange(
                                                                  `formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}`,
                                                                  value,
                                                                  section.id
                                                                )
                                                              }
                                                              data={
                                                                selectedTuple
                                                                  ? selectedTuple.insuranceTypesHasAttributes.map(
                                                                      (
                                                                        attr
                                                                      ) => ({
                                                                        value:
                                                                          attr.attributes_id,
                                                                        label: `${attr.attributes?.name} - ${attr.attributes?.type}`
                                                                      })
                                                                    )
                                                                  : []
                                                              }
                                                            />
                                                          </Grid.Col>
                                                          <Grid.Col xs={2}>
                                                            <NumberInput
                                                              label="Ancho de columnas (1 al 12)"
                                                              min={1}
                                                              max={12}
                                                              value={
                                                                formik.values
                                                                  .formSections[
                                                                  sectionIndex
                                                                ]
                                                                  .formSectionItem[
                                                                  sectionItemIndex
                                                                ].col_span
                                                              }
                                                              onChange={(val) =>
                                                                formik.setFieldValue(
                                                                  `formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}.col_span`,
                                                                  val
                                                                )
                                                              }
                                                            />
                                                          </Grid.Col>
                                                          <Grid.Col xs={1}>
                                                            <Text>
                                                              #id:{' '}
                                                              {sectionItemIndex}
                                                            </Text>
                                                          </Grid.Col>
                                                          <Grid.Col xs={2}>
                                                            <Text>
                                                              Tipo:{' '}
                                                              {
                                                                formik.values
                                                                  .formSections[
                                                                  sectionIndex
                                                                ]
                                                                  .formSectionItem[
                                                                  sectionItemIndex
                                                                ].type
                                                              }
                                                            </Text>
                                                          </Grid.Col>
                                                          <Grid.Col xs={2}>
                                                            <Group
                                                              spacing="xs"
                                                              position="right"
                                                            >
                                                              <ActionIcon
                                                                color="orange"
                                                                variant="transparent"
                                                                {...sectionItemDraggableProvided.dragHandleProps}
                                                              >
                                                                <RiDragMove2Fill />
                                                              </ActionIcon>
                                                              <ActionIcon
                                                                color="orange"
                                                                variant="transparent"
                                                                onClick={() =>
                                                                  removeSectionItem(
                                                                    section.id,
                                                                    sectionItem.id
                                                                  )
                                                                }
                                                              >
                                                                <RiDeleteBinFill />
                                                              </ActionIcon>
                                                            </Group>
                                                          </Grid.Col>
                                                        </Grid>
                                                        <Group
                                                          sx={{ marginTop: 12 }}
                                                          position="apart"
                                                        >
                                                          <Text>
                                                            Texto alternativo:
                                                          </Text>
                                                          <TextInput
                                                            name={`formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}.text`}
                                                            value={
                                                              formik.values
                                                                .formSections[
                                                                sectionIndex
                                                              ].formSectionItem[
                                                                sectionItemIndex
                                                              ].text
                                                            }
                                                            onChange={
                                                              formik.handleChange
                                                            }
                                                            variant="filled"
                                                            sx={{
                                                              width: '80%'
                                                            }}
                                                          />
                                                          <Checkbox
                                                            checked={
                                                              formik.values
                                                                .formSections[
                                                                sectionIndex
                                                              ].formSectionItem[
                                                                sectionItemIndex
                                                              ].is_required
                                                            }
                                                            onChange={(event) =>
                                                              formik.setFieldValue(
                                                                `formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}.is_required`,
                                                                event
                                                                  .currentTarget
                                                                  .checked
                                                              )
                                                            }
                                                            label="Obligatoria"
                                                          />
                                                        </Group>
                                                        {formik.values
                                                          .is_api_filled && (
                                                          <Grid.Col xs={12}>
                                                            <Box
                                                              sx={(theme) => ({
                                                                borderRadius:
                                                                  theme.radius
                                                                    .md,
                                                                padding:
                                                                  theme.spacing
                                                                    .sm,
                                                                backgroundColor:
                                                                  '#D9D9D9'
                                                              })}
                                                            >
                                                              <Stack>
                                                                <Box
                                                                  sx={(
                                                                    theme
                                                                  ) => ({
                                                                    backgroundColor:
                                                                      '#D9D9D9',
                                                                    padding:
                                                                      theme
                                                                        .spacing
                                                                        .sm,
                                                                    borderRadius:
                                                                      theme
                                                                        .radius
                                                                        .md
                                                                  })}
                                                                >
                                                                  <Grid>
                                                                    <Grid.Col
                                                                      xs={3}
                                                                    >
                                                                      Servicio
                                                                    </Grid.Col>
                                                                    <Grid.Col
                                                                      xs={4.5}
                                                                    >
                                                                      Tag de
                                                                      origen
                                                                    </Grid.Col>
                                                                    <Grid.Col
                                                                      xs={4.5}
                                                                    >
                                                                      Tag de
                                                                      destino
                                                                    </Grid.Col>
                                                                    <Grid.Col
                                                                      xs={3}
                                                                      sx={{
                                                                        borderRadius:
                                                                          '8px 0px 0px 8px',
                                                                        backgroundColor:
                                                                          'white',
                                                                        marginBottom: 16,
                                                                        display:
                                                                          'flex',
                                                                        alignItems:
                                                                          'center'
                                                                      }}
                                                                      px={8}
                                                                    >
                                                                      <Select
                                                                        clearable
                                                                        value={
                                                                          formik
                                                                            .values
                                                                            .formSections[
                                                                            sectionIndex
                                                                          ]
                                                                            .formSectionItem[
                                                                            sectionItemIndex
                                                                          ]
                                                                            .insurance_company_id
                                                                        }
                                                                        onChange={(
                                                                          value
                                                                        ) =>
                                                                          formik.setFieldValue(
                                                                            `formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}.insurance_company_id`,
                                                                            value
                                                                          )
                                                                        }
                                                                        placeholder="API"
                                                                        data={
                                                                          insuranceCompanies?.data
                                                                            ? insuranceCompanies?.data?.map(
                                                                                (
                                                                                  tc
                                                                                ) => ({
                                                                                  ...tc,
                                                                                  label:
                                                                                    tc.name ||
                                                                                    tc.business_name,
                                                                                  value:
                                                                                    tc.id
                                                                                })
                                                                              )
                                                                            : []
                                                                        }
                                                                      />
                                                                    </Grid.Col>
                                                                    <Grid.Col
                                                                      xs={4.5}
                                                                      sx={{
                                                                        backgroundColor:
                                                                          'white',
                                                                        marginBottom: 16,
                                                                        display:
                                                                          'flex',
                                                                        alignItems:
                                                                          'center'
                                                                      }}
                                                                      px={8}
                                                                    >
                                                                      <Textarea
                                                                        name="origin_tag"
                                                                        autosize
                                                                        placeholder="Ruta origen"
                                                                        sx={{
                                                                          width:
                                                                            '100%'
                                                                        }}
                                                                        value={
                                                                          formik
                                                                            .values
                                                                            .formSections[
                                                                            sectionIndex
                                                                          ]
                                                                            .formSectionItem[
                                                                            sectionItemIndex
                                                                          ]
                                                                            .origin_tag
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) =>
                                                                          formik.setFieldValue(
                                                                            `formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}.origin_tag`,
                                                                            event
                                                                              .currentTarget
                                                                              .value
                                                                          )
                                                                        }
                                                                      />
                                                                    </Grid.Col>
                                                                    <Grid.Col
                                                                      xs={4.5}
                                                                      sx={{
                                                                        borderRadius:
                                                                          '0px 8px 8px 0px',
                                                                        backgroundColor:
                                                                          'white',
                                                                        marginBottom: 16,
                                                                        display:
                                                                          'flex',
                                                                        alignItems:
                                                                          'center'
                                                                      }}
                                                                      px={8}
                                                                    >
                                                                      <Textarea
                                                                        name="target_tag"
                                                                        autosize
                                                                        placeholder="Ruta destino"
                                                                        sx={{
                                                                          width:
                                                                            '100%'
                                                                        }}
                                                                        value={
                                                                          formik
                                                                            .values
                                                                            .formSections[
                                                                            sectionIndex
                                                                          ]
                                                                            .formSectionItem[
                                                                            sectionItemIndex
                                                                          ]
                                                                            .target_tag
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) =>
                                                                          formik.setFieldValue(
                                                                            `formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}.target_tag`,
                                                                            event
                                                                              .currentTarget
                                                                              .value
                                                                          )
                                                                        }
                                                                      />
                                                                    </Grid.Col>
                                                                  </Grid>
                                                                </Box>
                                                              </Stack>
                                                            </Box>
                                                          </Grid.Col>
                                                        )}
                                                        {formik.values
                                                          .formSections[
                                                          sectionIndex
                                                        ].formSectionItem[
                                                          sectionItemIndex
                                                        ].type === 'Lista' && (
                                                          <Stack>
                                                            <Group
                                                              sx={{
                                                                marginTop: 12
                                                              }}
                                                              position="apart"
                                                            >
                                                              <Text>
                                                                Opciones:
                                                              </Text>
                                                              <TextInput
                                                                rightSection={
                                                                  <ActionIcon
                                                                    color="orange"
                                                                    variant="transparent"
                                                                    onClick={() =>
                                                                      addSectionOptionItem(
                                                                        section.id,
                                                                        sectionItem.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <RiAddCircleLine />
                                                                  </ActionIcon>
                                                                }
                                                                value={
                                                                  optionFormData[
                                                                    sectionItem
                                                                      .id
                                                                  ]?.name ?? ''
                                                                }
                                                                onChange={(e) =>
                                                                  setOptionFormData(
                                                                    {
                                                                      ...optionFormData,
                                                                      [sectionItem.id]:
                                                                        {
                                                                          ...optionFormData[
                                                                            sectionItem
                                                                              .id
                                                                          ],
                                                                          name: e
                                                                            .target
                                                                            .value
                                                                        }
                                                                    }
                                                                  )
                                                                }
                                                                variant="filled"
                                                              />
                                                              <Text>
                                                                ID Destino:
                                                              </Text>
                                                              <Select
                                                                variant="filled"
                                                                disabled={
                                                                  formik.values
                                                                    .formSections[
                                                                    sectionIndex
                                                                  ]
                                                                    .formSectionItem[
                                                                    sectionItemIndex
                                                                  ].is_multiple
                                                                }
                                                                data={formik.values.formSections
                                                                  .filter(
                                                                    (item) =>
                                                                      item.id !==
                                                                      section.id
                                                                  )
                                                                  .map(
                                                                    (item) => ({
                                                                      value:
                                                                        item.id,
                                                                      label:
                                                                        `id: ${item.id} - ${item.title}` ||
                                                                        'Seccion sin nombre'
                                                                    })
                                                                  )}
                                                                value={
                                                                  optionFormData[
                                                                    sectionItem
                                                                      .id
                                                                  ]
                                                                    ?.sectionRef ??
                                                                  ''
                                                                }
                                                                onChange={(
                                                                  value
                                                                ) =>
                                                                  setOptionFormData(
                                                                    {
                                                                      ...optionFormData,
                                                                      [sectionItem.id]:
                                                                        {
                                                                          ...optionFormData[
                                                                            sectionItem
                                                                              .id
                                                                          ],
                                                                          sectionRef:
                                                                            value
                                                                        }
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                              <Checkbox
                                                                checked={
                                                                  formik.values
                                                                    .formSections[
                                                                    sectionIndex
                                                                  ]
                                                                    .formSectionItem[
                                                                    sectionItemIndex
                                                                  ].is_multiple
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  formik.setFieldValue(
                                                                    `formSections.${sectionIndex}.formSectionItem.${sectionItemIndex}.is_multiple`,
                                                                    event
                                                                      .currentTarget
                                                                      .checked
                                                                  )
                                                                }
                                                                label="Selección multiple"
                                                              />
                                                            </Group>
                                                            <OptionsList
                                                              onDragEnd={
                                                                onOptionItemDragEnd
                                                              }
                                                              handleEditOption={
                                                                handleEditOption
                                                              }
                                                              removeSectionOptionItem={
                                                                removeSectionOptionItem
                                                              }
                                                              section={section}
                                                              sectionItem={
                                                                sectionItem
                                                              }
                                                            />
                                                          </Stack>
                                                        )}
                                                      </Paper>
                                                    )}
                                                  </Draggable>
                                                )
                                              )}
                                              {sectionItemProvided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                      {section.formSectionItem.length > 0 && (
                                        <ActionIcon
                                          color="dark"
                                          radius="xl"
                                          size="lg"
                                          onClick={() =>
                                            addNewSectionItem(section.id)
                                          }
                                          sx={{ placeSelf: 'end' }}
                                        >
                                          <FcPlus size="1.5em" />
                                        </ActionIcon>
                                      )}
                                    </Stack>
                                  )}
                                </Collapse>
                              </Box>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
          </Box>
        </Grid.Col>
        <Affix position={{ bottom: 20, right: 20 }}>
          <Transition transition="slide-up" mounted={scroll.y > 0}>
            {() => (
              <>
                {filterData.formSelected && (
                  <Button
                    onClick={formik.handleSubmit}
                    sx={{ marginRight: 12 }}
                    loading={isLoading}
                  >
                    Guardar Cambios
                  </Button>
                )}
                <PreviewFormModal
                  formik={formik}
                  isLoading={isLoading}
                  selectedTuple={selectedTuple}
                />
              </>
            )}
          </Transition>
        </Affix>
        <Grid.Col xs={7}>
          <Group position="apart">
            {filterData.formSelected &&
              filterData.formSelected !== 'NEW_FORM' && (
                <Button color="red" onClick={handleDelete}>
                  Eliminar
                </Button>
              )}
          </Group>
        </Grid.Col>
        <Grid.Col xs={5} />
      </Grid>
    </Page>
  )
}

export default Forms
