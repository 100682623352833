// @libs
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
// @mui-material
import { Container } from '@mantine/core'

function Page({ children, title }) {
  return (
    <>
      <Helmet>
        <title>{`${title} - Unidad Seguros`}</title>
      </Helmet>
      <Container size="xl" px={0} mt={32} mb={32}>
        {children}
      </Container>
    </>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

Page.defaultProps = {
  title: ''
}

export default Page
