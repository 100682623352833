// @libs
import PropTypes from 'prop-types'
// @components
import DefaultAlert from './Alerts/DefaultAlert'

function ErrorAlert({ color, title, message }) {
  return <DefaultAlert title={title} color={color} content={message} />
}

ErrorAlert.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
}

ErrorAlert.defaultProps = {
  color: 'error',
  title: 'Error',
  message: 'Message'
}

export default ErrorAlert
