// @mantine-ui
import { Container } from '@mantine/core'
import InsuranceForm from '../components/InsuranceForm'

function Client() {
  return (
    <Container size="sm" py="md">
      <InsuranceForm />
    </Container>
  )
}

export default Client
