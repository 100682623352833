// @libs
import { Fragment, useState, useEffect } from 'react'
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import * as jose from 'jose'
// @mantine-ui
import {
  Button,
  Grid,
  Text,
  TextInput,
  Select,
  MultiSelect,
  Table,
  Checkbox,
  NumberInput,
  Card,
  FileInput,
  Stepper,
  Box,
  Group,
  Tooltip,
  Input,
  SegmentedControl,
  Center,
  Textarea
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useFormik } from 'formik'
import { DatePicker } from '@mantine/dates'
import 'dayjs/locale/es-mx'
import { validateRut } from 'rutlib'
import { RiPercentLine } from 'react-icons/ri'
import { MdUploadFile, MdOutlineInsertPhoto } from 'react-icons/md'
import fetchOne from '../../../shared/hooks/useFetchOne'
import BasicLoader from '../../../shared/components/BasicLoader'
import axiosInstance from '../../../helpers/axios'
import {
  setFormAnswersAndResponse,
  setCustomResponses
} from '../../../store/cotizationSlice'
// import useClientsMutation from '../hooks/useClientsMutation'
import { useAuth } from '../../../shared/hooks/useAuth'
import successImage from '../../../assets/images/success_logo.png'

const validateValidEmail = (value) => {
  if (!value) {
    return 'Este campo es requerido'
  }
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  return emailRegex.test(value)
}

const validatePhoneNumber = (value) => {
  if (!value) {
    return 'Este campo es requerido'
  }
  const phoneRegex =
    /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
  return phoneRegex.test(value)
}

function Value({ file }) {
  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: '3px 7px',
        borderRadius: theme.radius.sm
      })}
    >
      <MdOutlineInsertPhoto size={14} style={{ marginRight: 5 }} />
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 200,
          display: 'inline-block'
        }}
      >
        {file.name}
      </span>
    </Center>
  )
}

Value.propTypes = {
  file: PropTypes.object
}

Value.defaultProps = {
  file: {}
}

function ValueComponent({ value }) {
  if (Array.isArray(value)) {
    return (
      <Group spacing="sm" py="xs">
        {value.map((file) => (
          <Value file={file} key={file.name} />
        ))}
      </Group>
    )
  }

  return <Value file={value} />
}

ValueComponent.propTypes = {
  value: PropTypes.array
}

ValueComponent.defaultProps = {
  value: []
}

function InsuranceForm() {
  const [isLoading, setIsLoading] = useState()
  const [isFormDone, setIsFormDone] = useState(false)
  const { uniqueTupleId } = useParams()
  const [active, setActive] = useState(0)
  const [filesSelected, setfilesSelected] = useState({})
  const [linkedListOptions, setLinkedListOptions] = useState({})
  const [highestStepVisited, setHighestStepVisited] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { customResponses, formAnswers } = useSelector(
    (state) => state.cotization
  )
  const { user, signOut } = useAuth()

  useEffect(() => {
    if (user) {
      signOut.mutateAsync()
    }
  }, [user])

  const { status, data } = fetchOne(
    ['client-types-has-insurance-types', uniqueTupleId],
    {
      refetchOnMount: true,
      onSuccess: (responseData) => {
        responseData.forms.forEach((form) => {
          form.formSections.forEach((section) => {
            section.formSectionItem.forEach((sectionItem) => {
              if (sectionItem.type === 'Verdadero / falso') {
                // eslint-disable-next-line no-use-before-define
                formik.setFieldValue(
                  `formAnswers.${sectionItem.attribute_id}`,
                  false
                )
              }
            })
          })
        })
      }
    }
  )

  const formik = useFormik({
    initialValues: {
      uniqueTupleId,
      agent_code: '',
      formAnswers:
        formAnswers?.formAnswers || location?.state?.formAnswers || {}
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const formDataWithFiles = new FormData()
        const token = searchParams.get('token')
        const payloadToSend = { ...values }

        if (token) {
          const { payload } = await jose.jwtVerify(
            token,
            new TextEncoder().encode('ajXNWaJ7vuUQLn2kCkra')
          )
          formDataWithFiles.append('agent_code', payload.agent_code)
          payloadToSend.agent_code = payload.agent_code
        }

        // validate if the form has files
        const attributesKeysWithFile = Object.keys(filesSelected)
        const allFiles = attributesKeysWithFile
          .map((key) => filesSelected[key])
          .flat()

        allFiles.map((file, index) =>
          formDataWithFiles.append(`file_${index + 1}`, file)
        )
        const attributesKeys = Object.keys(payloadToSend.formAnswers)

        attributesKeys.forEach((key) => {
          const valueToInsert = payloadToSend.formAnswers[key]

          if (Array.isArray(valueToInsert)) {
            formDataWithFiles.append(
              key,
              JSON.stringify(payloadToSend.formAnswers[key])
            )
          } else {
            formDataWithFiles.append(key, payloadToSend.formAnswers[key])
          }
        })

        formDataWithFiles.append('uniqueTupleId', payloadToSend.uniqueTupleId)

        const { data: responseData2 } = await axiosInstance.post(
          `/client-types-has-insurance-types/${uniqueTupleId}`,
          formDataWithFiles,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }
        )
        setIsLoading(false)
        dispatch(
          setFormAnswersAndResponse({
            formAnswers: values,
            apiResponse: responseData2
          })
        )

        if (data.is_online_cotization) {
          // navigate(`/cotiza/${uniqueTupleId}`)
          navigate(`/cotiza/${uniqueTupleId}/cotization`)
        } else {
          setIsFormDone(true)
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    if (data?.forms) {
      const currentForm = data.forms[active]

      if (currentForm?.is_api_filled) {
        currentForm?.formSections.forEach((section) => {
          section.formSectionItem.forEach((item) => {
            if (customResponses[item.insurance_company_id] && item.origin_tag) {
              const value =
                customResponses[item.insurance_company_id][item.origin_tag]

              formik.setFieldValue(
                `formAnswers.${item.attribute_id}`,
                item.type === 'Numerico' ? +value : value
              )
            }
          })
        })
      }
    }
  }, [data, customResponses, active, formik.setFieldValue])

  const handleRutChange = (event, sectionItemData) => {
    const {
      target: { value: inputValue }
    } = event

    formik.setFieldValue(
      `formAnswers.${sectionItemData.attribute_id}`,
      inputValue
    )
  }

  const createTableData = (sectionItem) => {
    const {
      attribute: { attribute_data: tableOptions }
    } = sectionItem
    const { columns, rowNumber } = tableOptions
    const th = (
      <thead>
        <tr>
          {columns?.map((column) => (
            <th style={{ textAlign: 'center', minWidth: 200 }}>
              {column.displayName}
            </th>
          ))}
        </tr>
      </thead>
    )
    const rows = []
    const questionRef =
      formik.values.formAnswers[`${sectionItem.attribute_id}`] || []

    for (let i = 0; i < rowNumber; i += 1) {
      const columnaSchema = {}
      columns.forEach((column) => {
        columnaSchema[column.displayName] = ''
      })
      const value = questionRef[i] ? questionRef[i] : columnaSchema

      // aqui tengo q armar el payload en base a un objeto
      rows.push(
        <tr>
          {columns?.map((column) => {
            if (column.dataType === 'Fecha') {
              return (
                <td>
                  <DatePicker
                    locale="es-mx"
                    size="md"
                    dropdownType="modal"
                    placeholder="Ingrese fecha"
                    value={value[column.displayName]}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        `formAnswers.${sectionItem.attribute_id}.[${i}]`,
                        {
                          ...value,
                          [column.displayName]: newValue
                        }
                      )
                    }}
                  />
                </td>
              )
            }
            if (column.dataType === 'Numero Entero') {
              return (
                <td>
                  <NumberInput
                    value={value[column.displayName]}
                    onChange={(newValue) =>
                      formik.setFieldValue(
                        `formAnswers.${sectionItem.attribute_id}.[${i}]`,
                        {
                          ...value,
                          [column.displayName]: newValue
                        }
                      )
                    }
                  />
                </td>
              )
            }
            if (column.dataType === 'Numero Decimal') {
              return (
                <td>
                  <NumberInput
                    precision={2}
                    value={value[column.displayName]}
                    onChange={(newValue) =>
                      formik.setFieldValue(
                        `formAnswers.${sectionItem.attribute_id}.[${i}]`,
                        {
                          ...value,
                          [column.displayName]: newValue
                        }
                      )
                    }
                  />
                </td>
              )
            }
            if (column.dataType === 'Si/No') {
              return (
                <td>
                  <Checkbox
                    checked={value[column.displayName] || false}
                    onChange={(event) =>
                      formik.setFieldValue(
                        `formAnswers.${sectionItem.attribute_id}.[${i}]`,
                        {
                          ...value,
                          [column.displayName]: event.currentTarget.checked
                        }
                      )
                    }
                  />
                </td>
              )
            }
            return (
              <td>
                <Textarea
                  autosize
                  value={value[column.displayName] || ''}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `formAnswers.${sectionItem.attribute_id}.[${i}]`,
                      {
                        ...value,
                        [column.displayName]: e.currentTarget.value
                      }
                    )
                  }
                />
              </td>
            )
          })}
        </tr>
      )
    }

    return (
      <>
        {th} <tbody>{rows}</tbody>
      </>
    )
  }

  const generateRange = (attributeProp) => {
    const minValue = attributeProp.attribute.attribute_data.min
    const maxValue = attributeProp.attribute.attribute_data.max
    const options = []

    for (let index = minValue; index <= maxValue; index += 1) {
      options.push({
        label: index.toString(),
        value: index
      })
    }
    return options
  }

  const stepNextDisabled = () => {
    if (data.forms) {
      const currentForm = data.forms[active]
      let message =
        'El formulario presenta errores, verifique para poder continuar.'

      const hasMissingAnswers = currentForm.formSections
        .map((section) =>
          section.formSectionItem.some((sectionItem) => {
            const value =
              formik.values.formAnswers[`${sectionItem.attribute_id}`]
            if (
              sectionItem.type === 'Texto' &&
              sectionItem.attribute.attribute_data.validate
            ) {
              if (
                sectionItem.attribute.attribute_data.validation_type ===
                  'Email' &&
                !validateValidEmail(value)
              ) {
                message = 'Correo invalido, revise el campo'
              }

              if (
                sectionItem.attribute.attribute_data.validation_type ===
                  'Teléfono' &&
                !validatePhoneNumber(value)
              ) {
                message = 'Número de teléfono invalido, revise el campo'
              }
            }
            if (
              sectionItem.type === 'Rut' &&
              sectionItem.attribute.attribute_data.validate
            ) {
              const isInvalidRut = validateRut(value)

              const validation =
                (sectionItem.is_required && !value) || !isInvalidRut

              if (validation) {
                message = 'El rut ingresado no es valido.'
              }

              return validation
            }
            if (sectionItem.type === 'Verdadero / falso') {
              return false
            }

            if (sectionItem.type === 'Tabla' && sectionItem.is_required) {
              const values = value || []

              if (!values.length) {
                message = `${sectionItem.text} es un campo requerido para continuar.`
              }
              return !values.length
            }
            const validation = sectionItem.is_required && !value

            if (validation) {
              message = `${sectionItem.text} es un campo requerido para continuar.`
            }

            return validation
          })
        )
        .some((item) => item)

      return { hasMissingAnswers, message }
    }
    return true
  }

  const nextStep = async () => {
    const { hasMissingAnswers, message } = stepNextDisabled()

    if (hasMissingAnswers) {
      showNotification({
        title: 'Error de validación',
        message,
        color: 'red'
      })
    } else {
      const currentForm = data.forms[active]
      const [firstSection] = currentForm.formSections
      const isTargetSource = firstSection.formSectionItem.every(
        (item) => item.insurance_company_id && item.target_tag
      )
      if (currentForm.is_api_filled && isTargetSource) {
        setIsLoading(true)
        const [fistElement] = firstSection.formSectionItem

        const { data: responseData } = await axios.get(
          fistElement.insuranceCompany.endpoint,
          {
            headers: {
              [fistElement.insuranceCompany.header_prop_name]:
                fistElement.insuranceCompany.access_token
            },
            params: {
              [fistElement.target_tag]:
                formik.values.formAnswers[fistElement.attribute_id]
            }
          }
        )
        dispatch(
          setCustomResponses({
            [fistElement.insuranceCompany.id]: responseData
          })
        )
        setIsLoading(false)
      }

      if (active === data.forms.length - 1) {
        formik.handleSubmit()
      } else {
        setActive((current) => current + 1)
      }
      setHighestStepVisited((hSC) => Math.max(hSC, active + 1))
    }
  }

  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current))
  }

  // Allow the user to freely go back and forth between visited steps.
  const shouldAllowSelectStep = (step) =>
    highestStepVisited >= step && active !== step

  const handleResetForm = () => {
    formik.resetForm()
    setIsFormDone(false)
    setActive(0)
    setHighestStepVisited(0)
    setfilesSelected({})
  }

  const handleFileChange = (sectionItemData, filesData) => {
    const fileNames = filesData.map((file) => file.name)
    formik.setFieldValue(
      `formAnswers.${sectionItemData.attribute_id}`,
      fileNames.join(', ')
    )
    setfilesSelected({
      ...filesSelected,
      [sectionItemData.attribute_id]: filesData
    })
  }

  const handleLinkedListFatherChange = (newValue, sectionItemData) => {
    formik.setFieldValue(
      `formAnswers.${sectionItemData.attribute_id}`,
      newValue
    )

    if (
      sectionItemData.attribute?.isMultilevelSelect &&
      sectionItemData.attribute?.multilevelType === 'PADRE'
    ) {
      const fatherOption =
        sectionItemData.attribute?.AttributesLinkedListOptions.find(
          (option) => option.id === +newValue
        )
      setLinkedListOptions({
        ...linkedListOptions,
        [sectionItemData.attribute_id]: fatherOption.childListOptions || []
      })
      if (sectionItemData.attribute?.childAttribute) {
        formik.setFieldValue(
          `formAnswers.${sectionItemData.attribute.childAttribute.id}`,
          ''
        )
      }
    }
  }

  const renderLinkedListOptions = (sectionItemData) => {
    let optionsToRender =
      sectionItemData.attribute?.AttributesLinkedListOptions || []

    if (
      sectionItemData.attribute?.isMultilevelSelect &&
      sectionItemData.attribute?.multilevelType === 'HIJO'
    ) {
      optionsToRender =
        linkedListOptions[sectionItemData.attribute.fatherAttributeId] || []
    }

    return optionsToRender.map((option) => ({
      label: option.name,
      value: option.id
    }))
  }

  const textFieldValidation = (sectionItemData) => {
    if (!sectionItemData.attribute.attribute_data.validate) {
      return {}
    }

    const value = formik.values.formAnswers[`${sectionItemData.attribute_id}`]

    if (!value) {
      return {}
    }

    if (sectionItemData.attribute.attribute_data.validation_type === 'Email') {
      return {
        error: !validateValidEmail(value) && 'Correo invalido'
      }
    }

    if (
      sectionItemData.attribute.attribute_data.validation_type === 'Teléfono'
    ) {
      return {
        error: !validatePhoneNumber(value) && 'Número de teléfono invalido'
      }
    }
    return {}
  }

  if (status === 'loading') return <BasicLoader />

  return (
    <Card
      sx={{
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '26px'
      }}
    >
      <Grid px={32} py={8}>
        {!data?.is_online_cotization && isFormDone ? (
          <Grid.Col xs={12}>
            <Group position="center">
              <Box component="img" src={successImage} sx={{ width: 130 }} />
              <Text
                sx={(theme) => ({
                  fontWeight: 700,
                  fontSize: 22,
                  lineHeight: '39px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  [theme.fn.smallerThan('sm')]: {
                    fontSize: 24,
                    lineHeight: '26px'
                  }
                })}
              >
                Tus datos se han enviado correctamente, nuestros asesores se
                contatarán a la brevedad.
              </Text>
              <Button
                fw={500}
                fullWidth
                size="md"
                onClick={handleResetForm}
                fz={16}
                sx={{ marginTop: 12 }}
              >
                Regresar al cotizador
              </Button>
            </Group>
          </Grid.Col>
        ) : (
          <>
            <Grid.Col xs={12}>
              <Text
                align="center"
                sx={{
                  fontFamily: 'Assistant',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 30,
                  lineHeight: '30px',
                  letterSpacing: '-0.015em',
                  color: '#1E1C3E',
                  marginBottom: 12
                }}
              >
                {data.insuranceType.name}
              </Text>
            </Grid.Col>
            <Grid.Col xs={12}>
              <Stepper
                color="green"
                size="xs"
                active={active}
                onStepClick={setActive}
                iconSize={32}
                breakpoint="md"
              >
                {data.forms.map((form, index) => (
                  <Stepper.Step
                    key={form.id}
                    label={
                      <Text fw={700} fz={16}>
                        {form.name}
                      </Text>
                    }
                    color={active === index ? 'orange' : 'green'}
                    allowStepSelect={shouldAllowSelectStep(index)}
                  >
                    {form.formSections.map((section) => (
                      <Fragment key={section.id}>
                        <Grid.Col xs={12} my={8}>
                          <Text
                            align="justify"
                            sx={{
                              fontFamily: 'Assistant',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              fontSize: 22,
                              lineHeight: '30px',
                              letterSpacing: '-0.015em',
                              color: '#1E1C3E'
                            }}
                          >
                            {section.title}
                          </Text>
                        </Grid.Col>
                        <Grid.Col xs={12}>
                          <Grid
                            grow
                            sx={{
                              backgroundColor: '#F4F1F1',
                              borderRadius: 26,
                              padding: '16px'
                            }}
                          >
                            <Grid.Col xs={12}>
                              <Text>{section.description}</Text>
                            </Grid.Col>
                            {section.type === 'ATTRIBUTES' && (
                              <>
                                {section.formSectionItem.map((sectionItem) => {
                                  if (
                                    sectionItem.type === 'Lista predeterminada'
                                  ) {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <Select
                                            label={sectionItem.text}
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(newValue) =>
                                              handleLinkedListFatherChange(
                                                newValue,
                                                sectionItem
                                              )
                                            }
                                            data={renderLinkedListOptions(
                                              sectionItem
                                            )}
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }
                                  if (
                                    sectionItem.type === 'Lista' &&
                                    !sectionItem.is_multiple
                                  ) {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <Select
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(newValue) =>
                                              formik.setFieldValue(
                                                `formAnswers.${sectionItem.attribute_id}`,
                                                newValue
                                              )
                                            }
                                            label={sectionItem.text}
                                            data={sectionItem.formSectionListItem.map(
                                              (option) => ({
                                                label: option.name,
                                                // value: option.name // forDemo
                                                value: option.id
                                              })
                                            )}
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            multiple={sectionItem.is_multiple}
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (
                                    sectionItem.type === 'Lista' &&
                                    sectionItem.is_multiple
                                  ) {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          color="gray"
                                          position="bottom-start"
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <MultiSelect
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            label={sectionItem.text}
                                            data={sectionItem.formSectionListItem.map(
                                              (option) => ({
                                                label: option.name,
                                                value: option.id
                                              })
                                            )}
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(newValue) =>
                                              formik.setFieldValue(
                                                `formAnswers.${sectionItem.attribute_id}`,
                                                newValue
                                              )
                                            }
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            multiple={sectionItem.is_multiple}
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Tabla') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                        sx={{ overflowX: 'auto' }}
                                      >
                                        <Text>
                                          {sectionItem.text}{' '}
                                          <span style={{ color: 'red' }}>
                                            {sectionItem.is_required && '*'}
                                          </span>
                                        </Text>
                                        <Table withBorder withColumnBorders>
                                          {createTableData(sectionItem)}
                                        </Table>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Texto') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          color="gray"
                                          position="bottom-start"
                                          withArrow
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <Textarea
                                            autosize
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(e) =>
                                              formik.setFieldValue(
                                                `formAnswers.${sectionItem.attribute_id}`,
                                                e.currentTarget.value
                                              )
                                            }
                                            label={sectionItem.text}
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            {...textFieldValidation(
                                              sectionItem
                                            )}
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'error',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Rango numerico') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <Select
                                            label={sectionItem.text}
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            data={generateRange(sectionItem)}
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(newValue) =>
                                              formik.setFieldValue(
                                                `formAnswers.${sectionItem.attribute_id}`,
                                                newValue
                                              )
                                            }
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Vigencia') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <DatePicker
                                            locale="es-mx"
                                            label={sectionItem.text}
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            placeholder="Ingrese fecha"
                                            minDate={
                                              new Date(
                                                sectionItem.attribute.attribute_data.startDate
                                              )
                                            }
                                            maxDate={
                                              new Date(
                                                sectionItem.attribute.attribute_data.endDate
                                              )
                                            }
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(newValue) => {
                                              formik.setFieldValue(
                                                `formAnswers.${sectionItem.attribute_id}`,
                                                newValue
                                              )
                                            }}
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Numerico') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <NumberInput
                                            label={sectionItem.text}
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            {...(sectionItem.attribute
                                              .attribute_data.numberType ===
                                              'FLOAT' && {
                                              precision: 2
                                            })}
                                            {...(sectionItem.attribute
                                              .attribute_data.numberType ===
                                              'PERCENT' && {
                                              min: 0,
                                              max: 100,
                                              icon: <RiPercentLine />
                                            })}
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(newValue) => {
                                              let valueParsed = newValue
                                              if (
                                                sectionItem.attribute
                                                  .attribute_data.numberType ===
                                                'PERCENT'
                                              ) {
                                                valueParsed /= 100
                                              }
                                              formik.setFieldValue(
                                                `formAnswers.${sectionItem.attribute_id}`,
                                                valueParsed
                                              )
                                            }}
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Rut') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <TextInput
                                            label={sectionItem.text}
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            {...(sectionItem.attribute
                                              .attribute_data.validate &&
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] && {
                                                error:
                                                  !validateRut(
                                                    formik.values.formAnswers[
                                                      `${sectionItem.attribute_id}`
                                                    ]
                                                  ) && 'Rut invalido'
                                              })}
                                            onChange={(e) =>
                                              handleRutChange(e, sectionItem)
                                            }
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'error',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Adjunto') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <FileInput
                                            label={sectionItem.text}
                                            value={
                                              filesSelected[
                                                `${sectionItem.attribute_id}`
                                              ] || []
                                            }
                                            onChange={(newValue) =>
                                              handleFileChange(
                                                sectionItem,
                                                newValue
                                              )
                                            }
                                            placeholder="Click para seleccionar los archivos"
                                            multiple
                                            icon={<MdUploadFile size={14} />}
                                            valueComponent={ValueComponent}
                                            clearable
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Formula') {
                                    const { operation, attributesIds } =
                                      sectionItem.attribute.attribute_data

                                    let formOperation = operation

                                    attributesIds.forEach((attributeId) => {
                                      const attributeInputValue =
                                        formik.values.formAnswers[
                                          attributeId
                                        ] ?? 0

                                      formOperation = formOperation.replaceAll(
                                        attributeId,
                                        attributeInputValue
                                      )
                                    })

                                    // regex que verifica la operacion posea solo numeros, espacios y los siguientes simbolos '+', '-', '*', '/', (', ')', '.', ','
                                    const validOperation =
                                      // eslint-disable-next-line no-useless-escape
                                      /^[0-9+\-*\/\(\) .,]+$/.test(
                                        formOperation
                                      )

                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Text>{sectionItem.text}</Text>
                                        <Text>
                                          {
                                            sectionItem.attribute
                                              .description_text
                                          }
                                        </Text>
                                        <Text>
                                          {
                                            sectionItem.attribute
                                              .description_text
                                          }
                                        </Text>
                                        {validOperation && (
                                          <>
                                            {/* eslint-disable-next-line no-eval */}
                                            <Text>{eval(formOperation)}</Text>
                                          </>
                                        )}
                                        {!validOperation && (
                                          <Text>Operacion no permitida</Text>
                                        )}
                                      </Grid.Col>
                                    )
                                  }

                                  if (
                                    sectionItem.type === 'Verdadero / falso'
                                  ) {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <Input.Wrapper
                                            label={sectionItem.text}
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                          >
                                            <SegmentedControl
                                              fullWidth
                                              data={[
                                                { label: 'Si', value: 'true' },
                                                { label: 'No', value: 'false' }
                                              ]}
                                              value={
                                                formik.values.formAnswers[
                                                  `${sectionItem.attribute_id}`
                                                ] || 'false'
                                              }
                                              onChange={(newValue) =>
                                                formik.setFieldValue(
                                                  `formAnswers.${sectionItem.attribute_id}`,
                                                  newValue
                                                )
                                              }
                                              color="orange"
                                              sx={{ backgroundColor: '#fff' }}
                                            />
                                          </Input.Wrapper>
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  if (sectionItem.type === 'Moneda') {
                                    return (
                                      <Grid.Col
                                        key={sectionItem.id}
                                        xs={sectionItem.col_span}
                                      >
                                        <Tooltip
                                          multiline
                                          withArrow
                                          label={
                                            sectionItem.attribute
                                              .description_text
                                          }
                                          position="bottom-start"
                                          color="gray"
                                          events={{
                                            hover:
                                              sectionItem.attribute
                                                .display_tooltip,
                                            focus: false,
                                            touch: false
                                          }}
                                        >
                                          <NumberInput
                                            label={sectionItem.text}
                                            description={
                                              !sectionItem.attribute
                                                .display_tooltip &&
                                              sectionItem.attribute
                                                .description_text
                                            }
                                            withAsterisk={
                                              sectionItem.is_required
                                            }
                                            value={
                                              formik.values.formAnswers[
                                                `${sectionItem.attribute_id}`
                                              ] || ''
                                            }
                                            onChange={(newValue) =>
                                              formik.setFieldValue(
                                                `formAnswers.${sectionItem.attribute_id}`,
                                                newValue
                                              )
                                            }
                                            inputWrapperOrder={[
                                              'label',
                                              'input',
                                              'description'
                                            ]}
                                            parser={(value) =>
                                              value.replace(/\$\s?|(\.*)/g, '')
                                            }
                                            formatter={(value) =>
                                              !Number.isNaN(parseFloat(value))
                                                ? `${value}`.replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    '.'
                                                  )
                                                : ''
                                            }
                                          />
                                        </Tooltip>
                                      </Grid.Col>
                                    )
                                  }

                                  return null
                                })}
                              </>
                            )}
                          </Grid>
                        </Grid.Col>
                      </Fragment>
                    ))}
                  </Stepper.Step>
                ))}
              </Stepper>
            </Grid.Col>
            <Grid.Col xs={12} mt={16}>
              <Box
                sx={{
                  width: active ? '100%' : '60%',
                  marginLeft: active ? 0 : '20%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                {!!active && (
                  <Button
                    fullWidth
                    variant="filled"
                    sx={{
                      backgroundColor: '#F4F1F1',
                      fontFamily: 'Montserrat',
                      fontWeight: 500,
                      fontStyle: 'normal',
                      fontSize: '16px',
                      lineHeight: ' 22px',
                      color: '#1E1C3E',
                      '&:hover': {
                        backgroundColor: '#F4F1F1'
                      }
                    }}
                    size="md"
                    onClick={prevStep}
                    mr={16}
                  >
                    Regresar
                  </Button>
                )}
                <Button
                  fw={500}
                  fullWidth
                  size="md"
                  onClick={nextStep}
                  loading={isLoading}
                  fz={16}
                >
                  Guardar y seguir
                </Button>
              </Box>
            </Grid.Col>
          </>
        )}
      </Grid>
    </Card>
  )
}

export default InsuranceForm
