// @libs
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useState } from 'react'
// import { FiUpload } from 'react-icons/fi'
// @mantine-ui
import {
  Button,
  Grid,
  TextInput,
  Box,
  Select,
  // FileInput,
  // ScrollArea,
  Textarea
} from '@mantine/core'
// @validations
import planSchema from '../validations'
// @hooks
import usePlanMutation from '../hooks/usePlanMutation'
import fetchAll from '../../../shared/hooks/useFetchAll'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import SwitchBase from '../../../shared/components/Switch/SwitchBase'
import BasicLoader from '../../../shared/components/BasicLoader'
import useFetchOne from '../../../shared/hooks/useFetchOne'

function PlanForm({ show, handleShow, planToEdit }) {
  const { addPlan, updatePlan } = usePlanMutation()
  const [clientOptions, setClientOptions] = useState([])
  // const [template, setTemplate] = useState(null)

  const formik = useFormik({
    initialValues: {
      insurance_company_id: planToEdit?.insurance_company_id || '',
      is_active: planToEdit?.is_active || true,
      name: planToEdit?.name || '',
      client_type_has_insurance_type_id: '',
      tag: planToEdit?.tag || '',
      email_template: planToEdit?.email || '',
      compare_value: planToEdit?.compare_value || ''
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: planSchema,
    onSubmit: (planData, { resetForm }) => {
      if (planToEdit) {
        return updatePlan
          .mutateAsync([planToEdit.id, { ...planToEdit, ...planData }])
          .then(() => {
            handleShow()
            resetForm()
            // setTemplate(null)
          })
      }
      return addPlan.mutateAsync(planData).then(() => {
        handleShow()
        resetForm()
        // setTemplate(null)
      })
    }
  })

  // const readSingleFile = (file) => {
  //   setTemplate(file)
  //   const fileReader = new FileReader()
  //   fileReader.onload = (e) => {
  //     const htmlContent = e.target.result
  //     formik.setFieldValue('email_template', htmlContent)
  //   }
  //   fileReader.readAsText(file)
  // }

  const insuranceCompanies = fetchAll({
    key: 'insurance-companies',
    filters: {
      limit: 'all'
    },
    customConfig: {
      enabled: show && !planToEdit,
      refetchOnMount: false
    }
  })
  const companyDetails = useFetchOne(
    ['insurance-companies', formik.values.insurance_company_id],
    {
      enabled: show && !!formik.values.insurance_company_id,
      onSuccess: (data) => {
        // set the email_template if exists
        // if (planToEdit?.email_template) {
        //   const blob = new Blob([planToEdit?.email_template], {
        //     type: 'text/html'
        //   })
        //   const file = new File([blob], 'template.html', { type: 'text/html' })
        //   readSingleFile(file)
        // }
        const optionsTuple = data.insurance_types_has_insurance_companies.map(
          (client) => ({
            value: client?.client_type_has_insurance_type?.id,
            label: `${client?.client_type_has_insurance_type?.insuranceType.name} (${client?.client_type_has_insurance_type?.clientType?.name})`,
            group: client?.client_type_has_insurance_type?.clientType?.name
          })
        )
        setClientOptions(optionsTuple)
        formik.setFieldValue(
          'client_type_has_insurance_type_id',
          planToEdit?.client_type_has_insurance_type?.id
        )
      }
    }
  )

  const handleChangeCompany = (companyId) => {
    if (companyId) {
      formik.setFieldValue('insurance_company_id', companyId)
    } else {
      formik.setFieldValue('insurance_company_id', '')
      formik.setFieldValue('client_type_has_insurance_type_id', '')
      setClientOptions([])
    }
  }
  const handleChangeClienType = (clientTypeId) => {
    formik.setFieldValue('client_type_has_insurance_type_id', clientTypeId)
  }

  const handleClose = () => {
    // setTemplate(null)
    formik.resetForm()
    handleShow()
  }

  return (
    <ModalBase
      opened={show}
      onClose={handleClose}
      title="Ingresa la información del nuevo plan aquí"
    >
      {insuranceCompanies.isFetching ? (
        <BasicLoader />
      ) : (
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid.Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Select
                disabled={!!planToEdit}
                name="insurance_company_id"
                value={formik.values.insurance_company_id}
                clearable
                placeholder="Compañia*"
                nothingFound="Sin resultados"
                defaultValue={
                  planToEdit
                    ? {
                        ...companyDetails?.data,
                        value: companyDetails?.data?.id,
                        label:
                          companyDetails?.data?.name ||
                          companyDetails?.data?.business_name
                      }
                    : []
                }
                data={
                  insuranceCompanies?.data?.data?.length > 0
                    ? insuranceCompanies.data.data.map(
                        (insuranceCompany) =>
                          insuranceCompany && {
                            ...insuranceCompany,
                            value: insuranceCompany.id,
                            label:
                              insuranceCompany.name ||
                              insuranceCompany.business_name
                          }
                      )
                    : []
                }
                onChange={(company) => handleChangeCompany(company)}
                error={formik.errors.insurance_company_id}
              />
              <SwitchBase
                label="Activo"
                name="is_active"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                value={formik.values.is_active}
                error={formik.errors.is_active}
              />
            </Grid.Col>
            {formik.values.insurance_company_id && (
              <Grid.Col xs={12}>
                {companyDetails.isLoading || companyDetails.isFetching ? (
                  <Grid>
                    <Grid.Col>
                      <BasicLoader />
                    </Grid.Col>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid.Col xs={12}>
                      <Select
                        name="client_type_has_insurance_type_id"
                        value={formik.values.client_type_has_insurance_type_id}
                        clearable
                        placeholder="Tipo de cliente*"
                        nothingFound="Sin resultados"
                        data={clientOptions}
                        onChange={(clientType) =>
                          handleChangeClienType(clientType)
                        }
                        error={formik.errors.client_type_has_insurance_type_id}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <TextInput
                        placeholder="Nombre comercial*"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.errors.name}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <Textarea
                        placeholder="Tag de respuesta*"
                        name="tag"
                        onChange={formik.handleChange}
                        value={formik.values.tag}
                        error={formik.errors.tag}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <TextInput
                        placeholder="Valor a comparar*"
                        name="compare_value"
                        onChange={formik.handleChange}
                        value={formik.values.compare_value}
                        error={formik.errors.compare_value}
                      />
                    </Grid.Col>
                    {/* <Grid.Col xs={12}>
                      <FileInput
                        icon={<FiUpload />}
                        placeholder="HTML mail bienvenida*"
                        name="email_template"
                        onChange={readSingleFile}
                        value={template}
                        error={formik.errors.email_template}
                        accept=".html"
                        clearable
                      />
                    </Grid.Col> */}
                  </Grid>
                )}
              </Grid.Col>
            )}
            {/* {template && (
              <Grid.Col xs={12}>
                <ScrollArea
                  w="100%"
                  h={150}
                  sx={{
                    border: '0.5px solid black',
                    borderRadius: 16,
                    padding: '2px 8px'
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formik.values.email_template
                    }}
                  />
                </ScrollArea>
              </Grid.Col>
            )} */}
            <Grid.Col xs={12} mt={16}>
              <Button
                fullWidth
                loading={addPlan.isLoading || updatePlan.isLoading}
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </ModalBase>
  )
}

PlanForm.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  planToEdit: PropTypes.object
}

PlanForm.defaultProps = {
  show: false,
  handleShow: () => {},
  planToEdit: null
}

// ruta del producto
// "OfertasBase.OfertaBase.producto"

// Ruta del plan
// "OfertasBase.OfertaBase.Deducibles.DeducibleBase.nombrePlan"

export default PlanForm
