// @libs
import * as yup from 'yup'
import validateRut from 'verificador-rut'
// @constants
import { REQUIRED_MESSAGE } from '../../utils/contants'

yup.addMethod(yup.string, 'isRut', function (errorMessage) {
  return this.test(`validate-rut`, errorMessage, function (value) {
    const { path, createError } = this
    const regex = /^\d{7,8}-[\dkK]{1}$/
    if (!value)
      return createError({ path, message: errorMessage ?? REQUIRED_MESSAGE })
    if (!value.match(regex))
      return createError({
        path,
        message: errorMessage ?? 'El formato es incorrecto'
      })
    if (!validateRut(value.toLowerCase()))
      return createError({
        path,
        message: errorMessage ?? 'El rut no es válido'
      })
    return true
  })
})

const userSchema = yup.object().shape({
  is_password_required: yup.bool().default(false),
  is_agent: yup.bool().default(false),
  rut: yup.string().isRut(),
  name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  last_name: yup
    .string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .max(255, 'Ingrese máximo 255 caracteres'),
  is_active: yup.boolean().default(true),
  email: yup
    .string()
    .email('El formato no es válido')
    .required(REQUIRED_MESSAGE),
  password: yup
    .string()
    .trim()
    .when('is_password_required', {
      is: true,
      then: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(8, 'Ingrese mínimo 8 caracteres')
        .max(255, 'Ingrese máximo 255 caracteres'),
      otherwise: yup.lazy((value) => {
        if (value) {
          return yup
            .string()
            .trim()
            .min(8, 'Ingrese mínimo 8 caracteres')
            .max(255, 'Ingrese máximo 255 caracteres')
        }
        return yup.string()
      })
    }),
  role_id: yup.number().positive().required(REQUIRED_MESSAGE).nullable(),
  phone: yup
    .string()
    .trim()
    .when('is_agent', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string()
    }),
  discharge_date: yup.string().trim(),
  city: yup.string().trim(),
  commune: yup.string().trim(),
  insurances: yup.array().of(
    yup.object().shape({
      insurancesTypes: yup.array().of(
        yup.object().shape({
          isSelected: yup.boolean(),
          hiring_percentage: yup
            .number()
            .when('isSelected', {
              is: true,
              then: yup.number().required(REQUIRED_MESSAGE).min(0).max(100)
            })
            .nullable(),
          renovation_percentage: yup
            .number()
            .when('isSelected', {
              is: true,
              then: yup.number().required(REQUIRED_MESSAGE).min(0).max(100)
            })
            .nullable()
        })
      )
    })
  )
})

export default userSchema
