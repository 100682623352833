import dayjs from 'dayjs'

export const dateFormat = (date) => {
  if (date) return dayjs(date).format('DD/MM/YYYY')
  return ''
}

export const hello = ''

export const formatRut = (value) => {
  let newValue = null
  const cleanRut = value.replace(/\./g, '').replace(/-/g, '')
  const body = cleanRut.slice(0, -1)
  const checker = cleanRut.slice(-1).toUpperCase()

  if (body.length > 0) {
    let rutFormatted = ''
    for (let j = 1, i = body.length - 1; i >= 0; i -= 1, j += 1) {
      const number = body.charAt(i)
      rutFormatted = number + rutFormatted
      if (j % 3 === 0 && j <= body.length - 1) {
        rutFormatted = `.${rutFormatted}`
      }
    }
    newValue = `${rutFormatted}-${checker}`
  } else {
    newValue = value
  }
  return newValue
}
export default formatRut
