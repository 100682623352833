// @libs
import axios from '../helpers/axios'

const fetchAll = async ({ queryKey }) => {
  let filters = ''
  if (queryKey[1]) {
    const querys = Object.entries(queryKey[1])
    querys.forEach(([key, value], i) => {
      filters += `${key}=${value}${i + 1 < querys.length ? '&' : ''}`
    })
  }
  try {
    const { data } = await axios.get(`${queryKey[0]}?${filters}`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
const fetchById = async (queryKey) => {
  const [path, _id] = queryKey
  try {
    const { data } = await axios.get(`/${path}/${_id}`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
const create = async (queryKey) => {
  const [path, body] = queryKey
  try {
    const { data } = await axios.post(`/${path}`, body)
    return data
  } catch (e) {
    return Promise.reject(e.response.data)
  }
}
const update = async (queryKey) => {
  const [path, _id, body] = queryKey
  try {
    const { data } = await axios.put(`/${path}/${_id}`, body)
    return data
  } catch (e) {
    return Promise.reject(e.response.data)
  }
}
const remove = async (queryKey) => {
  const [path, _id] = queryKey
  try {
    await axios.delete(`/${path}/${_id}`)
    return _id
  } catch (e) {
    return Promise.reject(e.response.data)
  }
}

const ApiService = {
  fetchAll,
  fetchById,
  create,
  update,
  remove
}

export default ApiService
