// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import Attributes from './pages/Attributes'

const AttributesRoutes = [
  {
    path: '/attributes',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.INSURANCES_SETTINGS}
        action="Atributos:VIEW"
        yes={() => <Attributes />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default AttributesRoutes
