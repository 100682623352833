// @libs
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { useListState } from '@mantine/hooks'
import { AiOutlineDrag } from 'react-icons/ai'
// @mantine-ui
import { Button, Grid, Text, Paper, Group } from '@mantine/core'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import useDraggableInPortal from '../../../shared/hooks/useDraggableInPortal'
import fetchOne from '../../../shared/hooks/useFetchOne'
import BasicLoader from '../../../shared/components/BasicLoader'

function InsuranceSettingsFilterOrderModal() {
  const [show, setShow] = useState(false)
  const [, handlers] = useListState([])
  const renderDraggable = useDraggableInPortal()
  const { uniqueTupleId } = useParams()

  const { data: uniqueTupleData, status } = fetchOne([
    'client-types-has-insurance-types',
    uniqueTupleId
  ])

  const handleDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) return

    handlers.reorder({ from: source.index, to: destination.index })
  }

  return (
    <>
      <Button onClick={() => setShow((prev) => !prev)}>
        Ordenar por atributos de filtro
      </Button>

      <ModalBase
        title="Ingresa la información del orden de atributo de filtro aquí"
        show={show}
        handleShow={() => setShow((prev) => !prev)}
      >
        <Grid>
          <Grid.Col xs={12}>
            {status === 'loading' ? (
              <BasicLoader />
            ) : (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="myDoppableId">
                  {(provided) => (
                    <Paper {...provided.droppableProps} ref={provided.innerRef}>
                      {uniqueTupleData.insuranceTypesHasAttributes
                        .filter((item) => item.is_filter)
                        .map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}-${item.attributes.name}`}
                            index={index}
                          >
                            {renderDraggable((draggableProvided) => (
                              <Paper
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                ref={draggableProvided.innerRef}
                                radius="xl"
                                p="md"
                                my={6}
                                withBorder
                                sx={(theme) => ({
                                  backgroundColor: theme.colors.orange[6]
                                })}
                              >
                                <Group grow>
                                  <Text
                                    sx={{
                                      color: '#fff',
                                      fontWeight: 400,
                                      width: '90%'
                                    }}
                                  >
                                    {index + 1} - {item.attributes.name}
                                  </Text>
                                  <AiOutlineDrag style={{ color: '#fff' }} />
                                </Group>
                              </Paper>
                            ))}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Paper>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Grid.Col>
        </Grid>
      </ModalBase>
    </>
  )
}

export default InsuranceSettingsFilterOrderModal
