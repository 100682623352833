// @libs
import { useState } from 'react'
// @mantine-ui
import { Box, Collapse, Grid, Switch, Text } from '@mantine/core'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useAgentMutation from '../hooks/useAgentMutation'
// @utils
import { openDeleteModal } from '../../../utils/alerts'
// @constants
import { TABLE_HEADERS } from '../constants'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import AgentForm from './AgentForm'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import DetailsIconButton from '../../../shared/components/Buttons/DetailsIconButton'
import AgentDetails from './AgentDetails'
import FilterIconButton from '../../../shared/components/Buttons/FilterIconButton'

function AgentList() {
  const [showEditForm, setShowEditForm] = useState(false)
  const [agentToEdit, setAgentToEdit] = useState(null)
  const [agentDetails, setAgentDetails] = useState(null)
  const [filters, setFilters] = useState({
    sort_by: ['created_at:desc']
  })

  const { data, isLoading, isError } = fetchAll({
    key: 'agents',
    filters
  })
  const { removeAgent } = useAgentMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert />
  if (!data.length) return <NoDataAlert />

  const handleDelete = (agent) =>
    openDeleteModal({
      title: 'Eliminar Agente',
      content: `¿Estás seguro de eliminar el Agente ${agent.user.name} ${agent.user.last_name}?.`,
      onConfirm: async () => removeAgent.mutateAsync(agent.user.id)
    })
  const handleAgentDetails = (agent) => {
    if (agent === agentDetails) return setAgentDetails(null)
    return setAgentDetails(agent)
  }
  const getValue = (agent, key) => {
    if (Array.isArray(key))
      return key
        .map((headerKey) =>
          headerKey.includes('.')
            ? agent.user[headerKey.split('.')[1]]
            : agent[headerKey]
        )
        .join(' ')
    if (key.includes('.')) return agent.user[key.split('.')[1]]
    return agent[key]
  }
  const handleSort = (option) => {
    const filter = filters.sort_by.find((item) => item.split(':')[0] === option)
    if (filter) {
      setFilters((currentFilters) => ({
        ...currentFilters,
        sort_by: currentFilters.sort_by.map((currentFilter) => {
          const [key, direction] = currentFilter.split(':')
          if (key === option) {
            if (direction === 'asc') return `${option}:desc`
            return `${option}:asc`
          }
          return currentFilter
        })
      }))
    } else {
      setFilters((currentFilters) => ({
        ...currentFilters,
        sort_by: [`${option}:asc`, ...currentFilters.sort_by]
      }))
    }
  }

  return (
    <Grid>
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS.map((header) => (
            <Grid.Col xs={2} key={header.label}>
              <Text
                sx={{
                  fontSize: 16,
                  lineHeight: '26px',
                  letterSpacing: '0.04em',
                  opacity: 0.8,
                  display: 'flex'
                }}
              >
                {header.label}
                {header.isSortable && (
                  <FilterIconButton onClick={() => handleSort(header.key)} />
                )}
              </Text>
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12} px={12}>
        {data.map((agent) => (
          <Grid
            key={agent.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            {TABLE_HEADERS.map((header) => (
              <Grid.Col xs={2} key={header.key}>
                {!header.key.includes('is_active') ? (
                  <Text
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: '26px',
                      letterSpacing: '0.04em',
                      opacity: 0.8
                    }}
                  >
                    {getValue(agent, header.key)}
                  </Text>
                ) : (
                  <Switch
                    size="sm"
                    color="green"
                    checked={getValue(agent, header.key)}
                  />
                )}
              </Grid.Col>
            ))}
            <Grid.Col xs={2}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <EditIconButton
                  onClick={() => {
                    setAgentToEdit(agent)
                    setShowEditForm((prev) => !prev)
                  }}
                />
                <DetailsIconButton onClick={() => handleAgentDetails(agent)} />
                <DeleteIconButton onClick={() => handleDelete(agent)} />
              </Box>
            </Grid.Col>
            <Collapse
              px={8}
              py={16}
              sx={{ width: '100%' }}
              in={agentDetails === agent}
            >
              <AgentDetails agent={agent} />
            </Collapse>
          </Grid>
        ))}
      </Grid.Col>
      <AgentForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        agentToEdit={agentToEdit}
      />
    </Grid>
  )
}

export default AgentList
