// @libs
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
// @mantine-ui
import { AppShell, Header, Box, Button, Text } from '@mantine/core'
// @utilities
import { APPBAR_HEIGHT } from '../../utils/contants'
// @components
import Logo from './Logo'
// @hooks
import { useAuth } from '../hooks/useAuth'

const UNIDAD_PHONE_NUMBER = '+56 9 5459 6621'

function PublicLayout({ children, customStyles, showAccessButton, ...props }) {
  const navigate = useNavigate()
  const location = useLocation()

  const { user, signOut } = useAuth()

  useEffect(() => {
    if (user) {
      signOut.mutateAsync()
    }
  }, [user])

  return (
    <AppShell
      sx={{
        minHeight: '100%',
        minWidth: '100%'
      }}
      header={
        <Header height={APPBAR_HEIGHT} p="xs">
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              [theme.fn.smallerThan('sm')]: {
                paddingRight: 16,
                paddingLeft: 16
              },
              paddingRight: 48,
              paddingLeft: 48
            })}
          >
            <Logo
              sx={{
                width: 150,
                height: 64,
                '&:hover': { cursor: 'pointer' }
              }}
              onClick={() => navigate('/')}
            />
            <Box
              sx={(theme) => ({
                display: 'flex',
                [theme.fn.smallerThan('sm')]: {
                  display: 'none'
                }
              })}
            >
              {showAccessButton && location.pathname !== '/auth/sign-in' && (
                <Button color="warning" component={NavLink} to="/auth/sign-in">
                  Acceso usuarios
                </Button>
              )}

              {!showAccessButton && (
                <Text
                  component="a"
                  href="https://wa.link/a1h6ey"
                  target="_blank"
                  fz={24}
                  fw={600}
                  c="orange"
                >
                  {UNIDAD_PHONE_NUMBER}
                </Text>
              )}
            </Box>
          </Box>
        </Header>
      }
      styles={(theme) => ({
        main: {
          paddingTop: APPBAR_HEIGHT,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[4],
          ...customStyles
        }
      })}
      {...props}
    >
      {children}
    </AppShell>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node,
  customStyles: PropTypes.object,
  showAccessButton: PropTypes.bool
}

PublicLayout.defaultProps = {
  children: null,
  customStyles: {},
  showAccessButton: true
}

export default PublicLayout
