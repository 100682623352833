// @libs
import { useState } from 'react'
import PropTypes from 'prop-types'
// @mantine-ui
import { Grid, Group, Switch, Text } from '@mantine/core'
import { NavLink } from 'react-router-dom'
// @utils
import { MODULES_PERMISSIONS } from '../../../utils/contants'
import { dateFormat } from '../../../utils/formats'
import { openDeleteModal } from '../../../utils/alerts'
// @hooks
import fetchAll from '../../../shared/hooks/useFetchAll'
import useCodeCampaignMutation from '../hooks/useCodeCampaignMutations'
// @constants
import { TABLE_HEADERS } from '../constants'
// @components
import BasicLoader from '../../../shared/components/BasicLoader'
import EditIconButton from '../../../shared/components/Buttons/EditIconButton'
import DeleteIconButton from '../../../shared/components/Buttons/DeleteIconButton'
import DetailsIconButton from '../../../shared/components/Buttons/DetailsIconButton'
import CodeForm from './CodeCampaignForm'
import NoDataAlert from '../../../shared/components/Alerts/NoDataAlert'
import ErrorAlert from '../../../shared/components/Alerts/ErrorAlert'
import Can from '../../../shared/components/Can'
import PaginationBase from '../../../shared/components/PaginationBase'
import RowsCount from '../../../shared/components/RowsCount'

function CodeCampaignList({ q }) {
  const [showEditForm, setShowEditForm] = useState(false)
  const [codeToEdit, setCodeToEdit] = useState(null)
  const [filters, setFilters] = useState({
    page: 1,
    limit: '50'
  })

  const { data, isLoading, isError } = fetchAll({
    key: 'code-campaigns',
    filters: { ...filters, q }
  })
  const { removeCodeCampaign } = useCodeCampaignMutation()

  if (isLoading) return <BasicLoader />
  if (isError) return <ErrorAlert code={500} />
  if (!data?.data?.length) return <NoDataAlert />

  const handleDelete = (code) =>
    openDeleteModal({
      title: 'Eliminar Códigos',
      content: `¿Estás seguro de eliminar los códigos?.`,
      onConfirm: async () => removeCodeCampaign.mutateAsync(code.id)
    })

  const getValue = (headerKey, value, index) => {
    switch (headerKey) {
      case 'is_active':
        return (
          <Group position="center">
            <Switch size="sm" color="green" checked={value[headerKey]} />
          </Group>
        )
      case 'end_date':
        return (
          <Text
            sx={{
              fontWeight: 700,
              fontSize: 16,
              lineHeight: '24px',
              letterSpacing: '0.04em',
              opacity: 0.8
            }}
          >
            {dateFormat(value[headerKey])}
          </Text>
        )
      case 'agent.user.name':
        return (
          <Text
            align="center"
            sx={{
              fontWeight: 700,
              fontSize: 16,
              lineHeight: '24px',
              letterSpacing: '0.04em',
              opacity: 0.8
            }}
          >
            {value?.agent?.user &&
              `${value?.agent?.user?.name} ${value?.agent?.user?.last_name}`}
          </Text>
        )
      default:
        return (
          <Text
            align={index === 0 ? 'start' : 'center'}
            sx={{
              fontWeight: 700,
              fontSize: 16,
              lineHeight: '24px',
              letterSpacing: '0.04em',
              opacity: 0.8
            }}
          >
            {value[headerKey]}
          </Text>
        )
    }
  }
  return (
    <Grid>
      <Grid.Col xs={12}>
        <Grid px={12}>
          {TABLE_HEADERS.map((header, index) => (
            <Grid.Col xs={2} key={header.label}>
              <Text
                align={index === 0 ? 'start' : 'center'}
                sx={{
                  fontSize: 16,
                  lineHeight: '24px',
                  letterSpacing: '0.04em',
                  opacity: 0.8
                }}
              >
                {header.label}
              </Text>
            </Grid.Col>
          ))}
          <Grid.Col xs={2} />
        </Grid>
      </Grid.Col>
      <Grid.Col xs={12}>
        {data?.data?.map((codeCampaign) => (
          <Grid
            key={codeCampaign.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
              marginBottom: 16,
              borderRadius: 16
            }}
            px={12}
          >
            {TABLE_HEADERS.map((header, index) => (
              <Grid.Col xs={2} key={header.key}>
                {getValue(header.key, codeCampaign, index)}
              </Grid.Col>
            ))}
            <Grid.Col xs={2}>
              <Group position="center">
                <Can
                  moduleName={MODULES_PERMISSIONS.AGENTS}
                  action="Códigos:VIEW"
                  yes={() => (
                    <DetailsIconButton
                      component={NavLink}
                      to={`/code-campaigns/${codeCampaign?.id}`}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.AGENTS}
                  action="Códigos:EDIT"
                  yes={() => (
                    <EditIconButton
                      onClick={() => {
                        setCodeToEdit(codeCampaign)
                        setShowEditForm((prev) => !prev)
                      }}
                    />
                  )}
                />
                <Can
                  moduleName={MODULES_PERMISSIONS.AGENTS}
                  action="Códigos:DELETE"
                  yes={() => (
                    <DeleteIconButton
                      onClick={() => handleDelete(codeCampaign)}
                    />
                  )}
                />
              </Group>
            </Grid.Col>
          </Grid>
        ))}
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <RowsCount
          value={filters.limit}
          onChange={(value) => setFilters({ ...filters, limit: value })}
        />
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <PaginationBase
          total={data?.meta?.lastPage}
          value={data?.meta?.currentPage}
          onChange={(value) => setFilters((cf) => ({ ...cf, page: value }))}
        />
      </Grid.Col>
      <CodeForm
        show={showEditForm}
        handleShow={() => setShowEditForm((prev) => !prev)}
        codeToEdit={codeToEdit}
      />
    </Grid>
  )
}

CodeCampaignList.propTypes = {
  q: PropTypes.string
}

CodeCampaignList.defaultProps = {
  q: ''
}

export default CodeCampaignList
