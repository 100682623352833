// @components
import Can from '../../shared/components/Can'
import ErrorPage from '../../shared/components/ErrorPage'
// @utilities
import { MODULES_PERMISSIONS } from '../../utils/contants'
// @pages
import InsuranceTypes from './pages/insurance-types'

const insuranceTypesRoutes = [
  {
    path: '/insurance-types',
    element: (
      <Can
        moduleName={MODULES_PERMISSIONS.INSURANCES_TYPES}
        action="Tipos de Seguro:VIEW"
        yes={() => <InsuranceTypes />}
        no={() => <ErrorPage code={401} />}
      />
    )
  }
]

export default insuranceTypesRoutes
