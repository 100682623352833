import { useState } from 'react'
import {
  Grid,
  Text,
  Container,
  Table,
  Stack,
  Button,
  Image
} from '@mantine/core'
import { useSelector } from 'react-redux'
import { openBasicModal } from '../../../utils/alerts'
import axiosInstance from '../../../helpers/axios'
import FidEmailVerificationModal from '../components/FidEmailVerificationModal'

const pesosLocale = Intl.NumberFormat('es')

const rows = [
  { id: 1, label: 'Deducible', field: 'deducible' },
  {
    id: 2,
    label: 'Valor Mensual',
    field: 'valorEnPeso',
    isCurrency: true,
    customStyles: {
      fontSize: 38,
      fontWeight: 700,
      marginTop: 12,
      marginBottom: 12
    }
  },
  { id: 3, label: 'UF mensual', field: 'valorEnUf' },
  {
    id: 4,
    label: 'Compañia',
    field: 'companyData',
    valueGetter: (row, value) => (
      <Stack align="center" sx={{ marginTop: 24, marginBottom: 24 }}>
        <Image src={value.image} width={100} alt="Norway" fit="contain" />
      </Stack>
    )
  },
  { id: 5, label: 'Plan', field: 'nombrePlan' },
  {
    id: 6,
    label: 'Robo de accesorios',
    field: 'Robo de accesorios',
    valueGetter: (row, value) =>
      value.image ===
      'https://www.fidseguros.cl/content/uploads/2022/10/logo-header.svg'
        ? 'Incluido'
        : 'No incluido'
  }
]

function Compare() {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [insuranceSelected, setInsuranceSelected] = useState(null)
  const { productsToCompare } = useSelector((state) => state.cotization)

  const handleContract = (insurance) => {
    const suraLink = 'https://www.epssura.com/cotiza/producto'
    setInsuranceSelected(insurance)

    openBasicModal({
      title: `Contratar seguro ${insurance.nombrePlan}`,
      content: `¿Estás seguro que quieres contratar la poliza ${insurance.nombrePlan}?. Un agente de Unidad Seguros se pondra en contacto contigo`,
      onConfirm: async () => {
        setIsLoading(true)
        if (insurance.type === 'FID') {
          await axiosInstance.post(
            `/client-types-has-insurance-types/fid_email_verification`,
            {
              dealId: insurance.fidDealID,
              itemId: insurance.fidItemId,
              quotationId: insurance.quotationId
            }
          )
          setOpen(true)
        }

        if (insurance.type === 'SURA') {
          await new Promise((resolve) => {
            setTimeout(resolve, 10000)
          }).then(() => {
            window.open(suraLink, '_blank')
          })
        }
        setIsLoading(false)
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
    setInsuranceSelected(null)
  }

  return (
    <Grid>
      <Grid.Col
        xs={12}
        sx={{ backgroundColor: 'orange', padding: '16px 32px' }}
      >
        <Text fw={700} lh={1.5}>
          Datos del Vehículo
        </Text>
        <Text fw={700} lh={1.5}>
          RENAULT, DUSTER DYNAMITE 1.6 2015
        </Text>
        <Text lh={1.2}>RUT: client_rut</Text>
        <Text lh={1.2}>Nombre: client_name</Text>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Container size="xl" sx={{ marginBottom: '2rem' }}>
          <Text
            fw={700}
            sx={{
              marginTop: 32,
              marginBottom: 24,
              fontSize: 30,
              color: '#1E1C3E'
            }}
          >
            Elige uno de los seguros seleccionados!
          </Text>
          <Table>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={row.id}>
                  <td style={{ width: 150 }}>
                    <Text align="right">{row.label}</Text>
                  </td>
                  <td />
                  {productsToCompare.map((item) => (
                    <>
                      <td
                        style={{
                          backgroundColor: '#FFFFFF',
                          ...(rowIndex === 0 && {
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20
                          })
                        }}
                      >
                        <Text align="center" sx={row.customStyles}>
                          {(row.isCurrency &&
                            `$${pesosLocale.format(item[row.field])}`) ||
                            item[row.field] ||
                            row.defaultValue ||
                            row.valueGetter(row, item)}
                        </Text>
                      </td>
                      <td />
                    </>
                  ))}
                </tr>
              ))}
              <tr>
                <td />
                <td />
                {productsToCompare.map((item) => (
                  <>
                    <td
                      style={{
                        backgroundColor: '#FFFFFF',
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: 20
                      }}
                    >
                      <Stack align="center" sx={{ marginTop: '4rem' }}>
                        <Button
                          fullWidth
                          size="md"
                          color="orange"
                          sx={{ width: '80%' }}
                          onClick={() => handleContract(item)}
                          loading={isLoading}
                        >
                          Comprar
                        </Button>
                      </Stack>
                    </td>
                    <td />
                  </>
                ))}
              </tr>
            </tbody>
          </Table>
        </Container>
      </Grid.Col>

      <FidEmailVerificationModal
        open={open}
        handleClose={handleClose}
        insurance={insuranceSelected}
      />
    </Grid>
  )
}

export default Compare
