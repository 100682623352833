const MultiSelect = {
  styles: (theme) => ({
    values: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '24px',
      letterSpacing: '-0.015em',
      textAlign: 'center',
      borderRadius: theme.radius.sm,
      color: '#1E1C3E'
    },
    searchInput: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '24px',
      letterSpacing: '-0.015em',
      borderRadius: theme.radius.sm,
      color: '#1E1C3E'
    },
    input: {
      minHeight: 42
    },
    value: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '24px',
      letterSpacing: '-0.015em'
    },
    separatorLabel: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 11,
      color: '#1E1C3E'
    },
    defaultValueRemove: {
      fontWeight: 400
    }
  })
}
export default MultiSelect
