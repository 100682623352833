// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Pagination } from '@mantine/core'

function PaginationBase({ total, value, onChange, ...props }) {
  return (
    <Pagination
      total={total}
      sx={{ color: '#F28D01' }}
      radius="md"
      value={value}
      initialPage={value}
      onChange={onChange}
      {...props}
    />
  )
}

PaginationBase.propTypes = {
  total: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func
}
PaginationBase.defaultProps = {
  total: 1,
  value: 1,
  onChange: () => {}
}

export default PaginationBase
