// @libs
import PropTypes from 'prop-types'
// @mantine-ui
import { Alert, Text } from '@mantine/core'

function DefaultAlert({ title, content, color, ...props }) {
  return (
    <Alert
      p={20}
      title={
        <Text
          sx={{
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '26px',
            textAlign: 'center',
            opacity: 0.8
          }}
        >
          {title}
        </Text>
      }
      radius="lg"
      sx={{
        backgroundColor: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)'
      }}
      {...props}
    >
      <Text
        sx={{
          fontSize: 16,
          lineHeight: '26px',
          opacity: 0.8
        }}
      >
        {content}
      </Text>
    </Alert>
  )
}

DefaultAlert.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.string
}

DefaultAlert.defaultProps = { title: '', content: '', color: '' }

export default DefaultAlert
