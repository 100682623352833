// @components
import DefaultAlert from './DefaultAlert'

function NoDataAlert() {
  return (
    <DefaultAlert
      title="Sin información"
      content="No hay información para mostrar."
    />
  )
}

export default NoDataAlert
