// @mantine-ui
import PropTypes from 'prop-types'
import { Loader, Center } from '@mantine/core'

function BasicLoader({ color, variant, ...props }) {
  return (
    <Center sx={{ marginTop: 12 }}>
      <Loader color={color} variant={variant} {...props} />
    </Center>
  )
}

BasicLoader.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string
}

BasicLoader.defaultProps = {
  color: 'yellow',
  variant: 'oval'
}

export default BasicLoader
